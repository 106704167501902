// import { GetReservations } from '../../container/reservation/admin/calendarDataQueries'
import actions from './actions'

const {
  RESERVATION_EXECUTE_GRAPHQL_QUERY,
  RESERVATION_TRIGGER_GRAPHQL,
  RESERVATION_STOP_GRAPHQL,
  RESERVATION_INCREASE_CLICK_COUNTER,
  RESERVATION_DECREASE_CLICK_COUNTER,
  RESERVATIONS_SET_ALL,
  RESERVATIONS_SET_TABLE,
  DELETE_RESERVATION,
  CREATE_RESERVATION
} = actions

const initialState = {
  executeGraphqlQuery: null,
  triggerGraphqlQuery: false,
  clickCounter: 0
}

const ReservationReducer = (state = initialState, action) => {
  const { type, data, id } = action

  // const graphqlVariables = {
  //   tokenGet: process.env.REACT_APP_TOKEN_GET,
  //   tokenMutation: process.env.REACT_APP_TOKEN_MUTATION
  // }

  switch (type) {
    case RESERVATION_EXECUTE_GRAPHQL_QUERY:
      // console.log('Data: ', data)
      return {
        ...state,
        executeGraphqlQuery: data
      }
    case RESERVATION_TRIGGER_GRAPHQL:
      return {
        ...state,
        triggerGraphqlQuery: true
      }
    case RESERVATION_STOP_GRAPHQL:
      return {
        ...state,
        triggerGraphqlQuery: false
      }
    case RESERVATION_INCREASE_CLICK_COUNTER:
      return {
        ...state,
        clickCounter: state.clickCounter + 1
      }
    case RESERVATION_DECREASE_CLICK_COUNTER:
      // console.log('State: ', state)
      return {
        ...state,
        clickCounter: state.clickCounter - 1
      }
    case RESERVATIONS_SET_ALL:
      return {
        ...state,
        allReservations: data,
        reservations: data
      }
    case RESERVATIONS_SET_TABLE:
      return {
        ...state,
        reservations: data
      }
    case DELETE_RESERVATION:
      const allReservations = state?.allReservations
      const updatedReservations = allReservations.filter(
        reservation => Number(reservation.id) !== id
      )

      return {
        ...state,
        reservations: updatedReservations,
        allReservations: updatedReservations
      }
    case CREATE_RESERVATION:
      return {
        ...state,
        reservations: data,
        allReservations: data
        // console.log('State: ', state);
      }
    default:
      return state
  }
}

export { ReservationReducer }
