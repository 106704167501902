const actions = {
  CAR_RENTERS_SET_ALL: 'CAR_RENTERS_SET_ALL',
  FILTER_SET_TABLE: 'FILTER_SET_TABLE',
  COMPANY_UPDATE: 'COMPANY_UPDATE',
  COMPANY_CREATE: 'COMPANY_CREATE',
  FILTER_SET_TABLE_TAB2: 'FILTER_SET_TABLE_TAB2',

  carRentersSetAll: data => {
    return {
      type: actions.CAR_RENTERS_SET_ALL,
      data
    }
  },
  filterSetTable: data => {
    return {
      type: actions.FILTER_SET_TABLE,
      data
    }
  },
  filterSetTableTab2: data => {
    return {
      type: actions.FILTER_SET_TABLE_TAB2,
      data
    }
  },
  companyUpdate: data => {
    return {
      type: actions.COMPANY_UPDATE,
      data
    }
  },
  companyCreate: data => {
    return {
      type: actions.COMPANY_UPDATE,
      data
    }
  }
}

export default actions
