import React, { useState, useEffect } from 'react'
import { Form } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import { PageHeader } from '../../../components/page-headers/page-headers'
import { Main } from '../../styled'

import { useQuery } from '@apollo/client'
import { GET_CAR_BY_ID } from '../../../graphql/AdminQueries'
import { CarDetail } from './tabs/carDetail'

import { useTranslation } from 'react-i18next'

const graphqlVariables = {
  tokenGet: process.env.REACT_APP_TOKEN_GET,
  tokenMutation: process.env.REACT_APP_TOKEN_MUTATION
}

const EditCar = props => {
  const { t } = useTranslation('common')
  const [form] = Form.useForm()

  const [state, setState] = useState({
    isLoadingCarData: true,
    isLoading: false
  })

  const [carObject, setCarObject] = useState({})
  const [loadData, setLoadData] = useState(false)

  let { id: carId } = useParams()

  useEffect(() => {
    setLoadData(true)
  }, [])

  const graphqlGetVariables = {
    ...graphqlVariables,
    carId: carId
  }
  const { data: carData } = useQuery(GET_CAR_BY_ID, {
    variables: graphqlGetVariables,
    skip: !loadData
  })
  if (carData) {
    const processedCarData =
      carData?.getCars && carData?.getCars[0] ? carData.getCars[0] : {}

    setState({
      ...state,
      isLoadingCarData: false
    })
    setLoadData(false)

    setCarObject(processedCarData)
  }

  return (
    <>
      <PageHeader ghost title={'Detail vozidla ' + carObject.licence_plate} />
      <Main>
        <CarDetail t={t} data={props} form={form} selectedCar={carObject} />
      </Main>
    </>
  )
}

export default EditCar
