import actions from './actions'

const {
  branchOfficesSetAll,
  filterSetTable,
  branchOfficceUpdate,
  branchOfficceCreate,
  filterSetTableTab2
} = actions

const setAllBranchOfficces = data => {
  return async dispatch => {
    dispatch(branchOfficesSetAll(data))
  }
}

const setTableFiler = data => {
  return async dispatch => {
    dispatch(filterSetTable(data))
  }
}
const setTableTab2Filer = data => {
  return async dispatch => {
    dispatch(filterSetTableTab2(data))
  }
}

const updateBranchOfficce = data => {
  return async dispatch => {
    dispatch(branchOfficceUpdate(data))
  }
}

const createBranchOfficce = data => {
  return async dispatch => {
    dispatch(branchOfficceCreate(data))
  }
}

export {
  setAllBranchOfficces,
  setTableFiler,
  updateBranchOfficce,
  createBranchOfficce,
  setTableTab2Filer
}
