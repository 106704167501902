import React, { useState, useEffect } from 'react'
import { BasicFormWrapper, CardToolbox, Main } from '../../styled'
import { Form, Input, Row, Col, PageHeader, Radio, DatePicker } from 'antd'
import { Button } from '../../../components/buttons/buttons'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import displayNotification from '../../reservation/admin/notification/notification'
import { useMutation } from '@apollo/client'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { GET_STORAGE_LIST } from '../../../graphql/AdminQueries'
import { UPDATE_STORAGE_RECORD } from '../../../graphql/AdminMutations'

const dateFormat = 'YYYY-MM-DD'
const EditWarehouseItem = props => {
  const { t } = useTranslation('common')
  const [form] = Form.useForm()
  const history = useHistory()

  const { id } = useParams()

  const graphqlVariables = {
    tokenGet: process.env.REACT_APP_TOKEN_GET,
    tokenMutation: process.env.REACT_APP_TOKEN_MUTATION,
    id: id
  }

  const [loadData, setLoadData] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  useEffect(() => {
    setLoadData(true)
  }, [])

  const [triggerUpdateReccord] = useMutation(UPDATE_STORAGE_RECORD, {
    variables: graphqlVariables
  })

  const itemData = useQuery(GET_STORAGE_LIST, {
    variables: graphqlVariables,
    skip: !loadData
  })
  const warehouseData = itemData?.data?.getStorageList
  let warehouseItemData = {}

  useEffect(() => {
    if (warehouseData) {
      if (warehouseData?.length > 0) {
        setLoadData(false)
        warehouseItemData = warehouseData[0]
        if (warehouseItemData) {
          const carId = warehouseItemData?.car?.id
          const id = warehouseItemData?.id
          setInitialValues({
            carId: carId,
            licence_plate: warehouseItemData?.car?.licence_plate,
            tyre_brand: warehouseItemData?.tyre_brand,
            number: warehouseItemData?.number,
            qty: warehouseItemData?.qty,
            date: moment(warehouseItemData?.date),
            thread: warehouseItemData?.thread,
            speedometer: warehouseItemData?.speedometer,
            disk_type: warehouseItemData?.disk_type,
            season: warehouseItemData?.season,
            note: warehouseItemData?.note
          })
        }
      }
    }
    form.resetFields()
  }, [form, warehouseData])

  const handleEditOk = values => {
    const variables = {
      ...graphqlVariables,
      number: values?.number,
      tyre_brand: values?.tyre_brand,
      date: moment(values?.date).format(dateFormat),
      note: values?.note,
      thread: values?.thread,
      qty: Number(values?.qty),
      disk_type: values?.disk_type,
      speedometer: Number(values?.speedometer),
      season: values?.season,
      carId: initialValues?.carId
    }
    console.log('variables:::: ', variables)
    triggerUpdateReccord({ variables }).then(({ data }) => {
      let type = 'success'
      const message = t('backend.notifications.update')
      let desc = t('backend.notifications.success.recordUpdate')

      if (!data?.updateStorageRecord?.sucessful) {
        type = 'error'
        desc = t('backend.notifications.error.recordUpdate')
        displayNotification(type, message, desc)
      } else {
        location.replace('/admin/service/warehouse')
      }
    })
  }
  console.log('initialValues::: ', initialValues)
  return (
    <>
      <CardToolbox>
        <PageHeader ghost title={t('backend.warehouse.editItem')} />
      </CardToolbox>
      <Main>
        <BasicFormWrapper>
          <Form
            form={form}
            name='editWarehouseItem'
            initialValues={initialValues}
            onFinish={handleEditOk}
          >
            <Row gutter={15}>
              <Col md={12} sm={12} xs={24}>
                <Form.Item label={t('car.licencePlate')} name='licence_plate'>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col md={6} sm={6} xs={24}>
                <Form.Item
                  label={t('backend.warehouse.number')}
                  name='number'
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={24} sm={24} xs={24}>
                <Form.Item
                  label={t('backend.warehouse.tyreType')}
                  name='tyre_brand'
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={4} sm={4} xs={24}>
                <Form.Item
                  label={t('backend.warehouse.qty')}
                  name='qty'
                  rules={[{ required: true }]}
                >
                  <Input placeholder='' />
                </Form.Item>
              </Col>
              <Col md={4} sm={4} xs={24}>
                <Form.Item
                  label={t('backend.warehouse.thread')}
                  name='thread'
                  rules={[{ required: true }]}
                >
                  <Input placeholder='(uvádzajte vo formáte x-x-x-x)' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={4} sm={4} xs={24}>
                <Form.Item
                  label={t('backend.warehouse.speedometer')}
                  name='speedometer'
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={4} xs={24}>
                <Form.Item
                  label='Dátum'
                  name='date'
                  rules={[{ required: true }]}
                >
                  <DatePicker format={'DD.MM.YYYY'} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={4} sm={4} xs={24}>
                <Form.Item
                  label={t('backend.warehouse.diskType')}
                  name='disk_type'
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    <Radio value='Bez Disku'>Bez Disku</Radio>
                    <Radio value='ALU'>ALU</Radio>
                    <Radio value='PLECH'>PLECH</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col md={4} sm={4} xs={24}>
                <Form.Item
                  label={t('backend.warehouse.season')}
                  name='season'
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    <Radio value='ZIMA'>ZIMA</Radio>
                    <Radio value='LETO'>LETO</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={24} sm={24} xs={24}>
                <Form.Item label={t('backend.warehouse.note')} name='note'>
                  <Input placeholder='' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={24} sm={24} xs={24} style={{ marginTop: '20px' }}>
                <Button
                  htmlType='submit'
                  size='default'
                  type='primary'
                  key='submit'
                >
                  {t('common.updateRecord')}
                </Button>
              </Col>
            </Row>
          </Form>
        </BasicFormWrapper>
      </Main>
    </>
  )
}

export default EditWarehouseItem
