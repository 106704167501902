import React, { useEffect, useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root'
import { Provider, useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { client } from './ApolloClient/client'
import { ApolloProvider } from '@apollo/client'
import { i18nextConfig } from './translations/i18nextConfig'

import store from './redux/store'
import Admin from './routes/admin'
import Auth from './routes/auth'
import Reservation from './routes/reservation'
import ReservationPortal from './routes/customer'
// import Car from './routes/car'
import './static/css/style.css'
import config from './config/config'
// import ProtectedRoute from './components/utilities/protectedRoute'

const { theme } = config

// init translations
const i18n = i18nextConfig

const ProviderConfig = () => {
  const { rtl, isLoggedIn, topMenu, darkMode } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login
    }
  })

  const [path, setPath] = useState(window.location.pathname)

  useEffect(() => {
    let unmounted = false
    if (!unmounted) {
      setPath(window.location.pathname)
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true)
  }, [setPath])

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode, i18n }}>
        <Router basename={process.env.PUBLIC_URL}>
          {/* {!isLoggedIn ? (
            <Route path='/admin' component={Auth} />
          ) : (
            <ProtectedRoute path='/admin' component={Admin} />
          )} */}

          {isLoggedIn &&
            (path === process.env.PUBLIC_URL ||
              path === `${process.env.PUBLIC_URL}/`) && (
              <Redirect to='/admin' />
            )}
          {!isLoggedIn && <Route path='/admin' component={Auth} />}
          {isLoggedIn && (
            <>
              <Route path='/reservation' component={Reservation} />

              <Route path='/admin' component={Admin} />
            </>
          )}

          {(path === process.env.PUBLIC_URL ||
            path === `${process.env.PUBLIC_URL}/`) && (
            // <Redirect to="/admin" />
            // <Route path="/" component={Reservation} />
            <Route path='/' component={ReservationPortal} />
          )}

          {/* <Redirect to="/admin" /> */}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  )
}

function App () {
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <ProviderConfig />
      </ApolloProvider>
    </Provider>
  )
}

export default hot(App)
