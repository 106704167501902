import { notification } from 'antd'

/**
 *
 * @param {*} type
 * @param {*} message
 * @param {*} desc
 */
const displayNotification = (type, message, desc) => {
  notification[type]({
    message: message,
    description: desc,
    onClick: () => {
      // console.log('Notification Clicked!');
    }
  })
}
export default displayNotification
