import React, { useState, lazy, Suspense, useEffect } from 'react'
import { Row, Col, Skeleton } from 'antd'
import { useQuery } from '@apollo/client'

import { Link, Switch, Route, useRouteMatch } from 'react-router-dom'
import { CalendarWrapper } from './style'
import { ShareButtonPageHeader } from '../../../components/buttons/share-button/share-button'
import { ExportButtonPageHeader } from '../../../components/buttons/export-button/export-button'
import { CalendarButtonPageHeader } from '../../../components/buttons/calendar-button/calendar-button'
import { Main } from '../../styled'
import { Button } from '../../../components/buttons/buttons'
import { Cards } from '../../../components/cards/frame/cards-frame'
import { PageHeader } from '../../../components/page-headers/page-headers'
import 'react-calendar/dist/Calendar.css'
import { Tabs } from 'antd'

import Cookies from 'js-cookie'

import { useTranslation } from 'react-i18next'
import { GET_DEVICES_BY_BRANCHOFFICE_ID } from '../../../graphql/AdminQueries'
const WeekCalendar = lazy(() => import('./overview/Week'))
// import { Tab } from './overview/tabs/tabs'

const { TabPane } = Tabs

const Calendars = () => {
  const { t } = useTranslation('common')

  const graphqlVariables = {
    tokenGet: process.env.REACT_APP_TOKEN_GET,
    branchOffice:
      Number(Cookies.get('selectedCompany')) ||
      Number(Cookies.get('branchOfficeId'))
  }
  const branchOffices = useQuery(GET_DEVICES_BY_BRANCHOFFICE_ID, {
    variables: graphqlVariables
  })
  let branchOfficesData = []
  const [deviceId, setDeviceId] = useState(1)
  let data = []
  if (branchOffices?.data?.getDevicesByBranchOfficeId) {
    branchOfficesData = branchOffices?.data?.getDevicesByBranchOfficeId
    data.push({
      title: t('backend.reservations.device1'),
      tabTitle: t('backend.reservations.device1'),
      deviceId: branchOfficesData[0].id
    })
    if (branchOfficesData.length === 2) {
      data.push({
        title: t('backend.reservations.device2'),
        tabTitle: t('backend.reservations.device2'),
        deviceId: branchOfficesData[1].id
      })
    }
  }

  useEffect(() => {
    if (branchOfficesData && branchOfficesData[0]) {
      setDeviceId(branchOfficesData[0].id)
    }
  }, [branchOfficesData])
  const onChange = key => {
    setDeviceId(key)
  }
  return (
    <>
      <PageHeader ghost title={t('backend.reservations.title')} />
      <Main>
        <CalendarWrapper className='reservation-overview'>
          <Row gutter={25}>
            <Col xxl={24} xl={15} xs={24}>
              <Switch>
                <Suspense
                  fallback={
                    <Cards headless>
                      <Skeleton paragraph={{ rows: 15 }} active />
                    </Cards>
                  }
                >
                  <Tabs defaultActiveKey='1' onChange={onChange}>
                    {data.map(item => {
                      const { title } = item
                      return (
                        <TabPane tab={title} key={item.deviceId}>
                          <WeekCalendar selectedDeviceId={deviceId} />
                        </TabPane>
                      )
                    })}
                  </Tabs>
                </Suspense>
              </Switch>
            </Col>
          </Row>
        </CalendarWrapper>
      </Main>
    </>
  )
}

export default Calendars
