import { useQuery } from '@apollo/client'

import { GET_STORAGE_LIST } from '../../../graphql/AdminQueries'

const GetGraphQlData = (graphqlVariables, loadData) => {
  const storage = useQuery(GET_STORAGE_LIST, {
    variables: graphqlVariables,
    skip: !loadData
  })

  return [storage]
}

export { GetGraphQlData }
