import { Table } from 'antd'
import React from 'react'
import { Cards } from '../../../../components/cards/frame/cards-frame'
import { UserTableStyleWrapper } from '../../../pages/style'
import { TableWrapper } from '../../../styled'
import { formatSQLDate } from '../../../../utils/common'
import FeatherIcon from 'feather-icons-react'

const Tab2 = props => {
  const { t, selectedItem } = props
  const tableData = []
  selectedItem &&
    selectedItem.map(item => {
      const { id, date, type, qty } = item
      let iconColor = 'red'
      let icon = 'minus-circle'
      let iconText = t('products.modal.tabs.tab2.grid.out')
      if (type === 'in') {
        iconColor = 'green'
        icon = 'plus-circle'
        iconText = t('products.modal.tabs.tab2.grid.in')
      }
      return tableData.push({
        key: id,
        date: formatSQLDate(date),
        type: (
          <span className='growth-upward'>
            <FeatherIcon icon={icon} size={16} color={iconColor} /> {iconText}
          </span>
        ),
        qty: qty
      })
    })

  return (
    <Cards headless>
      <UserTableStyleWrapper>
        <TableWrapper className='table-responsive'>
          <Table
            dataSource={tableData}
            columns={getTableColumns(t)}
            pagination={{
              defaultPageSize: 20,
              total: tableData ? tableData.length : 0,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} ${t('common.from')} ${total} ${t(
                  'common.items'
                )}`
            }}
          />
        </TableWrapper>
      </UserTableStyleWrapper>
    </Cards>
  )
}
const getTableColumns = t => {
  return [
    {
      title: t('products.modal.tabs.tab2.grid.date'),
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: t('products.modal.tabs.tab2.grid.type'),
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: t('products.modal.tabs.tab2.grid.qty'),
      dataIndex: 'qty',
      key: 'qty'
    }
  ]
}
export { Tab2 }
