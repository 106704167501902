import React from 'react'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'

const { Option } = Select

const SelectCarRenter = props => {
  const { data, ...rest } = props
  const { t } = useTranslation('common')

  const options = []
  if (data !== undefined) {
    for (let item of data) {
      const name = item.company
      const id = item.id
      const row = (
        <Option key={id} value={id} label={name}>
          {name}
        </Option>
      )
      options.push(row)
    }
  }
  return (
    <Select
      {...rest}
      showSearch
      placeholder={t('car.select')}
      optionFilterProp='children'
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
    >
      {options}
    </Select>
  )
}

export { SelectCarRenter }
