import { combineReducers } from 'redux'
import { readMessageReducer } from './message/reducers'
import { readNotificationReducer } from './notification/reducers'
import authReducer from './authentication/reducers'
import ChangeLayoutMode from './themeLayout/reducers'
import { UsersReducer } from './users/reducers'
import { headerSearchReducer } from './headerSearch/reducers'

import { ReservationReducer } from './reservation/reducers'
import { CarsReducer } from './cars/reducers'
import { CarOwnersReducer } from './car_owners/reducers'
import { CarRentersReducer } from './car_renters/reducers'
import { ProductsReducer } from './products/reducers'
import { ServicesReducer } from './services/reducers'
import { StorageReducer } from './storage/reducers'
import { BranchOfficesReducer } from './branchOfficces/reducers'

const rootReducers = combineReducers({
  headerSearchData: headerSearchReducer,
  message: readMessageReducer,
  notification: readNotificationReducer,
  users: UsersReducer,
  auth: authReducer,
  ChangeLayoutMode,
  reservation: ReservationReducer,
  carOwners: CarOwnersReducer,
  cars: CarsReducer,
  carRenters: CarRentersReducer,
  products: ProductsReducer,
  services: ServicesReducer,
  storage: StorageReducer,
  branchOffices: BranchOfficesReducer
})

export default rootReducers
