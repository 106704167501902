import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Spin } from 'antd'
import { PageHeader } from '../../../components/page-headers/page-headers'
import { Main, CardToolbox } from '../../styled'

import { Button } from '../../../components/buttons/buttons'

import { useTranslation } from 'react-i18next'
import { AutoComplete } from '../../../components/autoComplete/autoComplete'

import { useDispatch, useSelector } from 'react-redux'
import { GetGraphQlData } from './dataQueries'
import { setAll, setTableFiler } from '../../../redux/storage/actionCreator'
import List from './list'
const graphqlVariables = {
  tokenGet: process.env.REACT_APP_TOKEN_GET,
  tokenMutation: process.env.REACT_APP_TOKEN_MUTATION
}

const Warehouse = () => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()

  const tableData = useSelector(state => state?.storage?.all)
  const listData = useSelector(state => state?.storage?.tableFilter)
  const handleSearch = searchText => {
    const data = tableData.filter(
      item =>
        item?.number.toUpperCase().includes(searchText.toUpperCase()) ||
        item?.car?.licence_plate
          .toUpperCase()
          .includes(searchText.toUpperCase()) ||
        item?.car?.carOwner?.company
          .toUpperCase()
          .includes(searchText.toUpperCase()) ||
        item?.car?.carRenter?.company
          .toUpperCase()
          .includes(searchText.toUpperCase()) ||
        item?.car?.branchOffice?.company
          .toUpperCase()
          .includes(searchText.toUpperCase())
    )
    dispatch(setTableFiler(data))
  }

  const [loadData, setLoadData] = useState(false)
  const [loadingList, setLoadingList] = useState(true)
  const [warehouse, setWarehouse] = useState()

  useEffect(() => {
    setLoadData(true)
  }, [])

  const graphQlData = GetGraphQlData(graphqlVariables, loadData)
  const storageList = graphQlData[0]?.data?.getStorageList

  if (storageList) {
    const spinnerStatus = graphQlData?.loading
    setLoadData(false)
    dispatch(setAll(storageList))
    setWarehouse(storageList)
    setLoadingList(spinnerStatus)
  }

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('backend.warehouse.title')}
          subTitle={
            <>
              <span className='title-counter'>{t('carOwners.filter')} </span>
              <AutoComplete
                onSearch={handleSearch}
                placeholder={t('carOwners.startWritting')}
                width='100%'
                patterns
              />
            </>
          }
          buttons={[
            <Button
              // onClick={newRecordRedirect}
              className='btn-add_new'
              size='default'
              type='primary'
              key='1'
            >
              <Link to='./warehouse-new'>{t('common.addRecord')}</Link>
            </Button>
          ]}
        />
      </CardToolbox>
      <Main>
        <Row gutter={15}>
          <Col md={24}>
            {loadingList && (
              <div className='spin'>
                <Spin />
              </div>
            )}
            {!loadingList && (
              <List inputData={listData} graphqlVariables={graphqlVariables} />
            )}
          </Col>
        </Row>
      </Main>
    </>
  )
}

export default Warehouse
