import React, { useState, useCallback } from 'react'
import { Form } from 'antd'
import { Modal } from '../../components/modals/antd-modals'
import { Tab1 } from './overview/tabs/tab1'
import { CREATE_CAR_RENTER } from '../../graphql/AdminMutations'
import { setAllCarRenters } from '../../redux/car_renters/actionCreator'
import displayNotification from '../reservation/admin/notification/notification'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'

const NewRecordModal = props => {
  const { t, gridState, handleCancel, onCancel, tableData } = props

  const dispatch = useDispatch()
  const graphqlVariables = {
    tokenMutation: process.env.REACT_APP_TOKEN_MUTATION
  }
  const [triggerCreateCompany] = useMutation(CREATE_CAR_RENTER, {
    variables: graphqlVariables
  })

  const handleEditOk = newCompany => {
    onCancel()

    let newData = [...tableData]
    newCompany.id = tableData.length + 1
    newData.push(newCompany)
    const variables = {
      ...graphqlVariables,
      active: true,
      firstname: newCompany.firstname,
      lastname: newCompany.lastname,
      company: newCompany.company,
      zip: newCompany.zip,
      street: newCompany.street,
      city: newCompany.city,
      phone: newCompany.phone,
      email: newCompany.email,
      ic: newCompany.ic,
      dic: newCompany.dic,
      ic_dph: newCompany.ic_dph,
      reservation_emails: newCompany.reservation_emails
    }

    triggerCreateCompany({ variables }).then(({ data }) => {
      let type = 'success'
      const message = t('backend.notifications.update')
      let desc = t('backend.notifications.success.recordUpdate')
      if (data.createCarRenter !== undefined) {
        dispatch(setAllCarRenters(newData))
      } else {
        type = 'error'
        desc = t('backend.notifications.error.recordUpdate')
      }
      displayNotification(type, message, desc)
    })
  }

  const [form] = Form.useForm()

  return (
    <Modal
      type='primary'
      title={t('serviceActions.tableList.carRenter')}
      visible={gridState.addNewRecord}
      footer={null}
      onCancel={handleCancel}
      width={600}
    >
      <div className='project-modal'>
        <Tab1
          t={t}
          data={'selectedItem'}
          setState={'setState'}
          formReadonly={false}
          form={form}
          handleEditOk={handleEditOk}
        />
      </div>
    </Modal>
  )
}

export default NewRecordModal
