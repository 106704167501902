const sendBusinessLeaseInvoices = async () => {
  var axios = require('axios')

  const url =
    process.env.REACT_APP_BACKEND_API_URL +
    '/export/business-lease-invoices?apikey=' +
    process.env.REACT_APP_TOKEN_API
  var config = {
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    }
  }

  return await axios(config)
    .then(function (response) {
      return {
        status: true,
        data: response.data
      }
    })
    .catch(function (error) {
      console.log(error)
      return {
        status: false,
        error: error
      }
    })
}

export default sendBusinessLeaseInvoices
