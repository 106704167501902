import React from 'react'
import moment from 'moment'
import { Form } from 'antd'
import { Modal } from '../../../components/modals/antd-modals'
import { Tab1 } from './tabs/tab1'
import { CREATE_SERVICE_ACTTION } from '../../../graphql/AdminMutations'
import displayNotification from '../../reservation/admin/notification/notification'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'

const NewRecordModal = props => {
  const history = useHistory()
  const {
    t,
    gridState,
    handleCancel,
    onCancel,
    taxes,
    cars,
    branchOffices,
    setRedirectToDetail
  } = props
  const windowWidth = window.innerWidth
  setRedirectToDetail(true)
  const graphqlVariables = {
    tokenMutation: process.env.REACT_APP_TOKEN_MUTATION
  }
  const [triggerCreateReccord] = useMutation(CREATE_SERVICE_ACTTION, {
    variables: graphqlVariables
  })

  let taxesOptions = []
  for (const index in taxes) {
    taxesOptions.push({
      value: taxes[index].value,
      label: taxes[index].value
    })
  }

  const handleEditOk = newItem => {
    onCancel()
    let carObj = {}
    const carId = newItem.car
    for (const item of cars) {
      if (carId === item.id) {
        carObj = item
      }
    }
    const storaged = newItem?.storaged ? 1 : 0
    const variables = {
      ...graphqlVariables,
      status: 'open',
      mileage: Number(newItem.mileage),
      car: Number(carId),
      carOwner: Number(carObj.carOwner.id),
      note: newItem.note,
      date: moment(newItem.date).format('YYYY-MM-DD'),
      invoiceIssueDate: moment(newItem.date).format('YYYY-MM-DD'),
      email_send: 0,
      total: 0,
      storaged: storaged
    }

    triggerCreateReccord({ variables }).then(({ data }) => {
      let type = 'success'
      const message = t('backend.notifications.create')
      let desc = t('backend.notifications.success.recordCreate')
      const result = data?.createServiceAction
      const id = result?.id
      const mileage = result?.mileage
      const car = result?.car
      if (result === undefined) {
        type = 'error'
        desc = t('backend.notifications.error.recordCreate')
        displayNotification(type, message, desc)
        setRedirectToDetail(false)
      } else {
        if (result.storaged === true) {
          history.push(
            '/admin/service/warehouse-new-service/' +
              id +
              '/' +
              car?.id +
              '/' +
              mileage
          )
        } else {
          history.push('/admin/service/action/' + id)
        }
      }
    })
  }

  const [form] = Form.useForm()

  return (
    <Modal
      type='primary'
      title={t('services.newServiceRecord')}
      visible={gridState.addNewRecord}
      footer={null}
      onCancel={handleCancel}
      width={windowWidth * 0.5}
    >
      <div className='project-modal'>
        <Tab1
          t={t}
          data={'selectedItem'}
          formReadonly={false}
          form={form}
          handleEditOk={handleEditOk}
          taxes={taxes}
          type='new'
          branchOffices={branchOffices}
          cars={cars}
        />
      </div>
    </Modal>
  )
}

export default NewRecordModal
