import React, { useState, useEffect } from 'react'
import { BasicFormWrapper, CardToolbox, Main } from '../../styled'
import { Form, Input, Row, Col, DatePicker, PageHeader, Radio } from 'antd'
import { Button } from '../../../components/buttons/buttons'
import { useTranslation } from 'react-i18next'
import { GetAllCars } from '../../car/newCarDataQueries'
import { SelectCar } from '../../../components/customSelect/selectCarWarehouse'
import { CREATE_STORAGE_RECORD } from '../../../graphql/AdminMutations'
import displayNotification from '../../reservation/admin/notification/notification'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
const dateFormat = 'YYYY-MM-DD'
const graphqlVariables = {
  tokenGet: process.env.REACT_APP_TOKEN_GET,
  tokenMutation: process.env.REACT_APP_TOKEN_MUTATION
}

const NewItemFromServiceAction = props => {
  const { t } = useTranslation('common')
  const [form] = Form.useForm()
  const history = useHistory()

  const [loadData, setLoadData] = useState(false)
  const [selectedCarId, setSelectedCarId] = useState(
    props?.match?.params?.carId
  )
  useEffect(() => {
    setLoadData(true)
  }, [])
  const [triggerCreateReccord] = useMutation(CREATE_STORAGE_RECORD, {
    variables: graphqlVariables
  })

  const mileage = props?.match?.params?.mileage
    ? props?.match?.params?.mileage
    : 0

  const serviceId = props?.match?.params?.id
    ? props?.match?.params?.id
    : undefined

  const getAllCarsData = GetAllCars(graphqlVariables, loadData)
  const processedCarsData = getAllCarsData[0]?.data?.getCars

  if (selectedCarId && processedCarsData) {
    // setLoadData(false)
    const result = processedCarsData.find(item => {
      item?.id === selectedCarId
    })

    const carStorage = result?.storage
    form.setFieldsValue({
      licence_plate: selectedCarId,
      speedometer: mileage
    })
    if (carStorage?.length > 2) {
      form.setFieldsValue({
        licence_plate: selectedCarId,
        number: carStorage[1]?.number,
        tyre_brand: carStorage[1]?.tyre_brand,
        qty: carStorage[1]?.qty,
        season: carStorage[1]?.season,
        disk_type: carStorage[1]?.disk_type,
        note: carStorage[1]?.note,
        thread: carStorage[1]?.thread
      })
    }
  }

  const handleEditOk = values => {
    const variables = {
      ...graphqlVariables,
      carId: values?.licence_plate,
      number: values?.number,
      tyre_brand: values?.tyre_brand,
      date: moment(values?.date).format(dateFormat),
      note: values?.note,
      thread: values?.thread,
      qty: Number(values?.qty),
      disk_type: values?.disk_type,
      speedometer: Number(values?.speedometer),
      season: values?.season
    }
    triggerCreateReccord({ variables }).then(({ data }) => {
      let type = 'success'
      const message = t('backend.notifications.create')
      let desc = t('backend.notifications.success.recordCreate')

      if (!data?.createStorageRecord?.sucessful) {
        type = 'error'
        desc = t('backend.notifications.error.recordCreate')
        displayNotification(type, message, desc)
      } else {
        history.push('/admin/service/action/' + serviceId)
      }
    })
  }

  return (
    <>
      <CardToolbox>
        <PageHeader ghost title={t('backend.warehouse.newItem')} />
      </CardToolbox>
      <Main>
        <BasicFormWrapper>
          <Form form={form} name='addNewWarehouseItem' onFinish={handleEditOk}>
            <Row gutter={15}>
              <Col md={12} sm={12} xs={24}>
                <Form.Item label={t('car.licencePlate')} name='licence_plate'>
                  <SelectCar
                    data={processedCarsData}
                    required={true}
                    selectedCarId={setSelectedCarId}
                  />
                </Form.Item>
              </Col>
              <Col md={6} sm={6} xs={24}>
                <Form.Item
                  label={t('backend.warehouse.number')}
                  name='number'
                  rules={[{ required: true }]}
                >
                  <Input placeholder='' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={24} sm={24} xs={24}>
                <Form.Item
                  label={t('backend.warehouse.tyreType')}
                  name='tyre_brand'
                >
                  <Input placeholder='(napr. Michelin 195/65 R15 91 H ENERGY)' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={4} sm={4} xs={24}>
                <Form.Item
                  label={t('backend.warehouse.qty')}
                  name='qty'
                  rules={[{ required: true }]}
                >
                  <Input placeholder='' />
                </Form.Item>
              </Col>
              <Col md={4} sm={4} xs={24}>
                <Form.Item
                  label={t('backend.warehouse.thread')}
                  name='thread'
                  rules={[{ required: true }]}
                >
                  <Input placeholder='(uvádzajte vo formáte x-x-x-x)' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={4} sm={4} xs={24}>
                <Form.Item
                  label={t('backend.warehouse.speedometer')}
                  name='speedometer'
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={4} xs={24}>
                <Form.Item
                  label='Dátum'
                  name='date'
                  rules={[{ required: true }]}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={4} sm={4} xs={24}>
                <Form.Item
                  label={t('backend.warehouse.diskType')}
                  name='disk_type'
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    <Radio value='Bez Disku'>Bez Disku</Radio>
                    <Radio value='ALU'>ALU</Radio>
                    <Radio value='PLECH'>PLECH</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col md={4} sm={4} xs={24}>
                <Form.Item
                  label={t('backend.warehouse.season')}
                  name='season'
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    <Radio value='ZIMA'>ZIMA</Radio>
                    <Radio value='LETO'>LETO</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={24} sm={24} xs={24}>
                <Form.Item label={t('backend.warehouse.note')} name='note'>
                  <Input placeholder='' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={24} sm={24} xs={24} style={{ marginTop: '20px' }}>
                <Button
                  htmlType='submit'
                  size='default'
                  type='primary'
                  key='submit'
                >
                  {t('common.addRecord')}
                </Button>
              </Col>
            </Row>
          </Form>
        </BasicFormWrapper>
      </Main>
    </>
  )
  // return (
  //   <>
  //     <BasicFormWrapper>
  //       <Form name='addNewWarehouseItem' onFinish={handleEditOk}>
  //         <Row gutter={15}>
  //           <Col md={4} sm={4} xs={24}>
  //             <Form.Item
  //               label={t('products.tableList.qty')}
  //               name='qty'
  //               rules={[{ required: true }]}
  //             >
  //               <Input />
  //             </Form.Item>
  //           </Col>
  //         </Row>
  //       </Form>
  //     </BasicFormWrapper>
  //   </>
  // )
}

export default NewItemFromServiceAction
