import React from 'react'
import { Table } from 'ant-table-extensions'
import FeatherIcon from 'feather-icons-react'
import { UserTableStyleWrapper } from '../../pages/style'
import { TableWrapper } from '../../styled'
import { Button } from '../../../components/buttons/buttons'
import { Cards } from '../../../components/cards/frame/cards-frame'
import { useTranslation } from 'react-i18next'
import { formatSQLDate } from '../../../utils/common'
import { useHistory } from 'react-router-dom'
import { DELETE_STORAGE_RECORD } from '../../../graphql/AdminMutations'
import displayNotification from '../../reservation/admin/notification/notification'
import { useMutation } from '@apollo/client'

const List = props => {
  const { t } = useTranslation('common')
  const { inputData } = props

  const graphqlVariables = {
    tokenMutation: process.env.REACT_APP_TOKEN_MUTATION
  }
  // delete storage record
  const [triggerDeleteStorageItem] = useMutation(DELETE_STORAGE_RECORD, {
    variables: graphqlVariables
  })

  const onHandleDelete = item => {
    const confirm = window.confirm(t('backend.warehouse.delete'))
    if (confirm) {
      const variables = {
        ...graphqlVariables,
        id: parseInt(item.id)
      }

      triggerDeleteStorageItem({ variables }).then(({ data }) => {
        const response = data?.deleteStorageRecord
        let type = 'success'
        const message = t('backend.notifications.delete')
        let desc = t('backend.notifications.success.recordDelete')

        if (!response?.sucessful) {
          type = 'error'
          desc = t('backend.notifications.error.recordDelete')
          displayNotification(type, message, desc)
        } else {
          displayNotification(type, message, desc)
          setTimeout(function () {
            window.location.replace('/admin/service/warehouse')
          }, 3000)
        }
      })
    }
  }

  // delete service action
  // const [triggerDeleteItem] = useMutation(DELETE_SERVICE_ACTION, {
  //   variables: graphqlVariables
  // })

  const history = useHistory()

  const routeChange = item => {
    const detailPath = `/admin/service/warehouse-item/${item.id}`
    history.push(detailPath)
  }

  const tableData = []
  let usedIds = {}
  inputData &&
    inputData.map(item => {
      const { id, car } = item
      const insertedId = car?.id
      const link = '/admin/car/edit/' + insertedId
      if (!usedIds.hasOwnProperty(insertedId)) {
        // check if id is already inserted
        usedIds[insertedId] = insertedId
        return tableData.push({
          key: id,
          licence_plate: (
            <a key={id} href={link}>
              <span className={`status-text`}>{car?.licence_plate}</span>
            </a>
          ),
          branchOffice: car?.branchOffice?.company,
          carOwner: car?.carOwner?.company,
          carRenter: car?.carRenter?.company,
          date: formatSQLDate(item.date),
          number: item.number,
          thread: item?.thread,
          tyreBrand: item?.tyre_brand,
          diskType: item?.disk_type,
          season: item?.season,
          action: (
            <div className='table-actions'>
              <>
                <Button
                  onClick={() => routeChange(item)}
                  className='btn-icon'
                  type='info'
                  to='#'
                  shape='circle'
                >
                  <FeatherIcon icon='edit' size={16} />
                </Button>
                <Button
                  onClick={() => onHandleDelete(item)}
                  className='btn-icon'
                  type='info'
                  to='#'
                  shape='circle'
                >
                  <FeatherIcon icon='trash-2' size={16} />
                </Button>
              </>
            </div>
          )
        })
      }
    })

  return (
    <Cards headless>
      <UserTableStyleWrapper>
        <TableWrapper className='table-responsive'>
          <Table
            dataSource={tableData}
            columns={getTableColumns(t)}
            pagination={{
              defaultPageSize: 20,
              total: tableData.length,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} ${t('common.from')} ${total} ${t(
                  'common.items'
                )}`
            }}
            exportableProps={{
              fileName: 'uskladnenky',
              btnProps: {
                type: 'primary',
                children: <span>Export do CSV</span>
              }
            }}
          />
        </TableWrapper>
      </UserTableStyleWrapper>
    </Cards>
  )
}

const getTableColumns = t => {
  return [
    {
      title: t('serviceActions.tableList.licencePlate'),
      dataIndex: 'licence_plate',
      key: 'licence_plate'
    },
    {
      title: t('backend.warehouse.number'),
      dataIndex: 'number',
      key: 'number'
    },
    {
      title: 'Dátum',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: t('common.pneuservice'),
      dataIndex: 'branchOffice',
      key: 'branchOffice'
    },
    {
      title: t('serviceActions.tableList.carOwner'),
      dataIndex: 'carOwner',
      key: 'carOwner'
    },
    {
      title: t('serviceActions.tableList.carRenter'),
      dataIndex: 'carRenter',
      key: 'carRenter'
    },
    {
      title: t('backend.warehouse.thread'),
      dataIndex: 'thread',
      key: 'thread'
    },
    {
      title: 'PNEU',
      dataIndex: 'tyreBrand',
      key: 'tyreBrand'
    },
    {
      title: t('backend.warehouse.diskType'),
      dataIndex: 'diskType',
      key: 'diskType'
    },
    {
      title: t('backend.warehouse.season'),
      dataIndex: 'season',
      key: 'season'
    },
    {
      title: t('common.actions'),
      dataIndex: 'action',
      key: 'action',
      width: '90px'
    }
  ]
}

export default List
