import React, { useState } from 'react'
import { Button, Col, Form, Input, InputNumber, Row } from 'antd'
import { CREATE_SERVICE_ACTION_ITEM } from '../../../graphql/AdminMutations'
import displayNotification from '../../reservation/admin/notification/notification'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { Modal } from '../../../components/modals/antd-modals'
import { BasicFormWrapper } from '../../styled'
import { SelectProduct } from '../../../components/customSelect/selectProduct'
import { formatPrice } from '../../../utils/common'

const NoteModal = props => {
  const { noteModalData, gridState, handleCancel, t } = props
  const windowWidth = window.innerWidth

  return (
    <Modal
      type='primary'
      title={t('products.tableList.note')}
      visible={gridState.showNoteModal}
      footer={null}
      onCancel={handleCancel}
      width={windowWidth * 0.5}
    >
      <div className='project-modal'>
        <p>{noteModalData}</p>
      </div>
    </Modal>
  )
}

export default NoteModal
