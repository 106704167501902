import actions from './actions'

const {
  carOwnersSetAll,
  filterSetTable,
  companyUpdate,
  companyCreate,
  filterSetTableTab2
} = actions

const setAllCarOwners = data => {
  return async dispatch => {
    dispatch(carOwnersSetAll(data))
  }
}

const setTableFiler = data => {
  return async dispatch => {
    dispatch(filterSetTable(data))
  }
}

const setTableTab2Filer = data => {
  return async dispatch => {
    dispatch(filterSetTableTab2(data))
  }
}

const updateCompany = data => {
  return async dispatch => {
    dispatch(companyUpdate(data))
  }
}

const createCompany = data => {
  return async dispatch => {
    dispatch(companyCreate(data))
  }
}

export {
  setAllCarOwners,
  setTableFiler,
  updateCompany,
  createCompany,
  setTableTab2Filer
}
