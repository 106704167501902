import { useQuery } from '@apollo/client'

import { GET_CAR_RENTERS, GET_RENTER_CARS } from '../../graphql/AdminQueries'

const GetCarRenters = (graphqlVariables, loadData) => {
  const carRenters = useQuery(GET_CAR_RENTERS, {
    variables: graphqlVariables,
    skip: !loadData
  })
  if (loadData && carRenters?.data?.getCarRenters) {
    return [carRenters]
  }
  return undefined
}

const GetRenterCars = (graphqlVariables, loadData) => {
  const cars = useQuery(GET_RENTER_CARS, {
    variables: graphqlVariables,
    skip: !loadData
  })
  return [cars]
}

export { GetCarRenters, GetRenterCars }
