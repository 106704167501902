import React from 'react'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'

const { Option } = Select

const SelectPaymentType = props => {
  const { paymentType, ...rest } = props
  const { t } = useTranslation('common')

  const options = []
  const data = [
    { id: 'bank', name: 'Banka' },
    { id: 'cash', name: 'Hotovosť' }
  ]
  if (data !== undefined) {
    for (let item of data) {
      const { id, name } = item
      const row = (
        <Option key={id} value={id} label={name}>
          {name}
        </Option>
      )
      options.push(row)
    }
  }
  const handleChange = payment => {
    paymentType(payment)
  }

  return (
    <Select
      // {...rest}
      showSearch={false}
      placeholder='vyberte'
      optionFilterProp='children'
      required={true}
      defaultValue='bank'
      onChange={handleChange}
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
    >
      {options}
    </Select>
  )
}

export { SelectPaymentType }
