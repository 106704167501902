import React from 'react'
import { Form, Input, Row, Col } from 'antd'
import { BasicFormWrapper } from '../../../styled'
import { Button } from '../../../../components/buttons/buttons'
import TextArea from 'antd/lib/input/TextArea'

const Tab1 = props => {
  const { t, formReadonly, form, handleEditOk } = props

  return (
    <>
      <BasicFormWrapper>
        <Form form={form} name='contactEdit' onFinish={handleEditOk}>
          <Row gutter={15}>
            <Col md={24} sm={24} xs={24}>
              <Form.Item
                label={t('carOwners.modal.tabs.tab1.company')}
                name='company'
                rules={[{ required: true }]}
              >
                <Input disabled={formReadonly} />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                label={t('carOwners.modal.tabs.tab1.firstname')}
                name='firstname'
              >
                <Input disabled={formReadonly} />
              </Form.Item>
              <Form.Item
                label={t('carOwners.modal.tabs.tab1.street')}
                name='street'
                rules={[{ required: true }]}
              >
                <Input disabled={formReadonly} />
              </Form.Item>
              <Form.Item
                label={t('carOwners.modal.tabs.tab1.zip')}
                name='zip'
                rules={[{ required: true }]}
              >
                <Input disabled={formReadonly} />
              </Form.Item>
              <Form.Item
                label={t('carOwners.modal.tabs.tab1.ic')}
                name='ic'
                rules={[{ required: true }]}
              >
                <Input disabled={formReadonly} />
              </Form.Item>
              <Form.Item
                label={t('carOwners.modal.tabs.tab1.dic')}
                name='dic'
                rules={[{ required: true }]}
              >
                <Input disabled={formReadonly} />
              </Form.Item>
              <Form.Item
                label={t('carOwners.modal.tabs.tab1.ic_dph')}
                name='ic_dph'
              >
                <Input disabled={formReadonly} />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                label={t('carOwners.modal.tabs.tab1.lastname')}
                name='lastname'
              >
                <Input disabled={formReadonly} />
              </Form.Item>
              <Form.Item
                label={t('carOwners.modal.tabs.tab1.city')}
                name='city'
                rules={[{ required: true }]}
              >
                <Input disabled={formReadonly} />
              </Form.Item>
              <Form.Item
                label={t('carOwners.modal.tabs.tab1.phone')}
                name='phone'
              >
                <Input disabled={formReadonly} />
              </Form.Item>
              <Form.Item label='E-mail' name='email'>
                <Input disabled={formReadonly} />
              </Form.Item>
              <Form.Item label='Poznámka' name='note'>
                <TextArea disabled={formReadonly} />
              </Form.Item>
            </Col>
          </Row>
          <>
            {!formReadonly && (
              <Row gutter={15}>
                <Col md={24} sm={24} xs={24} style={{ marginTop: '20px' }}>
                  <Button
                    htmlType='submit'
                    size='default'
                    type='primary'
                    key='submit'
                  >
                    {t('common.updateRecord')}
                  </Button>
                </Col>
              </Row>
            )}
          </>
        </Form>
      </BasicFormWrapper>
    </>
  )
}

export { Tab1 }
