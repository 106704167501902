import React, { Suspense } from 'react'
import { Spin } from 'antd'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import CarsGrid from '../../container/car/grid'
import AddNewCar from '../../container/car/overview/AddCar'
import EditCar from '../../container/car/overview/EditCar'

const CarRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Suspense
        fallback={
          <div className='spin'>
            <Spin />
          </div>
        }
      >
        <Route exact path={`${path}/`} component={CarsGrid} />
        <Route path={`${path}/add`} component={AddNewCar} />
        <Route exact path={`${path}/edit/:id`} component={EditCar} />
      </Suspense>
    </Switch>
  )
}

export default CarRoutes
