import actions from './actions'

const {
  carsSetAll,
  carsSetTable,
  carsAddCar,
  carsUpdateCar,
  carsDeleteCar,
  carsSetNewCarDataSelect
} = actions

const setAllCars = data => {
  return async dispatch => {
    dispatch(carsSetAll(data))
    // try {
    //   dispatch(carsGetAll(dara));
    // } catch (err) {
    //   dispatch(loginErr(err));
    // }
  }
}

const setNewCarDataSelect = data => {
  return async dispatch => {
    dispatch(carsSetNewCarDataSelect(data))
  }
}

const setTableCars = data => {
  return async dispatch => {
    dispatch(carsSetTable(data))
  }
}

const addCar = data => {
  return async dispatch => {
    dispatch(carsAddCar(data))
  }
}

const updateCar = data => {
  return async dispatch => {
    dispatch(carsUpdateCar(data))
  }
}

const deleteCar = id => {
  return async dispatch => {
    dispatch(carsDeleteCar(id))
  }
}

export {
  setAllCars,
  setTableCars,
  addCar,
  updateCar,
  deleteCar,
  setNewCarDataSelect
}
