import { Table } from 'antd'
import React, { useState, useEffect } from 'react'
import { Form, Input } from 'antd'
import { Cards } from '../../../../components/cards/frame/cards-frame'
import { UserTableStyleWrapper } from '../../../pages/style'
import { TableWrapper } from '../../../styled'
import FeatherIcon from 'feather-icons-react'
import { Button } from '../../../../components/buttons/buttons'

import { useDispatch, useSelector } from 'react-redux'
import { AutoComplete } from '../../../../components/autoComplete/autoComplete'
import { UPDATE_CUSTOM_PRICE } from '../../../../graphql/AdminMutations'
import {
  setCarOwnerAllProducts,
  setTableFilterCarOwnerProducts
} from '../../../../redux/products/actionCreator'
import displayNotification from '../../../reservation/admin/notification/notification'

import { useMutation } from '@apollo/client'

const Tab3 = props => {
  const { t, products, selectedItem, graphqlVariables } = props
  const carOwnerId = Number(selectedItem.id)
  const tableData = useSelector(state => state.products?.allProductsCarOwner)
  const dispatch = useDispatch()
  const listData = useSelector(
    state => state?.products?.tableFilterCarOwnerProducts
  )
  const tab3TableData = []

  // update / insert custom price
  const [triggerUpdateCustomPrice] = useMutation(UPDATE_CUSTOM_PRICE, {
    variables: graphqlVariables
  })

  const [customPrice, setCustomPrice] = useState()

  useEffect(() => {
    dispatch(setCarOwnerAllProducts(products))
    dispatch(setTableFilterCarOwnerProducts(products))
  }, [products])

  const changePriceAction = item => {
    const productId = Number(item.id)

    const variables = {
      ...graphqlVariables,
      id: item.price_sale_custom_id ? item.price_sale_custom_id : undefined,
      price_sale: customPrice[productId]
        ? Number(customPrice[productId])
        : Number(item.price_sale),
      product: productId,
      carOwner: carOwnerId
    }

    // update database

    triggerUpdateCustomPrice({ variables }).then(({ data }) => {
      let type = 'success'
      const message = t('backend.notifications.update')
      let desc = t('backend.notifications.success.recordUpdate')
      if (data.updateCustomPrice !== undefined) {
      } else {
        type = 'error'
        desc = t('backend.notifications.error.recordUpdate')
      }
      displayNotification(type, message, desc)
    })
  }

  const handleChange = event => {
    const id = event?.target?.id
    const value = Number(event?.target?.value)
    let object = {}
    if (typeof customPrice === 'object') {
      object = customPrice
    }
    object[id] = value
    setCustomPrice(object)
  }

  listData &&
    listData.map(item => {
      const { id, name, price_sale } = item
      let price_sale_custom = price_sale
      let price_sale_custom_id = 0
      const updatedItem = { ...item }
      // find custom price if exist
      const { priceLists } = item
      for (const index in priceLists) {
        if (Number(priceLists[index].carOwner.id) === carOwnerId) {
          price_sale_custom = priceLists[index].price_sale
          price_sale_custom_id = Number(priceLists[index].id)
        }
      }
      updatedItem.price_sale_custom = price_sale_custom
      updatedItem.price_sale_custom_id = price_sale_custom_id

      return tab3TableData.push({
        key: id,
        name: name,
        price_sale: price_sale,
        price_sale_custom_id: price_sale_custom_id,
        price_sale_custom: (
          <Form.Item
            initialValue={price_sale_custom}
            name={carOwnerId + '-' + id}
          >
            <Input
              // defaultValue={price_sale_custom}
              onChange={handleChange}
              id={id}
            />
          </Form.Item>
        ),
        action: (
          <div className='table-actions'>
            <>
              <Button
                onClick={() => changePriceAction(updatedItem)}
                className='btn-icon'
                type='info'
                to='#'
                shape='circle'
              >
                <FeatherIcon icon='save' size={16} />
              </Button>
            </>
          </div>
        )
      })
    })

  const handleSearch = searchText => {
    const data = tableData.filter(item => {
      return item.name.toUpperCase().includes(searchText.toUpperCase())
    })
    dispatch(setTableFiler(data))
  }

  return (
    <Cards headless>
      <div style={{ marginBottom: '20px' }}>
        <AutoComplete
          onSearch={handleSearch}
          placeholder={t('carOwners.startWritting')}
          width='100%'
          patterns
        />
      </div>
      <UserTableStyleWrapper>
        <TableWrapper className='table-responsive price-list-car-owner'>
          <Form>
            <Table
              dataSource={tab3TableData}
              columns={getTableColumns(t)}
              pagination={{
                defaultPageSize: 20,
                total: tab3TableData ? tab3TableData.length : 0,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} ${t('common.from')} ${total} ${t(
                    'common.items'
                  )}`
              }}
            />
          </Form>
        </TableWrapper>
      </UserTableStyleWrapper>
    </Cards>
  )
}
const getTableColumns = t => {
  return [
    {
      title: t('products.tableList.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('products.tableList.price_sale'),
      dataIndex: 'price_sale',
      key: 'price_sale',
      width: '120px'
    },
    {
      title: t('products.tableList.price_sale_custom'),
      dataIndex: 'price_sale_custom',
      key: 'price_sale_custom',
      width: '120px'
    },
    {
      title: t('common.actions'),
      dataIndex: 'action',
      key: 'action',
      width: '90px'
    }
  ]
}
export { Tab3 }
