const actions = {
  USERS_SET_ALL: 'USERS_SET_ALL',
  USERS_UPDATE: 'USERS_UPDATE',
  USERS_DELETE: 'USERS_DELETE',

  usersSetAll: data => {
    return {
      type: actions.USERS_SET_ALL,
      data
    }
  },
  usersUpdateUser: (data, id) => {
    return {
      type: actions.USERS_UPDATE,
      data,
      id
    }
  },
  usersDeleteUser: (data, id) => {
    return {
      type: actions.USERS_DELETE,
      data,
      id
    }
  }
}

export default actions
