import React, { useState, useCallback } from 'react'
import { Table, Form, Input, Row, Col, Checkbox } from 'antd'
import FeatherIcon from 'feather-icons-react'
import { UserTableStyleWrapper } from '../../pages/style'
import { TableWrapper } from '../../styled'
// import Heading from '../../../components/heading/heading'
import { Button } from '../../../components/buttons/buttons'
import { Cards } from '../../../components/cards/frame/cards-frame'
import { Modal } from '../../../components/modals/antd-modals'
import { BasicFormWrapper } from '../../styled'
// import { AddUser } from '../../pages/style'

import { useMutation } from '@apollo/client'
import { UPDATE_USER, DELETE_USER } from '../../../graphql/AdminMutations'

import { useDispatch, useSelector } from 'react-redux'
import { updateUser, deleteUser } from '../../../redux/users/actionCreator'
import { useTranslation } from 'react-i18next'

import { SelectCompany } from '../../../components/customSelect/selectCompany'

const graphqlVariables = {
  tokenMutation: process.env.REACT_APP_TOKEN_MUTATION
}

const UserListTable = props => {
  const { t } = useTranslation('common')

  const { branchOficcesData, usersData } = props

  const dispatch = useDispatch()
  let tableData = useSelector(state => state.users?.allUsers)

  const [form] = Form.useForm()

  const [state, setState] = useState({
    editVisible: false,
    update: {}
  })

  const [formReadonly, setFormReadonly] = useState(true)

  const { update } = state

  const usersTableData = []

  // update user data
  const [triggerUpdateUserInfo] = useMutation(UPDATE_USER, {
    variables: graphqlVariables
  })

  // delete user
  const [triggerDeleteUser] = useMutation(DELETE_USER, {
    variables: graphqlVariables
  })

  tableData &&
    tableData.map(user => {
      const { id, firstname, lastname, active, branchOffice, email } = user
      const { company } = branchOffice

      return usersTableData.push({
        key: id,
        firstname: firstname,
        lastname: lastname,
        email: email,
        active: active ? t('common.yes') : t('common.no'),
        branchOffice: company,
        action: (
          <div className='table-actions'>
            <>
              <Button
                onClick={() => showEditModal(user)}
                className='btn-icon'
                type='info'
                to='#'
                shape='circle'
              >
                <FeatherIcon icon='eye' size={16} />
              </Button>
              <Button
                onClick={() => showEditModal(user, 'edit')}
                className='btn-icon'
                type='info'
                to='#'
                shape='circle'
              >
                <FeatherIcon icon='edit' size={16} />
              </Button>
              <Button
                onClick={() => onHandleDelete(id)}
                className='btn-icon'
                type='danger'
                to='#'
                shape='circle'
              >
                <FeatherIcon icon='trash-2' size={16} />
              </Button>
            </>
          </div>
        )
      })
    })

  const showEditModal = (data, type = 'view') => {
    setEditFormFieldsValues(data)
    if (type === 'edit') {
      setFormReadonly(false)
    } else {
      setFormReadonly(true)
    }
    setState({
      ...state,
      editVisible: true,
      update: data
    })
  }

  const onCancel = () => {
    setState({
      ...state,
      editVisible: false,
      update: {}
    })
  }

  const handleCancel = () => {
    onCancel()
  }

  const onHandleDelete = id => {
    const confirm = window.confirm(t('user.deleteUserQuestion'))
    if (confirm) {
      const variables = {
        ...graphqlVariables,
        id: parseInt(id),
        active: false
      }

      triggerDeleteUser({ variables }).then(({ data }) => {
        const response = data?.deleteUser
        if (response.sucessful) {
          const newTableData = tableData.filter(object => {
            return object.id !== String(id)
          })
          const updatedUsers = getUpdatedUsers(newTableData, '', 'delete')

          dispatch(deleteUser(updatedUsers))
        }
      })
    }
  }

  const setEditFormFieldsValues = userInfo => {
    updateFormFieldValue('firstname', userInfo?.firstname)
    updateFormFieldValue('lastname', userInfo?.lastname)
    updateFormFieldValue('email', userInfo?.email)
    updateFormFieldValue('note', userInfo?.active)

    updateFormFieldValue('branchOffice', userInfo?.branchOffice?.company)
  }

  const updateFormFieldValue = useCallback(
    (fieldKey, fieldValue) => {
      form.setFieldsValue({ [fieldKey]: fieldValue })
    },
    [form]
  )

  const handleEditOk = values => {
    onCancel()
    const updatedUsers = getUpdatedUsers(tableData, values)

    // FORM submit returns:
    // ID of selected option - > if update field ,
    // name of the option -> if there is no update in the select
    const company = isNaN(values.branchOffice)
      ? branchOficcesData.find(({ company }) => company === values.branchOffice)
          .id
      : values.branchOffice

    const variables = {
      ...graphqlVariables,
      id: parseInt(update.id),
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      branchOffice: parseInt(company)
    }

    triggerUpdateUserInfo({ variables }).then(({ data }) => {
      dispatch(updateUser(updatedUsers))
    })
  }

  const tableColumns = [
    {
      title: t('user.firstname'),
      dataIndex: 'firstname',
      key: 'firstname'
    },
    {
      title: t('user.lastname'),
      dataIndex: 'lastname',
      key: 'lastname'
    },
    {
      title: t('user.company'),
      dataIndex: 'branchOffice',
      key: 'branchOffice'
    },
    {
      title: t('user.email'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: t('user.active'),
      dataIndex: 'active',
      key: 'active'
    },
    {
      title: t('common.actions'),
      dataIndex: 'action',
      key: 'action',
      width: '90px'
    }
  ]

  const getUpdatedUsers = (tableData, values, type = 'update') => {
    return tableData.map(user => {
      if (user.id === update.id && type === 'update') {
        const company = !isNaN(values.branchOffice)
          ? branchOficcesData.find(({ id }) => id === values.branchOffice)
              .company
          : values.branchOffice

        const updatedUser = { ...user }
        updatedUser.email = values.email
        updatedUser.firstname = values.firstname
        updatedUser.lastname = values.lastname
        updatedUser.branchOffice = { company: company }
        // updatedUser.active = values.active
        return updatedUser
      } else {
        return user
      }
    })
  }

  return (
    <Cards headless>
      <UserTableStyleWrapper>
        <TableWrapper className='table-responsive'>
          <Table
            dataSource={usersTableData}
            columns={tableColumns}
            pagination={{
              defaultPageSize: 10,
              total: usersTableData.length,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} ${t('common.from')} ${total} ${t(
                  'common.items'
                )}`
            }}
          />
        </TableWrapper>
      </UserTableStyleWrapper>
      <Modal
        type='primary'
        title={t('user.userInformation')}
        visible={state.editVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <div className='project-modal'>
          {/* <AddUser> */}
          <BasicFormWrapper>
            {update.id !== undefined ? (
              <Form form={form} name='contactEdit' onFinish={handleEditOk}>
                <Row gutter={15}>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      name='branchOffice'
                      label={t('user.company')}
                      rules={[{ required: true }]}
                    >
                      <SelectCompany
                        data={branchOficcesData}
                        disabled={formReadonly}
                      />
                    </Form.Item>
                    <Form.Item
                      label={t('user.firstname')}
                      name='firstname'
                      rules={[{ required: true }]}
                    >
                      <Input placeholder='' disabled={formReadonly} />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={t('user.email')}
                      name='email'
                      rules={[
                        {
                          type: 'email',
                          message: t('car.validation.wrongEmail')
                        },
                        {
                          required: true,
                          message: t('car.validation.email')
                        }
                      ]}
                    >
                      <Input placeholder='' disabled={formReadonly} />
                    </Form.Item>
                    <Form.Item
                      label={t('user.lastname')}
                      name='lastname'
                      rules={[{ required: true }]}
                    >
                      <Input placeholder='' disabled={formReadonly} />
                    </Form.Item>
                  </Col>
                </Row>
                <>
                  {!formReadonly && (
                    <Row gutter={15}>
                      <Col
                        md={24}
                        sm={24}
                        xs={24}
                        style={{ marginTop: '20px' }}
                      >
                        <Button
                          htmlType='submit'
                          size='default'
                          type='primary'
                          key='submit'
                        >
                          {t('common.updateRecord')}
                        </Button>
                      </Col>
                    </Row>
                  )}
                </>
              </Form>
            ) : null}
          </BasicFormWrapper>
          {/* </AddUser> */}
        </div>
      </Modal>
    </Cards>
  )
}

export default UserListTable
