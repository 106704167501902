import actions from './actions'

const {
  PRODUCTS_SET_ALL,
  FILTER_SET_TABLE,
  FILTER_SET_TAXES,
  PRODUCT_UPDATE,
  PRODUCTS_SET_ALL_CAR_OWNER_PRODUCTS,
  FILTER_SET_TABLE_CAR_OWNER_PRODUCTS
} = actions

const initialState = {
  allProducts: [],
  tableFilter: [],
  tableFilterCarOwnerProducts: [],
  allProductsCarOwner: [],
  taxes: []
}

const ProductsReducer = (state = initialState, action) => {
  const { type, data } = action
  switch (type) {
    case PRODUCTS_SET_ALL:
      return {
        ...state,
        allProducts: data,
        tableFilter: data
      }
    case FILTER_SET_TABLE:
      return {
        ...state,
        tableFilter: data
      }
    case FILTER_SET_TAXES:
      return {
        ...state,
        taxes: data
      }
    case PRODUCT_UPDATE:
      return {
        ...state,
        allProducts: data,
        tableFilter: data
      }
    case PRODUCTS_SET_ALL_CAR_OWNER_PRODUCTS:
      return {
        ...state,
        allProductsCarOwner: data,
        tableFilterCarOwnerProducts: data
      }
    case FILTER_SET_TABLE_CAR_OWNER_PRODUCTS:
      return {
        ...state,
        tableFilterCarOwnerProducts: data
      }
    default:
      return state
  }
}

export { ProductsReducer }
