import React, { Suspense } from 'react'
import { Spin } from 'antd'
import { Switch, Route, Redirect } from 'react-router-dom'
import ReservationLayout from '../container/reservation/index_simple'

//const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));

// const NotFound = () => {
//   return <Redirect to='/' />
// }

const ReservationWizard = () => {
  return <div> Create a new reservation</div>
}

const ReservationRoutes = () => {
  return (
    <Switch>
      <Suspense
        fallback={
          <div className='spin'>
            <Spin />
          </div>
        }
      >
        {/* <Route exact path="/" component={Login} /> */}
        <Route path='/' component={ReservationWizard} />
        {/* <Route exact path="/reservation" component={ReservationWizard} /> */}
        {/* <Route exact path="*" component={NotFound} /> */}
      </Suspense>
    </Switch>
  )
}

export default ReservationLayout(ReservationRoutes)
