import React from 'react'
import { Form, Input, Row, Col, Select } from 'antd'
import { BasicFormWrapper } from '../../../styled'
import { Button } from '../../../../components/buttons/buttons'

const Tab1 = props => {
  const { t, taxes, formReadonly, form, handleEditOk, type, setState } = props

  let taxesOptions = []
  for (const index in taxes) {
    taxesOptions.push({
      value: taxes[index].value,
      label: taxes[index].value
    })
  }

  const typeOptions = [
    {
      value: 'product',
      label: t('products.type.product')
    },
    {
      value: 'service',
      label: t('products.type.service')
    }
  ]
  return (
    <>
      <BasicFormWrapper>
        <Form form={form} name='edit' onFinish={handleEditOk}>
          <Row gutter={15}>
            <Col md={12} sm={12} xs={24}>
              <Form.Item
                label={t('products.tableList.name')}
                name='name'
                rules={[{ required: true }]}
              >
                <Input disabled={formReadonly} />
              </Form.Item>
            </Col>
            <Col md={4} sm={4} xs={24}>
              <Form.Item
                label={t('products.tableList.type')}
                name='type'
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: 120 }}
                  options={typeOptions}
                  disabled={formReadonly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col md={4} sm={4} xs={24}>
              <Form.Item
                label={t('products.tableList.price_purchase')}
                name='price_purchase'
              >
                <Input disabled={formReadonly} />
              </Form.Item>
            </Col>
            <Col md={4} sm={4} xs={24}>
              <Form.Item
                label={t('products.tableList.price_sale')}
                name='price_sale'
                rules={[{ required: true }]}
              >
                <Input disabled={formReadonly} />
              </Form.Item>
            </Col>
            <Col md={4} sm={4} xs={24}>
              <Form.Item
                label={t('products.tableList.taxPerc')}
                name='tax'
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: 120 }}
                  options={taxesOptions}
                  disabled={formReadonly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col md={4} sm={4} xs={24}>
              <Form.Item label={t('products.tableList.qty')} name='qty'>
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col md={4} sm={4} xs={24}>
              <Form.Item label='Kód' name='code' rules={[{ required: false }]}>
                <Input disabled={formReadonly} />
              </Form.Item>
            </Col>
          </Row>
          <>
            {!formReadonly && (
              <Row gutter={15}>
                <Col md={24} sm={24} xs={24} style={{ marginTop: '20px' }}>
                  <Button
                    htmlType='submit'
                    size='default'
                    type='primary'
                    key='submit'
                  >
                    {type === 'update' && t('common.updateRecord')}
                    {type === 'new' && t('common.addRecord')}
                  </Button>
                </Col>
              </Row>
            )}
          </>
        </Form>
      </BasicFormWrapper>
    </>
  )
}

export { Tab1 }
