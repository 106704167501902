import React, { useState, useCallback } from 'react'
import { Form } from 'antd'
import { Table } from 'ant-table-extensions'
import FeatherIcon from 'feather-icons-react'
import { UserTableStyleWrapper } from '../../pages/style'
import { TableWrapper } from '../../styled'

import { Button } from '../../../components/buttons/buttons'
import { Cards } from '../../../components/cards/frame/cards-frame'

import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { formatPrice, formatSQLDate } from '../../../utils/common'

import { useMutation } from '@apollo/client'
import { DELETE_SERVICE_ACTION } from '../../../graphql/AdminMutations'

import { deleteServiceAction } from '../../../redux/services/actionCreator'
import displayNotification from '../../reservation/admin/notification/notification'

import { useHistory } from 'react-router-dom'

// import { ModalForm } from './tabs/modalForm'

const List = props => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const { inputData, graphqlVariables, checkedStatus } = props
  const displayExportPdf = checkedStatus === 'open' ? true : false
  const [form] = Form.useForm()

  const [state, setState] = useState({
    editVisible: false,
    update: {},
    newIncomeModalState: false
  })

  // delete service action
  const [triggerDeleteItem] = useMutation(DELETE_SERVICE_ACTION, {
    variables: graphqlVariables
  })

  const history = useHistory()

  const routeChange = item => {
    const detailPath = `/admin/service/action/${item.id}`
    history.push(detailPath)
  }

  const [formReadonly, setFormReadonly] = useState(true)
  const [selectedItem, setSelectedItem] = useState({})

  const tableData = []
  const modalData = []
  inputData &&
    inputData.map(item => {
      const {
        id,
        branchOffice,
        car,
        carOwner,
        date,
        status,
        total,
        invoice_number,
        exportBlDate,
        exportBlStatus
      } = item
      const link = '/admin/car/edit/' + car?.id
      let invoiceNumber = invoice_number
      if (exportBlStatus === true) {
        invoiceNumber = (
          <ul>
            <span>{invoice_number}</span>
            <p>{formatSQLDate(exportBlDate)}</p>
          </ul>
        )
      }
      return tableData.push({
        id: id,
        key: id,
        licence_plate: (
          <a key={id} href={link}>
            <span className={`status-text`}>{car?.licence_plate}</span>
          </a>
        ),
        date: formatSQLDate(date),
        status: t('serviceActions.tableList.statuses.' + status),
        owner: carOwner?.company,
        branchOffice: branchOffice?.company,
        total: formatPrice(total),
        invoiceNumber: invoiceNumber,
        action: (
          <div className='table-actions'>
            <>
              <Button
                onClick={() => routeChange(item)}
                className='btn-icon'
                type='info'
                to='#'
                shape='circle'
              >
                <FeatherIcon icon='edit' size={16} />
              </Button>
              <Button
                onClick={() => deleteItem(item, 'delete')}
                className='btn-icon'
                type='info'
                to='#'
                shape='circle'
              >
                <FeatherIcon icon='trash-2' size={16} />
              </Button>
            </>
          </div>
        )
      })
    })

  const deleteItem = data => {
    const id = Number(data.id)
    const confirm = window.confirm(t('serviceActions.tableList.delete'))
    if (confirm) {
      const variables = {
        ...graphqlVariables,
        id: id
      }
      triggerDeleteItem({ variables }).then(({ data }) => {
        const response = data?.deleteServiceAction
        let type = 'success'
        const message = t('backend.notifications.update')
        let desc = t('backend.notifications.success.recordDelete')
        if (response.sucessful) {
          dispatch(deleteServiceAction(id))
        } else {
          type = 'error'
          desc = t('backend.notifications.error.recordDelete')
        }
        displayNotification(type, message, desc)
      })
    }
  }
  return (
    <Cards headless>
      <UserTableStyleWrapper>
        {/* {displayExportPdf && displayExportPdf === true && <Button>1111</Button>} */}
        <TableWrapper className='table-responsive'>
          <Table
            dataSource={tableData}
            columns={getTableColumns(t)}
            pagination={{
              defaultPageSize: 20,
              total: tableData.length,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} ${t('common.from')} ${total} ${t(
                  'common.items'
                )}`
            }}
            exportableProps={{
              fileName: 'zoznam-servisnych-zasahov',
              btnProps: {
                type: 'primary',
                children: <span>Export do CSV</span>
              }
            }}
          />
        </TableWrapper>
      </UserTableStyleWrapper>
    </Cards>
  )
}

const getTableColumns = t => {
  return [
    {
      title: t('serviceActions.tableList.licencePlate'),
      dataIndex: 'licence_plate',
      key: 'licence_plate'
    },
    {
      title: t('serviceActions.tableList.status'),
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: t('serviceActions.tableList.date'),
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: t('serviceActions.tableList.carOwner'),
      dataIndex: 'owner',
      key: 'owner'
    },
    {
      title: t('serviceActions.tableList.branchOffice'),
      dataIndex: 'branchOffice',
      key: 'branchOffice'
    },
    {
      title: t('serviceActions.tableList.total'),
      dataIndex: 'total',
      key: 'total'
    },
    {
      title: t('serviceActions.tableList.invoiceNumber'),
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber'
    },
    {
      title: t('common.actions'),
      dataIndex: 'action',
      key: 'action',
      width: '90px'
    }
  ]
}

export default List
