/**
 *
 * @param {*} email
 * @returns
 */
const requestNewUserPassword = async email => {
  const data = { email }
  const axios = require('axios')
  const url =
    process.env.REACT_APP_BACKEND_API_URL +
    '/user/password/update?apikey=' +
    process.env.REACT_APP_TOKEN_API
  var config = {
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  }

  return await axios(config)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
      return error
    })
}

export default requestNewUserPassword
