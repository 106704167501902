import React, { Suspense, lazy } from 'react'
import { Spin } from 'antd'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import Dashboard from './dashboard'
import Car from '../car'
import CarOwners from '../carOwners'
import CarRenters from '../carRenters'
import User from '../user'
import Calendars from '../../container/reservation/admin/calendar'
import Products from '../../container/service/products/grid'
import Actions from '../../container/service/actions/grid'
import ActionDetail from '../../container/service/action/detail'
import withAdminLayout from '../../layout/withAdminLayout'
import PrintInvoice from '../../container/service/action/Invoice/print'
import Warehouse from '../../container/service/warehouse/grid'
import NewWarehouseItem from '../../container/service/warehouse/newItem'
import EditWarehouseItem from '../../container/service/warehouse/editItem'
import NewItemFromServiceAction from '../../container/service/warehouse/newItemFromServiceAction'
import BranchOffices from './BranchOffices'

const Admin = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Suspense
        fallback={
          <div className='spin'>
            <Spin />
          </div>
        }
      >
        <Route path={path} component={Dashboard} />
        <Route path={`${path}/car`} component={Car} />
        <Route path={`${path}/car-owners`} component={CarOwners} />
        <Route path={`${path}/car-renters`} component={CarRenters} />
        <Route path={`${path}/reservation/week`} component={Calendars} />
        <Route path={`${path}/service/products`} component={Products} />
        <Route path={`${path}/service/action/:id`} component={ActionDetail} />
        <Route path={`${path}/service/print/:id`} component={PrintInvoice} />
        <Route path={`${path}/service/actions`} component={Actions} />
        <Route path={`${path}/offices`} component={BranchOffices} />
        <Route
          path={`${path}/service/warehouse-new`}
          component={NewWarehouseItem}
        />
        <Route
          path={`${path}/service/warehouse-new-service/:id/:carId/:mileage`}
          component={NewItemFromServiceAction}
        />
        <Route
          path={`${path}/service/warehouse-item/:id`}
          component={EditWarehouseItem}
        />
        <Route path={`${path}/service/warehouse`} component={Warehouse} />
        <Route path={`${path}/user`} component={User} />
      </Suspense>
    </Switch>
  )
}

export default withAdminLayout(Admin)
