import { useQuery } from '@apollo/client'
import { getItem } from '../../../utility/localStorageControl'

import {
  GET_ALL_CARS,
  GET_BRANCH_OFICCES,
  GET_SERVICE_ACTIONS
} from '../../../graphql/AdminQueries'

const GetGraphQlData = (graphqlVariables, loadData) => {
  const serviceActions = useQuery(GET_SERVICE_ACTIONS, {
    variables: graphqlVariables,
    skip: !loadData
  })

  const branchOfices = useQuery(GET_BRANCH_OFICCES, {
    variables: graphqlVariables,
    skip: !loadData
  })

  const user = getItem('user')
  if (user.role !== 1) {
    graphqlVariables.branchOffice = user?.branchOffice?.id
  }
  const cars = useQuery(GET_ALL_CARS, {
    variables: graphqlVariables,
    skip: !loadData
  })

  return [serviceActions, branchOfices, cars]
}

export { GetGraphQlData }
