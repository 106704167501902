import actions from './actions'

const {
  productsSetAll,
  filterSetTable,
  taxesSetAll,
  productUpdate,
  filterSetTableCarOwnerProducts,
  productsSetAllCarOwnerProducts
} = actions

const setAllProducts = data => {
  return async dispatch => {
    dispatch(productsSetAll(data))
  }
}
const setTableFiler = data => {
  return async dispatch => {
    dispatch(filterSetTable(data))
  }
}

const setTaxes = data => {
  return async dispatch => {
    dispatch(taxesSetAll(data))
  }
}

const updateProduct = data => {
  return async dispatch => {
    dispatch(productUpdate(data))
  }
}
const setCarOwnerAllProducts = data => {
  return async dispatch => {
    dispatch(productsSetAllCarOwnerProducts(data))
  }
}

const setTableFilterCarOwnerProducts = data => {
  return async dispatch => {
    dispatch(filterSetTableCarOwnerProducts(data))
  }
}
export {
  setAllProducts,
  setTableFiler,
  setTaxes,
  updateProduct,
  setCarOwnerAllProducts,
  setTableFilterCarOwnerProducts
}
