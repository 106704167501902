import React, { useState } from 'react'
import { Avatar } from 'antd'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import FeatherIcon from 'feather-icons-react'
import { UserOutlined } from '@ant-design/icons'
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style'
import Message from './message'
import Notification from './notification'
import Settings from './settings'
import Support from './support'
import { Popover } from '../../popup/popup'
import { Dropdown } from '../../dropdown/dropdown'
import { logOut } from '../../../redux/authentication/actionCreator'
import { getItem } from '../../../utility/localStorageControl'
import Heading from '../../heading/heading'
import Cookies from 'js-cookie'

const AuthInfo = () => {
  const [t, i18n] = useTranslation('common')

  const dispatch = useDispatch()
  const [state, setState] = useState({
    flag: 'slovak' // 'english'
  })
  const { flag } = state

  const SignOut = e => {
    e.preventDefault()
    dispatch(logOut())
  }

  const user = getItem('user')

  const userContent = (
    <UserDropDwon>
      <div className='user-dropdwon'>
        <figure className='user-dropdwon__info'>
          {/* <img
            src={require('../../../static/img/avatar/chat-auth.png')}
            alt=''
          /> */}
          <figcaption>
            <Heading as='h5'>
              {user?.firstname} {user?.lastname}
            </Heading>
          </figcaption>
        </figure>
        <Link className='user-dropdwon__bottomAction' onClick={SignOut} to='#'>
          <FeatherIcon icon='log-out' /> {t('topMenu.signOut')}
        </Link>
      </div>
    </UserDropDwon>
  )

  const onFlagChangeHandle = value => {
    setState({
      ...state,
      flag: value
    })
    const ln = getNewLang(value)
    i18n.changeLanguage(ln)
    Cookies.set('ln', ln)
  }

  const getNewLang = label => {
    switch (label) {
      case 'english':
        return 'en'
      case 'slovak':
        return 'sk'
      default:
        return 'sk'
    }
  }

  const country = (
    <NavAuth>
      <Link onClick={() => onFlagChangeHandle('slovak')} to='#'>
        <img src={require('../../../static/img/flag/slovak.png')} alt='' />
        <span>{t('topMenu.slovak')}</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('english')} to='#'>
        <img src={require('../../../static/img/flag/english.png')} alt='' />
        <span>{t('topMenu.english')}</span>
      </Link>
    </NavAuth>
  )

  return (
    <InfoWraper>
      {/* <Message />
      <Notification />

      <Settings />
      <Support /> */}
      <div className='nav-author'>
        <Dropdown placement='bottomRight' content={country} trigger='click'>
          <Link to='#' className='head-example'>
            <img src={require(`../../../static/img/flag/${flag}.png`)} alt='' />
          </Link>
        </Dropdown>
      </div>

      <div className='nav-author'>
        <Popover placement='bottomRight' content={userContent} action='click'>
          <Link to='#' className='head-example'>
            <UserOutlined
              style={{ fontSize: '20px', color: '#EE2723', paddingTop: '6px' }}
            />
            {/* <Avatar src='https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png' /> */}
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  )
}

export default AuthInfo
