import { Table } from 'antd'
import React from 'react'

const CarReservations = props => {
  const { t, carData } = props
  const { reservations } = carData
  let data = []
  const columns = getColumns(t)
  // zoradenie podla datumu
  const arrayForSort = [...reservations]
  const sortedAsc = arrayForSort.sort(
    (objA, objB) => Number(new Date(objA.date)) - Number(new Date(objB.date))
  )
  if (sortedAsc) {
    for (const reservation of sortedAsc) {
      data.push({
        date: new Date(reservation.date).toLocaleDateString('sk-SK'),
        time: reservation.from_time,
        note: reservation.note,
        key: reservation.id
      })
    }
    data.reverse()
  }
  return (
    <Table columns={columns} dataSource={data} key={record => record.uid} />
  )
}

const getColumns = t => {
  return [
    {
      title: t('car.tabs.tab2.grid.date'),
      dataIndex: 'date',
      key: 'date',
      width: '20%'
    },
    {
      title: t('car.tabs.tab2.grid.time'),
      dataIndex: 'time',
      key: 'time',
      width: '20%'
    },
    {
      title: t('car.tabs.tab2.grid.note'),
      dataIndex: 'note',
      key: 'note'
    }
  ]
}

export { CarReservations }
