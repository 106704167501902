import { gql } from '@apollo/client'

export const ADD_CAR = gql`
  mutation createNewCar(
    $licencePlate: String!
    $firstname: String!
    $lastname: String!
    $email: String
    $phone: String
    $branchOffice: Int!
    $carOwner: Int!
    $carRenter: Int!
    $note: String
    $type: String
    $sendSms: Boolean
    $tokenMutation: String!
  ) {
    createCar(
      licence_plate: $licencePlate
      active: true
      driver_firstname: $firstname
      driver_lastname: $lastname
      driver_email: $email
      driver_phone: $phone
      branchOffice: $branchOffice
      carOwner: $carOwner
      carRenter: $carRenter
      note: $note
      type: $type
      sendSms: $sendSms
      token: $tokenMutation
    ) {
      id
      licence_plate
      driver_firstname
      driver_lastname
      branchOffice {
        company
      }
      carRenter {
        company
      }
      carOwner {
        company
      }
    }
  }
`

export const UPDATE_CAR = gql`
  mutation updateCar(
    $licencePlate: String!
    $firstname: String!
    $lastname: String!
    $email: String
    $phone: String
    $branchOffice: Int!
    $id: ID!
    $carOwner: Int!
    $carRenter: Int!
    $status: Boolean!
    $sendSms: Boolean!
    $storage_status: Boolean!
    $note: String!
    $type: String!
    $tokenMutation: String!
  ) {
    updateCar(
      licence_plate: $licencePlate
      active: $status
      driver_firstname: $firstname
      driver_lastname: $lastname
      driver_email: $email
      driver_phone: $phone
      branchOffice: $branchOffice
      carOwner: $carOwner
      carRenter: $carRenter
      id: $id
      sendSms: $sendSms
      storage_status: $storage_status
      note: $note
      type: $type
      token: $tokenMutation
    ) {
      licence_plate
      active
      driver_firstname
      driver_lastname
      driver_email
      driver_phone
      branchOffice {
        id
      }
      carOwner {
        id
      }
      carRenter {
        id
      }
    }
  }
`

export const ARCHIVE_CAR = gql`
  mutation archiveCar($carId: ID!, $active: Boolean!, $tokenMutation: String!) {
    archiveCar(id: $carId, active: $active, token: $tokenMutation) {
      sucessful
      message
    }
  }
`

export const DELETE_RESERVATION = gql`
  mutation deleteReservationById($reservationId: ID!, $tokenMutation: String!) {
    deleteReservation(id: $reservationId, token: $tokenMutation) {
      sucessful
      message
    }
  }
`
export const UPDATE_RESERVATION_NOTE = gql`
  mutation updateReservationNote(
    $tokenMutation: String!
    $id: ID!
    $note: String!
  ) {
    updateReservationNote(note: $note, id: $id, token: $tokenMutation) {
      sucessful
      message
    }
  }
`

export const CREATE_RESERVATION = gql`
  mutation createNewReservation(
    $tokenMutation: String!
    $device: Int!
    $fromTime: String!
    $toTime: String!
    $status: String!
    $date: String!
    $car: Int!
    $pickup: Boolean!
    $note: String!
    $emailSendStatus: Boolean
  ) {
    createReservation(
      token: $tokenMutation
      from_time: $fromTime
      to_time: $toTime
      status: $status
      date: $date
      car: $car
      pickup: $pickup
      note: $note
      device: $device
      emailSendStatus: $emailSendStatus
    ) {
      id
      number
      from_time
      to_time
      status
      date
      pickup
      note
      car {
        id
        licence_plate
        driver_firstname
        driver_lastname
        driver_email
        driver_phone
        carOwner {
          id
          company
        }
        carRenter {
          id
          company
        }
        storage {
          id
          number
        }
        branchOffice {
          id
          lastname
          firstname
          company
        }
      }
      device {
        id
        branchOffice {
          id
          lastname
          firstname
        }
        first_reservation
        last_reservation
      }
    }
  }
`
export const CREATE_USER = gql`
  mutation createNewUser(
    $tokenMutation: String!
    $firstname: String!
    $lastname: String!
    $branchOffice: Int!
    $email: String!
    $password: String!
    $active: Boolean!
  ) {
    createUser(
      firstname: $firstname
      lastname: $lastname
      email: $email
      password: $password
      branchOffice: $branchOffice
      token: $tokenMutation
      active: $active
    ) {
      id
      firstname
      lastname
      email
      branchOffice {
        id
        company
      }
    }
  }
`
export const UPDATE_USER = gql`
  mutation updateUser(
    $tokenMutation: String!
    $id: ID!
    $firstname: String!
    $lastname: String!
    $branchOffice: Int!
    $email: String!
  ) {
    updateUser(
      email: $email
      id: $id
      firstname: $firstname
      lastname: $lastname
      branchOffice: $branchOffice
      token: $tokenMutation
    ) {
      sucessful
      message
    }
  }
`

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!, $tokenMutation: String!) {
    deleteUser(id: $id, token: $tokenMutation) {
      sucessful
      message
    }
  }
`
export const UPDATE_CAR_OWNER = gql`
  mutation updateCarOwner(
    $id: ID!
    $firstname: String
    $lastname: String
    $company: String
    $street: String
    $city: String
    $zip: String
    $phone: String
    $email: String
    $ic: String
    $dic: String
    $ic_dph: String
    $reservation_emails: String
    $invoice_maturity: Int
    $bonus: Int
    $invoice_emails: String
    $tokenMutation: String
  ) {
    updateCarOwner(
      id: $id
      firstname: $firstname
      lastname: $lastname
      company: $company
      street: $street
      city: $city
      zip: $zip
      phone: $phone
      email: $email
      ic: $ic
      dic: $dic
      ic_dph: $ic_dph
      reservation_emails: $reservation_emails
      invoice_emails: $invoice_emails
      invoice_maturity: $invoice_maturity
      bonus: $bonus
      token: $tokenMutation
    ) {
      id
      company
    }
  }
`
export const CREATE_CAR_OWNER = gql`
  mutation createCarOwner(
    $active: Boolean
    $firstname: String
    $lastname: String
    $company: String
    $street: String
    $city: String
    $zip: String
    $phone: String
    $email: String
    $ic: String
    $dic: String
    $ic_dph: String
    $reservation_emails: String
    $invoice_emails: String
    $invoice_maturity: Int
    $bonus: Int
    $tokenMutation: String
  ) {
    createCarOwner(
      active: $active
      firstname: $firstname
      lastname: $lastname
      company: $company
      street: $street
      city: $city
      zip: $zip
      phone: $phone
      email: $email
      ic: $ic
      dic: $dic
      ic_dph: $ic_dph
      reservation_emails: $reservation_emails
      invoice_emails: $invoice_emails
      invoice_maturity: $invoice_maturity
      bonus: $bonus
      token: $tokenMutation
    ) {
      id
      company
    }
  }
`

export const UPDATE_CAR_RENTER = gql`
  mutation updateCarRenter(
    $id: ID!
    $firstname: String
    $lastname: String
    $company: String
    $street: String
    $city: String
    $zip: String
    $phone: String
    $email: String
    $ic: String
    $dic: String
    $ic_dph: String
    $reservation_emails: String
    $tokenMutation: String
  ) {
    updateCarRenter(
      id: $id
      firstname: $firstname
      lastname: $lastname
      company: $company
      street: $street
      city: $city
      zip: $zip
      phone: $phone
      email: $email
      ic: $ic
      dic: $dic
      ic_dph: $ic_dph
      reservation_emails: $reservation_emails
      token: $tokenMutation
    ) {
      id
      company
    }
  }
`
export const CREATE_CAR_RENTER = gql`
  mutation createCarRenter(
    $active: Boolean
    $firstname: String
    $lastname: String
    $company: String
    $street: String
    $city: String
    $zip: String
    $phone: String
    $email: String
    $ic: String
    $dic: String
    $ic_dph: String
    $reservation_emails: String
    $tokenMutation: String
  ) {
    createCarRenter(
      active: $active
      firstname: $firstname
      lastname: $lastname
      company: $company
      street: $street
      city: $city
      zip: $zip
      phone: $phone
      email: $email
      ic: $ic
      dic: $dic
      ic_dph: $ic_dph
      reservation_emails: $reservation_emails
      token: $tokenMutation
    ) {
      id
      company
    }
  }
`
export const UPDATE_PRODUCT = gql`
  mutation updateProduct(
    $id: ID!
    $name: String!
    $price_purchase: Float
    $price_sale: Float!
    $tax: Float!
    $type: String!
    $code: String
    $tokenMutation: String!
  ) {
    updateProduct(
      id: $id
      name: $name
      price_purchase: $price_purchase
      price_sale: $price_sale
      tax: $tax
      type: $type
      code: $code
      token: $tokenMutation
    ) {
      sucessful
      message
    }
  }
`

export const CREATE_PRODUCT = gql`
  mutation createProduct(
    $name: String!
    $price_purchase: Float
    $price_sale: Float!
    $tax: Float!
    $qty: Int!
    $type: String!
    $code: String
    $tokenMutation: String!
  ) {
    createProduct(
      name: $name
      price_purchase: $price_purchase
      price_sale: $price_sale
      tax: $tax
      qty: $qty
      type: $type
      code: $code
      token: $tokenMutation
    ) {
      id
      name
    }
  }
`
export const CREATE_SERVICE_ACTTION = gql`
  mutation createServiceAction(
    $status: String!
    $mileage: Int
    $car: Int!
    $carOwner: Int!
    $date: String!
    $note: String
    $email_send: Int
    $total: Int
    $tokenMutation: String!
    $storaged: Int
  ) {
    createServiceAction(
      status: $status
      mileage: $mileage
      car: $car
      carOwner: $carOwner
      email_send: $email_send
      date: $date
      note: $note
      total: $total
      token: $tokenMutation
      storaged: $storaged
    ) {
      id
      storaged
      mileage
      car {
        id
      }
    }
  }
`
export const CREATE_STOCK_MOVE = gql`
  mutation createStockMove(
    $productId: ID!
    $qty: Int!
    $date: String!
    $type: String!
    $tokenMutation: String!
  ) {
    createStockMove(
      productId: $productId
      qty: $qty
      type: $type
      date: $date
      token: $tokenMutation
    ) {
      id
    }
  }
`
export const UPDATE_CUSTOM_PRICE = gql`
  mutation updateCustomPrice(
    $id: ID
    $price_sale: Float!
    $carOwner: Int!
    $product: Int!
    $tokenMutation: String!
  ) {
    updateCustomPrice(
      id: $id
      price_sale: $price_sale
      carOwner: $carOwner
      product: $product
      token: $tokenMutation
    ) {
      id
      price_sale
      carOwner {
        id
      }
    }
  }
`
export const DELETE_SERVICE_ACTION = gql`
  mutation deleteServiceAction($id: ID!, $tokenMutation: String!) {
    deleteServiceAction(id: $id, token: $tokenMutation) {
      sucessful
      message
    }
  }
`

export const UPDATE_SERVICE_ACTION = gql`
  mutation updateServiceAction(
    $id: ID!
    $status: String
    $mileage: Int
    $note: String
    $authorization_code: String
    $total: Int
    $tokenMutation: String!
    $invoiced: Boolean
    $invoiceSendStatus: Boolean
    $invoice_number: String
    $payment_type: String
    $invoice_year: String
    $invoice_issue_date: String
    $invoice_due_date: String
  ) {
    updateServiceAction(
      id: $id
      status: $status
      mileage: $mileage
      note: $note
      authorization_code: $authorization_code
      total: $total
      invoiced: $invoiced
      invoiceSendStatus: $invoiceSendStatus
      invoice_number: $invoice_number
      payment_type: $payment_type
      invoice_year: $invoice_year
      invoice_issue_date: $invoice_issue_date
      invoice_due_date: $invoice_due_date
      token: $tokenMutation
    ) {
      sucessful
      message
    }
  }
`

export const DELETE_SERVICE_ACTION_ITEM = gql`
  mutation deleteServiceActionItem(
    $id: ID!
    $serviceItemId: ID!
    $tokenMutation: String
  ) {
    deleteServiceActionItem(
      id: $id
      serviceItemId: $serviceItemId
      token: $tokenMutation
    ) {
      sucessful
      message
    }
  }
`
export const CREATE_SERVICE_ACTION_ITEM = gql`
  mutation createServiceActionItem(
    $product_name: String!
    $qty: Int!
    $price_purchase: Float!
    $price_sale: Float!
    $price_total: Float!
    $tax: Int!
    $price_sale_tax: Float!
    $price_total_tax: Float!
    $service: ID!
    $product: ID!
    $note: String
    $tokenMutation: String!
  ) {
    createServiceActionItem(
      product_name: $product_name
      qty: $qty
      price_purchase: $price_purchase
      price_sale: $price_sale
      price_total: $price_total
      tax: $tax
      price_sale_tax: $price_sale_tax
      price_total_tax: $price_total_tax
      service: $service
      product: $product
      note: $note
      token: $tokenMutation
    ) {
      id
    }
  }
`
export const CREATE_STORAGE_RECORD = gql`
  mutation createStorageRecord(
    $carId: ID!
    $reservationId: ID
    $number: String!
    $tyre_brand: String!
    $thread: String!
    $tokenMutation: String
    $date: String
    $note: String
    $disk_type: String
    $season: String
    $speedometer: Int
    $qty: Int
  ) {
    createStorageRecord(
      carId: $carId
      reservationId: $reservationId
      number: $number
      tyre_brand: $tyre_brand
      date: $date
      note: $note
      thread: $thread
      qty: $qty
      disk_type: $disk_type
      speedometer: $speedometer
      season: $season
      token: $tokenMutation
    ) {
      sucessful
      message
    }
  }
`
export const UPDATE_STORAGE_RECORD = gql`
  mutation updateStorageRecord(
    $id: ID!
    $carId: ID!
    $reservationId: ID
    $number: String!
    $tyre_brand: String!
    $thread: String!
    $tokenMutation: String
    $date: String
    $note: String
    $disk_type: String
    $season: String
    $speedometer: Int
    $qty: Int
  ) {
    updateStorageRecord(
      id: $id
      carId: $carId
      reservationId: $reservationId
      number: $number
      tyre_brand: $tyre_brand
      date: $date
      note: $note
      thread: $thread
      qty: $qty
      disk_type: $disk_type
      speedometer: $speedometer
      season: $season
      token: $tokenMutation
    ) {
      sucessful
      message
    }
  }
`
export const DELETE_STORAGE_RECORD = gql`
  mutation deleteStorageRecord($id: ID!, $tokenMutation: String) {
    deleteStorageRecord(id: $id, token: $tokenMutation) {
      sucessful
      message
    }
  }
`

export const UPDATE_BRANCH_OFFICE = gql`
  mutation updateBranchOffice(
    $id: ID!
    $firstname: String
    $lastname: String
    $company: String
    $street: String
    $city: String
    $zip: String
    $phone: String
    $email: String
    $ic: String
    $dic: String
    $ic_dph: String
    $send_reservation_email: Boolean
    $note: String
    $tokenMutation: String
  ) {
    updateBranchOffice(
      id: $id
      firstname: $firstname
      lastname: $lastname
      company: $company
      street: $street
      city: $city
      zip: $zip
      phone: $phone
      email: $email
      ic: $ic
      dic: $dic
      ic_dph: $ic_dph
      send_reservation_email: $send_reservation_email
      note: $note
      token: $tokenMutation
    ) {
      id
      company
    }
  }
`
export const CREATE_BRANCH_OFFICE = gql`
  mutation createBranchOffice(
    $firstname: String
    $lastname: String
    $company: String
    $street: String
    $city: String
    $zip: String
    $phone: String
    $email: String
    $ic: String
    $dic: String
    $ic_dph: String
    $send_reservation_email: Boolean
    $note: String
    $tokenMutation: String
  ) {
    createBranchOffice(
      firstname: $firstname
      lastname: $lastname
      company: $company
      street: $street
      city: $city
      zip: $zip
      phone: $phone
      email: $email
      ic: $ic
      dic: $dic
      ic_dph: $ic_dph
      send_reservation_email: $send_reservation_email
      note: $note
      token: $tokenMutation
    ) {
      id
      company
    }
  }
`
