import React, { useState, useEffect } from 'react'
import { Button, Card, Table, Drawer, Statistic } from 'antd'
import { UserTableStyleWrapper } from '../../../pages/style'
import { BasicFormWrapper, TableWrapper } from '../../../styled'
import FeatherIcon from 'feather-icons-react'
import { formatPrice, formatTax } from '../../../../utils/common'
import NewRecordModal from '../newRecordModal'
import { GetGraphQlData } from '../../products/dataQueries'
import { DELETE_SERVICE_ACTION_ITEM } from '../../../../graphql/AdminMutations'

import { useMutation } from '@apollo/client'
import NoteModal from '../noteModal'
import { ArrowUpOutlined } from '@ant-design/icons'

const List = props => {
  const {
    t,
    inputData,
    graphqlVariables,
    carOwnerId,
    carOwnerBonus,
    serviceActionId,
    data,
    statusChange
  } = props
  const [loadDataItem, setLoadDataItem] = useState(false)
  const [noteModalData, setNoteModalData] = useState()
  const [status, setStatus] = useState(data.status)
  useEffect(() => {
    setLoadDataItem(true)
  }, [])

  useEffect(() => {
    setStatus(statusChange)
  }, [statusChange])
  const graphQlData = GetGraphQlData(graphqlVariables, loadDataItem)
  const products = graphQlData[0]?.data?.getProducts

  // delete service action item
  const [triggerDeleteRecord] = useMutation(DELETE_SERVICE_ACTION_ITEM, {
    variables: graphqlVariables
  })

  const [gridState, setGridState] = useState({
    addNewRecord: false,
    showNoteModal: false
  })

  const onHandleDelete = item => {
    const confirm = window.confirm(t('serviceActions.tableList.delete'))
    if (confirm) {
      const variables = {
        ...graphqlVariables,
        serviceItemId: Number(item.id)
      }

      triggerDeleteRecord({ variables }).then(({ data }) => {
        const response = data?.deleteServiceActionItem
        if (response.sucessful) {
          window.location.reload(true)
        }
      })
    }
  }

  const showNewRecordModal = () => {
    setGridState({
      ...gridState,
      addNewRecord: true
    })
  }
  const onCancel = () => {
    setGridState({
      ...gridState,
      addNewRecord: false,
      showNoteModal: false
    })
  }

  const handleCancel = () => {
    onCancel()
  }

  const showNoteModal = item => {
    setNoteModalData(item.note)
    setGridState({
      ...gridState,
      showNoteModal: true
    })
  }

  const tableData = []
  let increment = 0
  let total_price_total_tax = 0
  let total_price_total = 0
  inputData &&
    inputData.map(item => {
      const {
        id,
        product_name,
        price_sale,
        qty,
        tax,
        price_total_tax,
        price_total,
        price_sale_tax,
        note
      } = item
      increment++
      total_price_total_tax += price_total_tax
      total_price_total += price_total
      return tableData.push({
        order: increment,
        key: id,
        name: (
          <div className=''>
            <>
              {product_name}
              {item.note && (
                <Button
                  onClick={() => showNoteModal(item)}
                  className='btn-icon note-info-icon'
                  type='info'
                  to='#'
                  shape='circle'
                >
                  <FeatherIcon icon='info' size={16} />
                </Button>
              )}
            </>
          </div>
        ),
        price_sale: formatPrice(price_sale),
        qty: qty,
        tax: formatTax(tax),
        price_total_tax: formatPrice(price_total_tax),
        price_total: formatPrice(price_total),
        price_sale_tax: formatPrice(price_sale_tax),
        action: status === 'open' && (
          <div className='table-actions'>
            <>
              <Button
                onClick={() => onHandleDelete(item)}
                className='btn-icon'
                type='info'
                to='#'
                shape='circle'
              >
                <FeatherIcon icon='trash-2' size={16} />
              </Button>
            </>
          </div>
        ),
        note: (
          <div className='table-note'>
            <>{note}</>
          </div>
        )
      })
    })
  return (
    <>
      <BasicFormWrapper className='mb-25'>
        <Card
          title={t('serviceActions.detail.itemsList')}
          extra={
            <>
              <Statistic
                title={t('products.tableList.totalExTax')}
                value={total_price_total}
                precision={2}
                valueStyle={{ color: '#20C997' }}
                suffix='€'
                className='statistics-summary'
              />
              <Statistic
                title={t('products.tableList.tax')}
                value={total_price_total_tax - total_price_total}
                precision={2}
                valueStyle={{ color: '#9299b8' }}
                suffix='€'
                className='statistics-summary'
              />
              <Statistic
                title={t('products.tableList.totalIncTax')}
                value={total_price_total_tax}
                precision={2}
                valueStyle={{ color: '#E4002C' }}
                suffix='€'
                className='statistics-summary'
              />
              {status === 'open' && (
                <Button
                  className='ant-btn ant-btn-primary'
                  onClick={showNewRecordModal}
                >
                  {t('common.addRecord')}
                </Button>
              )}
            </>
          }
        >
          <UserTableStyleWrapper>
            <TableWrapper className='table-responsive'>
              <Table
                dataSource={tableData}
                columns={getTableColumns(t, status)}
                pagination={{
                  defaultPageSize: 20,
                  total: tableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} ${t('common.from')} ${total} ${t(
                      'common.items'
                    )}`
                }}
              />
            </TableWrapper>
          </UserTableStyleWrapper>
        </Card>
      </BasicFormWrapper>
      <NewRecordModal
        t={t}
        gridState={gridState}
        handleCancel={handleCancel}
        onCancel={onCancel}
        products={products}
        carOwnerId={carOwnerId}
        carOwnerBonus={carOwnerBonus}
        serviceActionId={serviceActionId}
      />

      <NoteModal
        noteModalData={noteModalData}
        gridState={gridState}
        handleCancel={handleCancel}
        onCancel={onCancel}
        t={t}
      />
    </>
  )
}

const getTableColumns = (t, status) => {
  const actions =
    status === 'open'
      ? {
          title: t('common.actions'),
          dataIndex: 'action',
          key: 'action',
          width: '90px'
        }
      : {}
  return [
    {
      title: t('products.tableList.order'),
      dataIndex: 'order',
      key: 'order'
    },
    {
      title: t('products.tableList.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('products.tableList.price_sale'),
      dataIndex: 'price_sale',
      key: 'price_sale'
    },
    {
      title: t('products.tableList.qty'),
      dataIndex: 'qty',
      key: 'qty'
    },
    {
      title: t('products.tableList.taxPerc'),
      dataIndex: 'tax',
      key: 'tax'
    },
    {
      title: t('products.tableList.priceTotalWithoutTax'),
      dataIndex: 'price_total',
      key: 'price_total'
    },
    {
      title: t('products.tableList.tax'),
      dataIndex: 'price_sale_tax',
      key: 'price_sale_tax'
    },
    {
      title: t('products.tableList.priceTotalTax'),
      dataIndex: 'price_total_tax',
      key: 'price_total_tax'
    },
    actions
  ]
}

export default List
