import React, { Suspense } from 'react'
import { Spin } from 'antd'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import CarRentersGrid from '../../container/car_renters/grid'

const CarRentersRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Suspense
        fallback={
          <div className='spin'>
            <Spin />
          </div>
        }
      >
        <Route exact path={`${path}/`} component={CarRentersGrid} />
      </Suspense>
    </Switch>
  )
}

export default CarRentersRoutes
