import React from 'react'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'

const { Option } = Select

const SelectCar = props => {
  const { selectedBranchOffice, data, required, ...rest } = props
  const { t } = useTranslation('common')

  const options = []
  if (data !== undefined) {
    for (let item of data) {
      const name = item.licence_plate
      const id = item.id
      const branchOffice = item.branchOffice
      if (selectedBranchOffice) {
        if (Number(branchOffice?.id) === selectedBranchOffice) {
          const row = (
            <Option key={id} value={id} label={name}>
              {name}
            </Option>
          )
          options.push(row)
        }
      } else {
        const row = (
          <Option key={id} value={id} label={name}>
            {name}
          </Option>
        )
        options.push(row)
      }
    }
  }
  return (
    <Select
      {...rest}
      showSearch
      placeholder={t('car.select')}
      optionFilterProp='children'
      required={required}
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
    >
      {options}
    </Select>
  )
}

export { SelectCar }
