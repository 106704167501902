import React, { useState } from 'react'
import { Form, Input, Row, Col, DatePicker, InputNumber, Checkbox } from 'antd'
import moment from 'moment'
import { BasicFormWrapper } from '../../../styled'
import { Button } from '../../../../components/buttons/buttons'

import { SelectCar } from '../../../../components/customSelect/selectCar'
import { SelectCompanyForNewCar } from '../../../../components/customSelect/selectCompanyForNewCar'

const Tab1 = props => {
  const todayDate = {
    date: moment(new Date())
  }
  const {
    t,
    data,
    setState,
    formReadonly,
    form,
    handleEditOk,
    branchOffices,
    cars
  } = props
  const dateFormat = 'DD.MM.YYYY'
  const [dateState, setDateState] = useState({
    startDate: moment().format(dateFormat)
  })

  const onChangeStart = (date, dateString) => {
    setDateState({ ...dateState, startDate: dateString })
  }

  return (
    <>
      <BasicFormWrapper>
        <Form form={form} name='serviceActionEdit' onFinish={handleEditOk}>
          <Row gutter={15}>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                label={t('services.licencePlate')}
                name='car'
                rules={[{ required: true, message: 'Vyberte vozidlo!' }]}
              >
                <SelectCar
                  data={cars}
                  required={true}
                  selectedBranchOffice={null}
                />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                label={t('services.mileage')}
                name='mileage'
                rules={[{ required: true, message: 'Zadajte km!' }]}
              >
                <InputNumber status='warning' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                label={t('services.date')}
                name='date'
                rules={[{ required: true, message: 'Vyberte dátum!' }]}
              >
                <DatePicker onChange={onChangeStart} format={dateFormat} />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                label='Vytvoriť uskladnenku'
                name='storaged'
                valuePropName='checked'
              >
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col md={24} sm={24} xs={24}>
              <Form.Item label={t('services.note')} name='note'>
                <Input.TextArea placeholder={t('services.noteHint')} />
              </Form.Item>
            </Col>
          </Row>
          <>
            {!formReadonly && (
              <Row gutter={15}>
                <Col md={24} sm={24} xs={24} style={{ marginTop: '20px' }}>
                  <Button
                    htmlType='submit'
                    size='default'
                    type='primary'
                    key='submit'
                  >
                    {t('common.addRecord')}
                  </Button>
                </Col>
              </Row>
            )}
          </>
        </Form>
      </BasicFormWrapper>
    </>
  )
}

export { Tab1 }
