import React, { useState, useEffect } from 'react'
import { Row, Col, Table } from 'antd'
import { useSelector } from 'react-redux'

import FeatherIcon from 'feather-icons-react'
import {
  InvoiceHeader,
  InvoiceLetterBox,
  InvoiceAction,
  ProductTable,
  OrderSummary
} from './Style'
import { Main } from './styled'
import { Cards } from '../../../../components/cards/frame/cards-frame'

import Heading from '../../../../components/heading/heading'
import { Button } from '../../../../components/buttons/buttons'

import { formatSQLDate } from '../../../../utils/common'

const invoiceTableColumns = [
  {
    title: 'Označenie',
    dataIndex: 'details',
    key: 'details'
  },
  {
    title: 'Počet m.j.',
    dataIndex: 'quantity',
    key: 'quantity'
  },
  {
    title: 'Cena za m.j.',
    dataIndex: 'unit',
    key: 'unit'
  },
  {
    title: 'DPH %',
    dataIndex: 'vat_perc',
    key: 'vat_perc'
  },
  {
    title: 'Bez DPH',
    dataIndex: 'without_vat',
    key: 'without_vat'
  },
  {
    title: 'DPH',
    dataIndex: 'vat',
    key: 'vat'
  },
  {
    title: 'Spolu',
    dataIndex: 'total',
    key: 'total'
  }
]

const generateDocument = (serviceItems, service, invoiceId, type = 'print') => {
  const { rtl } = useSelector(state => {
    return {
      rtl: state.ChangeLayoutMode.rtlData
    }
  })

  const printInvoice = () => {
    window.print()
  }

  const backAction = () => {
    history.push('/admin/service/action/' + graphqlVariables.id)
  }

  let invoiceTableData = []

  let key = 0
  let tax0 = 0
  let tax10 = 0
  let tax20 = 0
  let tax0_base = 0
  let tax10_base = 0
  let tax20_base = 0
  let tax0_total = 0
  let tax10_total = 0
  let tax20_total = 0
  if (serviceItems) {
    for (const item of serviceItems) {
      const tax = item.tax
      const price_sale_tax = item.price_sale_tax
      const price_total = item.price_total
      const price_total_tax = item.price_total_tax
      switch (tax) {
        case 0:
          tax0 += price_sale_tax
          tax0_base += price_total
          tax0_total += price_total_tax
          break
        case 10:
          tax10 += price_sale_tax
          tax10_base += price_total
          tax10_total += price_total_tax
          break
        default:
          tax20 += price_sale_tax
          tax20_base += price_total
          tax20_total += price_total_tax
      }
      key++
      invoiceTableData.push({
        key: key,
        row: key,
        details: (
          <>
            <div className='product-info'>{item.product_name}</div>
          </>
        ),
        unit: (
          <span className='product-unit'>{item.price_sale.toFixed(2)}</span>
        ),
        quantity: <span className='product-quantity'>{item.qty}</span>,
        vat_perc: <span className='product-quantity'>{tax}</span>,
        without_vat: (
          <span className='product-quantity'>{price_total.toFixed(2)}</span>
        ),
        vat: (
          <span className='product-quantity'>{price_sale_tax.toFixed(2)}</span>
        ),
        total: (
          <span className='product-total-price'>
            {price_total_tax.toFixed(2)}
          </span>
        )
      })
    }
  }
  const total_total = (tax0_total + tax10_total + tax20_total).toFixed(2)
  const total_tax = (tax0 + tax10 + tax20).toFixed(2)
  const total_base = (tax0_base + tax10_base + tax20_base).toFixed(2)
  let issueDate
  let dueDate
  let customer
  const paymentType =
    service?.payment_type === 'bank' ? 'Bankový prevod' : 'Hotovosť'
  if (service) {
    issueDate = formatSQLDate(service.invoice_issue_date)
    dueDate = formatSQLDate(service.invoice_due_date)
    customer = service.carOwner
  }
  return (
    <Main>
      <Row gutter={15}>
        <Col md={24}>
          <Cards headless>
            <div className='invoice-content-wrap' id='print-invoice-content'>
              <InvoiceLetterBox>
                <div className='invoice-letter-inner'>
                  <article className='invoice-header'>
                    <Heading className='invoice-header__title_left' as='h5'>
                      Faktúra - daňový doklad
                    </Heading>
                    <div>
                      <strong>Číslo faktúry:</strong> {invoiceId} <br />
                      <strong>VS:</strong> {invoiceId} <br />
                      <strong>Dátum vystavenia:</strong> {issueDate}
                      <br />
                      <strong>Dátum splatnosti:</strong> {dueDate}
                      <br />
                      <strong>Dátum dodania:</strong> {issueDate}
                      <br />
                      <strong>Forma úhrady:</strong> {paymentType}
                    </div>
                  </article>
                  <address className='invoice-customer'>
                    <Heading className='invoice-customer__title' as='h5'>
                      Odberateľ:
                    </Heading>
                    <p>
                      {customer?.company}
                      <br />
                      {customer?.street}, {customer?.zip} {customer?.city}{' '}
                      <br />
                      IČO: {customer?.ic} <br />
                      DIČ: {customer?.dic} <br /> IČ DPH: {customer?.ic_dph}
                    </p>
                  </address>
                </div>
              </InvoiceLetterBox>
              <InvoiceHeader>
                <div className='invoice-letter-inner'>
                  <address className='invoice-supplier'>
                    <Heading className='invoice-supplier__title' as='h5'>
                      Dodávateľ:
                    </Heading>
                    <p>
                      <address className='invoice-info'>
                        PNEUSYSTEM spol. s r.o.
                        <br />
                        Galvaniho 16/A,82104 Bratislava <br />
                        IČO: 44342951 | DIČ: 2022684873 | IČ DPH: SK2022684873
                        <br />
                        Banka: ČSOB Banka
                        <br />
                        Č. účtu: SK02 7500 0000 0040 3102 4548
                        <br />
                        Tel.:+421 905 717 661
                        <br />
                        Spoločnosť je zapísaná v obchodnom registri Okresného
                        súdu Bratislava I, oddiel Sro, vložka číslo: 53987/B
                      </address>
                    </p>
                    <p>
                      <strong>
                        Fakturujeme Vám tovar podľa Vašej objednávky:
                      </strong>
                    </p>
                    <p>
                      <strong>EČV:</strong>
                      {service?.car?.licence_plate}, <strong>KM:</strong>
                      {service?.mileage}, <strong>Schvaľovací kód:</strong>
                      {service?.authorization_code}, <strong>Nájomca:</strong>
                      {service?.car?.carRenter?.company}
                    </p>
                  </address>
                </div>
              </InvoiceHeader>
              <br />
              <br />
              <ProductTable>
                <div className='table-invoice table-responsive'>
                  <Table
                    dataSource={invoiceTableData}
                    columns={invoiceTableColumns}
                    pagination={false}
                  />
                </div>
              </ProductTable>

              <Row justify='end'>
                <Col xxl={12} xl={12} sm={8} xs={14} offset={rtl ? 0 : 10}>
                  <OrderSummary>
                    <div className='invoice-summary-inner'>
                      <ul className='summary-list'>
                        <li className='header'>
                          <span className='summary-list-title'>Sadzba DPH</span>
                          <span className='summary-list-title'>Základ</span>
                          <span className='summary-list-title'>DPH</span>
                          <span className='summary-list-title'>Spolu</span>
                        </li>
                        {!!tax0 && (
                          <li>
                            <span className='summary-list-text'>0%</span>
                            <span className='summary-list-text'>
                              {tax0_base.toFixed(2)}
                            </span>
                            <span className='summary-list-text'>
                              {tax0.toFixed(2)}
                            </span>
                            <span className='summary-list-text'>
                              {tax0_total.toFixed(2)}
                            </span>
                          </li>
                        )}
                        {!!tax10 && (
                          <li>
                            <span className='summary-list-text'>10%</span>
                            <span className='summary-list-text'>
                              {tax10_base.toFixed(2)}
                            </span>
                            <span className='summary-list-text'>
                              {tax10.toFixed(2)}
                            </span>
                            <span className='summary-list-text'>
                              {tax10_total.toFixed(2)}
                            </span>
                          </li>
                        )}
                        {!!tax20 && (
                          <li>
                            <span className='summary-list-text'>20%</span>
                            <span className='summary-list-text'>
                              {tax20_base.toFixed(2)}
                            </span>
                            <span className='summary-list-text'>
                              {tax20.toFixed(2)}
                            </span>
                            <span className='summary-list-text'>
                              {tax20_total.toFixed(2)}
                            </span>
                          </li>
                        )}
                        <li>
                          <span className='summary-list-text'>SPOLU</span>
                          <span className='summary-list-text'>
                            {total_base}
                          </span>
                          <span className='summary-list-text'>{total_tax}</span>
                          <span className='summary-list-text'>
                            {total_total}
                          </span>
                        </li>
                      </ul>
                      <Heading className='summary-total' as='h3'>
                        <span className='summary-total-label'>
                          Spolu na úhradu :{' '}
                        </span>
                        <span className='summary-total-amount'>{`€${total_total}`}</span>
                      </Heading>
                    </div>
                  </OrderSummary>
                </Col>
                <Col xxl={12} xl={12} sm={8} xs={14} offset={rtl ? 0 : 10}>
                  <div className='invoice-barcode'>
                    <Cards headless>
                      <img
                        style={{ width: '50%' }}
                        src={require('../../../../static/img/peciatka.jpg')}
                        alt='barcode'
                      />
                    </Cards>
                  </div>
                </Col>
              </Row>
              {type === 'print' && (
                <Row justify='end'>
                  <Col lg={12} md={18} sm={24} offset={0}>
                    <InvoiceAction>
                      <Button
                        size='small'
                        shape='round'
                        type='default'
                        onClick={() => backAction()}
                      >
                        <FeatherIcon icon='skip-back' size={14} />
                        Naspäť
                      </Button>
                      <Button
                        size='small'
                        shape='round'
                        type='default'
                        onClick={() => printInvoice()}
                      >
                        <FeatherIcon icon='printer' size={14} />
                        Tlač / PDF
                      </Button>
                    </InvoiceAction>
                  </Col>
                </Row>
              )}
            </div>
          </Cards>
        </Col>
      </Row>
    </Main>
  )
}

export default generateDocument
