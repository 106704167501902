import React from 'react'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { formatPrice } from '../../utils/common'

const { Option } = Select

const SelectProduct = props => {
  const {
    selectedProduct,
    data,
    required,
    productsData,
    form,
    setItemStatus,
    ...rest
  } = props
  const { t } = useTranslation('common')

  // set data to the form based on selected product

  const handleChange = productId => {
    const qty = form.getFieldValue('qty') ? form.getFieldValue('qty') : 1
    productsData.map(item => {
      if (item.id === productId) {
        setItemStatus(false)
        form.setFieldsValue({
          priceSale: item.priceSale,
          bonusPrice: item.bonusPrice,
          total: formatPrice(item.bonusPrice * qty),
          productId: Number(productId),
          productName: item.name,
          qty: qty,
          pricePurchase: item.pricePurchase,
          tax: item.tax
        })
      }
    })
  }

  const options = []
  if (data !== undefined) {
    for (let item of data) {
      const name = item.name
      const id = item.id
      const product = item.id
      if (selectedProduct) {
        if (Number(product) === selectedProduct) {
          const row = (
            <Option key={id} value={id} label={name}>
              {name}
            </Option>
          )
          options.push(row)
        }
      } else {
        const row = (
          <Option key={id} value={id} label={name}>
            {name}
          </Option>
        )
        options.push(row)
      }
    }
  }
  return (
    <Select
      {...rest}
      showSearch
      placeholder={t('car.select')}
      optionFilterProp='children'
      onChange={handleChange}
      required={required}
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
    >
      {options}
    </Select>
  )
}

export { SelectProduct }
