import React, { useState, useEffect } from 'react'
import { Row, Col, Spin, Form, Input, Checkbox } from 'antd'

import { Link } from 'react-router-dom'
import UserListTable from '../user/overview/UsersTable'
import { PageHeader } from '../../components/page-headers/page-headers'
import { Main, CardToolbox, BasicFormWrapper } from '../styled'

import { AutoComplete } from '../../components/autoComplete/autoComplete'
import { Button } from '../../components/buttons/buttons'
import { AddUser } from '../pages/style'

import { useMutation } from '@apollo/client'
import { CREATE_USER } from '../../graphql/AdminMutations'

import { Modal } from '../../components/modals/antd-modals'
import { SelectCompany } from '../../components/customSelect/selectCompany'

import { useDispatch, useSelector } from 'react-redux'
import { setAllUsers } from '../../redux/users/actionCreator'
import { GetSelectData } from '../user/newUserDataQueries'

import { useTranslation } from 'react-i18next'
import requestNewUserPassword from '../../Api/user'

const graphqlVariables = {
  tokenGet: process.env.REACT_APP_TOKEN_GET,
  tokenMutation: process.env.REACT_APP_TOKEN_MUTATION
}

const UsersList = () => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const tableData = useSelector(state => state.cars?.cars)
  const allData = useSelector(state => state.cars?.allCars)

  const [createUserErrorMessage, setCreateUserErrorMessage] = useState('')
  const [state, setState] = useState({
    selectedRowKeys: 0,
    selectedRows: 0,
    addVisible: false
  })

  const [form] = Form.useForm()

  const [loadData, setLoadData] = useState(false)
  const [branchOficcesData, setBranchOficcesData] = useState([])
  const [usersData, setUsersData] = useState([])

  const [loadingUsers, setLoadingUsers] = useState(true)

  const graphQlData = GetSelectData(graphqlVariables, loadData)
  const branchOfices = graphQlData[0]?.data?.getBranchOficces
  const users = graphQlData[1]?.data?.getUserById
  if (users && branchOfices) {
    dispatch(setAllUsers(users))
    setLoadData(false)
    setBranchOficcesData(branchOfices)
    setUsersData(users)
    setLoadingUsers(graphQlData[0]?.loading)
  }
  useEffect(() => {
    setLoadData(true)
  }, [])

  // create new user
  const [triggerCreateUser] = useMutation(CREATE_USER, {
    variables: graphqlVariables
  })

  const handleSearch = searchText => {
    const data = allData.filter(item =>
      item.licence_plate.toUpperCase().startsWith(searchText.toUpperCase())
    )
    // dispatch(setTableCars(data))
  }

  const showModal = () => {
    setState({
      ...state,
      addVisible: true
    })
  }

  const onCancel = () => {
    setState({
      ...state,
      addVisible: false
    })
  }

  const handleCancel = () => {
    onCancel()
  }

  const handleOk = async values => {
    const variables = {
      ...graphqlVariables,
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      branchOffice: parseInt(values.branchOffice),
      active: true,
      password: 'pneusystem'
    }

    await triggerCreateUser({ variables })
      .then(async ({ data }) => {
        const newUser = data?.createUser
        const { email } = newUser
        const result = await requestNewUserPassword(email)
        onCancel()
        if (result?.code === 200) {
          document.location.href = '/admin/user'
        }
      })
      .catch(err => {
        if (err.message === 'userExist') {
          setCreateUserErrorMessage(t('backend.emailExist'))
        }
      })
  }

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('user.userList')}
          subTitle={
            <>
              {/* <span className="title-counter">{cars.length} Cars </span> */}
              <span className='title-counter'>
                {tableData.length} {t('user.users')}{' '}
              </span>
              <AutoComplete
                onSearch={handleSearch}
                // dataSource={notData}
                placeholder={t('common.find')}
                width='100%'
                patterns
              />
            </>
          }
          buttons={[
            <Button
              onClick={showModal}
              className='btn-add_new'
              size='default'
              type='primary'
              key='1'
            >
              {/* <Link to="/admin/users/add-user/info">+ Add New Car</Link> */}
              <Link to='#'>{t('common.addRecord')}</Link>
            </Button>
          ]}
        />
      </CardToolbox>

      <Main>
        <Row gutter={15}>
          <Col md={24}>
            {loadingUsers && (
              <div className='spin'>
                <Spin />
              </div>
            )}
            {!loadingUsers && (
              <UserListTable
                branchOficcesData={branchOficcesData}
                usersData={usersData}
              />
            )}
          </Col>
        </Row>
        <Modal
          type='primary'
          title={t('user.addNewUser')}
          visible={state.addVisible}
          footer={null}
          onCancel={handleCancel}
          width={600}
        >
          <div className='project-modal'>
            <AddUser>
              <BasicFormWrapper>
                <Form form={form} name='contact' onFinish={handleOk}>
                  <Row gutter={15}>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        name='branchOffice'
                        label={t('user.company')}
                        rules={[{ required: true }]}
                      >
                        <SelectCompany data={branchOficcesData} />
                      </Form.Item>
                      <Form.Item
                        label={t('user.firstname')}
                        name='firstname'
                        rules={[{ required: true }]}
                      >
                        <Input placeholder='' />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={t('user.email')}
                        name='email'
                        rules={[
                          {
                            type: 'email',
                            message: t('car.validation.wrongEmail')
                          },
                          {
                            required: true,
                            message: t('car.validation.email')
                          }
                        ]}
                      >
                        <Input placeholder='' />
                      </Form.Item>
                      <Form.Item
                        label={t('user.lastname')}
                        name='lastname'
                        rules={[{ required: true }]}
                      >
                        <Input placeholder='' />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={15}>
                    <Col md={24} sm={24} xs={24} style={{ marginTop: '20px' }}>
                      <Button
                        htmlType='submit'
                        size='default'
                        type='primary'
                        key='submit'
                      >
                        {t('car.addCar')}
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <p className='color-secondary'>{createUserErrorMessage}</p>
              </BasicFormWrapper>
            </AddUser>
          </div>
        </Modal>
      </Main>
    </>
  )
}

export default UsersList
