import actions from './actions'

const { storageSetAll, filterSetTable } = actions

const setAll = data => {
  return async dispatch => {
    dispatch(storageSetAll(data))
  }
}
const setTableFiler = data => {
  return async dispatch => {
    dispatch(filterSetTable(data))
  }
}

export { setAll, setTableFiler }
