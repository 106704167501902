import actions from './actions'

const { usersSetAll, usersUpdateUser, usersDeleteUser } = actions

const setAllUsers = data => {
  return async dispatch => {
    dispatch(usersSetAll(data))
  }
}

const updateUser = data => {
  return async dispatch => {
    dispatch(usersUpdateUser(data))
  }
}

const deleteUser = data => {
  return async dispatch => {
    dispatch(usersDeleteUser(data))
  }
}

export { setAllUsers, updateUser, deleteUser }
