import actions from './actions'

const { SERVICES_SET_ALL, FILTER_SET_TABLE, DELETE_SERVICE_ACTION } = actions

const initialState = {
  allServices: [],
  tableFilter: []
}

const ServicesReducer = (state = initialState, action) => {
  const { type, data } = action
  switch (type) {
    case SERVICES_SET_ALL:
      return {
        ...state,
        allServices: data,
        tableFilter: data
      }
    case FILTER_SET_TABLE:
      return {
        ...state,
        tableFilter: data
      }
    case DELETE_SERVICE_ACTION:
      const source = state?.allServices
      const updated = source.filter(item => Number(item.id) !== Number(data))

      return {
        ...state,
        allServices: updated,
        tableFilter: updated
      }
    default:
      return state
  }
}

export { ServicesReducer }
