import { gql } from '@apollo/client'

export const GET_CAR_BY_LICENSE_PLATE = gql`
  query getCarByLicencePlate($licencePlate: String!, $tokenGet: String!) {
    getCars(licence_plate: $licencePlate, token: $tokenGet) {
      id
      licence_plate
      driver_firstname
      driver_lastname
      driver_email
      driver_phone
      branchOffice {
        id
        firstname
        lastname
        company
        street
        city
        zip
        phone
        email
        ic
        dic
        ic_dph
      }
      carRenter {
        id
        firstname
        lastname
        company
        street
        city
        zip
        phone
        email
        ic
        dic
        ic_dph
      }
      carOwner {
        id
        firstname
        lastname
        company
        street
        city
        zip
        phone
        email
        ic
        dic
        ic_dph
        reservation_emails
      }
      storage_status
      storage {
        id
        number
      }
    }
  }
`

export const GET_FREE_TERMS = gql`
  query getFreeTerms($tokenGet: String!) {
    getCars(token: $tokenGet) {
      id
    }
  }
`

export const GET_FREE_TERMS_BY_DATE = gql`
  query getFreeTermsByDate(
    $tokenGet: String!
    $branchOfficeId: ID!
    $date: String!
    $timeSlotDuration: Int!
    $isStorage: Boolean!
  ) {
    getFreeTermsByDate(
      token: $tokenGet
      branchOfficeId: $branchOfficeId
      date: $date
      timeSlotDuration: $timeSlotDuration
      isStorage: $isStorage
    ) {
      title
      from_time
      to_time
      date
      device {
        id
      }
    }
  }
`

export const GET_FREE_TERMS_BY_DATE_RANGE = gql`
  query getFreeTermsByDateRange(
    $tokenGet: String!
    $branchOfficeId: ID!
    $date: String!
    $plusDays: Int!
    $timeSlotDuration: Int!
    $isStorage: Boolean!
  ) {
    getFreeTermsByDateRange(
      token: $tokenGet
      branchOfficeId: $branchOfficeId
      date: $date
      plusDays: $plusDays
      timeSlotDuration: $timeSlotDuration
      isStorage: $isStorage
    ) {
      title
      from_time
      to_time
      date
      device {
        id
      }
    }
  }
`

export const GET_DEVICES_BY_BRANCHOFFICE_ID = gql`
  query getDevicesByBranchOfficeId($tokenGet: String!, $branchOfficeId: ID!) {
    getDevicesByBranchOfficeId(
      token: $tokenGet
      branchOffice: $branchOfficeId
    ) {
      id
      first_reservation
      last_reservation
    }
  }
`

export const GET_DATES_WITHOUT_FREE_TERMS_BY_BRANCHOFFICE_ID = gql`
  query getDatesWithoutFreeTerms(
    $tokenGet: String!
    $branchOfficeId: ID!
    $date: String!
    $plusDays: Int!
    $timeSlotDuration: Int!
    $isStorage: Boolean!
  ) {
    getDatesWithoutFreeTerms(
      token: $tokenGet
      branchOfficeId: $branchOfficeId
      date: $date
      plusDays: $plusDays
      timeSlotDuration: $timeSlotDuration
      isStorage: $isStorage
    ) {
      date
    }
  }
`

export const GET_APP_SETTINGS = gql`
  query getConfiguration($tokenGet: String!, $code: String) {
    getConfiguration(token: $tokenGet, code: $code) {
      id
      code
      value
    }
  }
`
