import { useQuery } from '@apollo/client'

import { GET_PRODUCTS, GET_TAXES } from '../../../graphql/AdminQueries'

const GetGraphQlData = (graphqlVariables, loadData) => {
  const products = useQuery(GET_PRODUCTS, {
    variables: graphqlVariables,
    skip: !loadData
  })

  const taxes = useQuery(GET_TAXES, {
    variables: graphqlVariables,
    skip: !loadData
  })
  return [products, taxes]
}

export { GetGraphQlData }
