import React, { useState, useEffect } from 'react'
import { Modal } from '../../../components/modals/antd-modals'
import { CREATE_STOCK_MOVE } from '../../../graphql/AdminMutations'
import displayNotification from '../../reservation/admin/notification/notification'
import { useMutation } from '@apollo/client'

import { Form, Input, Row, Col, DatePicker } from 'antd'
import { BasicFormWrapper } from '../../styled'
import { Button } from '../../../components/buttons/buttons'

const NewIncomeModalForm = props => {
  const { t, showModal, setState, selectedItem } = props
  const windowWidth = window.innerWidth
  const [loadData, setLoadData] = useState(false)
  const graphqlVariables = {
    tokenMutation: process.env.REACT_APP_TOKEN_MUTATION
  }
  const [triggerCreateReccord] = useMutation(CREATE_STOCK_MOVE, {
    variables: graphqlVariables
  })

  const productId = Number(selectedItem.id)

  const onCancel = () => {
    setState({
      newIncomeModalState: false
    })
  }

  const handleCancel = () => {
    onCancel()
  }

  useEffect(() => {
    setLoadData(true)
  }, [])

  const handleEditOk = item => {
    onCancel()
    const variables = {
      qty: Number(item.qty),
      date: item.date.format('YYYY-MM-DD'),
      type: 'in',
      productId: productId
    }

    triggerCreateReccord({ variables }).then(({ data }) => {
      let type = 'success'
      const message = t('backend.notifications.create')
      let desc = t('backend.notifications.success.recordCreate')
      if (data.createStockMove !== undefined) {
        setTimeout(function () {
          window.location.reload()
        }, 1000)
      } else {
        type = 'error'
        desc = t('backend.notifications.error.recordCreate')
      }
      displayNotification(type, message, desc)
    })
  }
  const [form] = Form.useForm()

  return (
    <Modal
      type='primary'
      title={t('products.stockIncome')}
      visible={showModal}
      footer={null}
      onCancel={handleCancel}
      width={windowWidth * 0.8}
    >
      <div className='project-modal'>
        <BasicFormWrapper>
          <Form form={form} name='edit' onFinish={handleEditOk}>
            <Row gutter={15}>
              <Col md={4} sm={4} xs={24}>
                <Form.Item
                  label={t('products.tableList.qty')}
                  name='qty'
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={4} sm={4} xs={24}>
                <Form.Item
                  label={t('products.modal.tabs.tab2.grid.date')}
                  name='date'
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    format='DD.MM.YYYY'
                    placeholder={t('products.selectDate')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={15}>
              <Col md={24} sm={24} xs={24} style={{ marginTop: '20px' }}>
                <Button
                  htmlType='submit'
                  size='default'
                  type='primary'
                  key='submit'
                >
                  {t('common.addRecord')}
                </Button>
              </Col>
            </Row>
          </Form>
        </BasicFormWrapper>
      </div>
    </Modal>
  )
}

export default NewIncomeModalForm
