import React, { lazy, Suspense } from 'react'
import { Spin } from 'antd'
import { Switch, Route, Redirect } from 'react-router-dom'
import AuthLayout from '../container/profile/authentication/Index'

const Login = lazy(() =>
  import('../container/profile/authentication/overview/SignIn')
)
const ForgotPass = lazy(() =>
  import('../container/profile/authentication/overview/forgotPassword')
)

const NotFound = () => {
  return <Redirect to='/admin' />
}

const FrontendRoutes = () => {
  return (
    <Switch>
      <Suspense
        fallback={
          <div className='spin'>
            <Spin />
          </div>
        }
      >
        <Route exact path='/admin' component={Login} />
        <Route path='/admin/' component={NotFound} />
        <Route exact path='/admin/forgotPassword' component={ForgotPass} />
      </Suspense>
    </Switch>
  )
}

export default AuthLayout(FrontendRoutes)
