import React from 'react'

import { Row, Col, Card, Form, Input } from 'antd'
import { BasicFormWrapper } from '../../styled'

const InfoFormLeft = props => {
  const { t, data } = props
  return (
    <BasicFormWrapper className='mb-25'>
      <Card title={t('car.carInformation')}>
        <Form name='sDash_grid-form' layout='horizontal'>
          <Row gutter={30}>
            <Col md={4} xs={24} className='mb-25'>
              <Form.Item
                name='licence_plate'
                label={t('car.licencePlate')}
                className='small-input'
                initialValue={data?.car?.licence_plate}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={8} xs={24} className='mb-25'>
              <Form.Item
                name='owner'
                label={t('car.owner')}
                className='small-input'
                initialValue={data?.carOwner?.company}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={8} xs={24} className='mb-25'>
              <Form.Item
                name='renter'
                label={t('car.renter')}
                className='small-input'
                initialValue={data?.car?.carRenter?.company}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col md={12} xs={24} className='mb-25'>
              <Form.Item
                name='branchOffice'
                label={t('serviceActions.tableList.branchOffice')}
                className='small-input'
                initialValue={data?.branchOffice?.company}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={6} xs={24} className='mb-25'>
              <Form.Item
                name='driverFirstname'
                label={t('car.driverFirstname')}
                className='small-input'
                initialValue={
                  data?.car?.driver_firstname + ' ' + data?.car?.driver_lastname
                }
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={6} xs={24} className='mb-25'>
              <Form.Item
                name='driverPhone'
                label={t('car.driverPhone')}
                className='small-input'
                initialValue={data?.car?.driver_phone}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </BasicFormWrapper>
  )
}

export default InfoFormLeft
