import { Table } from 'antd'
import { array } from 'prop-types'
import React from 'react'

const CarServiceActions = props => {
  const { t, carData } = props
  const { services } = carData
  let data = []
  const columns = getColumns(t)
  if (services) {
    for (const item of services) {
      const key = item.id
      const link = '/admin/service/action/' + key
      data.push({
        date: (
          <a key={item.id} href={link}>
            {new Date(item.date).toLocaleDateString('sk-SK')}
          </a>
        ),
        key: (
          <a key={item.id} href={link}>
            {key}
          </a>
        ),
        status: (
          <a key={item.id} href={link}>
            {t('serviceActions.tableList.statuses.' + item.status)}
          </a>
        )
      })
    }
    data.reverse()
  }
  return (
    <Table columns={columns} dataSource={data} key={record => record.uid} />
  )
}

const getColumns = t => {
  const keys = ['key', 'date', 'status']
  let columns = []
  keys.forEach(item => {
    columns.push({
      title: t('car.tabs.tab5.grid.' + item),
      dataIndex: item,
      key: item
    })
  })
  return columns
}

export { CarServiceActions }
