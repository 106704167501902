import React from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from 'antd'
import { Modal } from '../../../components/modals/antd-modals'
import { Tab1 } from './tabs/tab1'
import { CREATE_PRODUCT } from '../../../graphql/AdminMutations'
import { setAllProducts } from '../../../redux/products/actionCreator'
import displayNotification from '../../reservation/admin/notification/notification'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { formatPrice } from '../../../utils/common'

const NewRecordModal = props => {
  const history = useHistory()
  const { t, gridState, handleCancel, onCancel, tableData, taxes } = props
  const windowWidth = window.innerWidth
  const dispatch = useDispatch()
  const graphqlVariables = {
    tokenMutation: process.env.REACT_APP_TOKEN_MUTATION
  }
  const [triggerCreateReccord] = useMutation(CREATE_PRODUCT, {
    variables: graphqlVariables
  })

  let taxesOptions = []
  for (const index in taxes) {
    taxesOptions.push({
      value: taxes[index].value,
      label: taxes[index].value
    })
  }

  const typeOptions = [
    {
      value: 'product',
      label: t('products.type.product')
    },
    {
      value: 'service',
      label: t('products.type.service')
    }
  ]

  const handleEditOk = newItem => {
    onCancel()

    let newData = [...tableData]
    newItem.id = tableData.length + 1
    newItem.qty = 0
    let newGridItem = { ...newItem }
    newGridItem.price_purchase = formatPrice(Number(newItem.price_purchase))
    newGridItem.price_sale = formatPrice(Number(newItem.price_sale))
    newData.push(newGridItem)
    const variables = {
      ...graphqlVariables,
      name: newItem.name,
      price_purchase: newItem.price_purchase
        ? Number(newItem.price_purchase)
        : 0,
      price_sale: Number(newItem.price_sale),
      tax: Number(newItem.tax),
      type: newItem.type,
      code: newItem.code,
      qty: newItem.qty
    }

    triggerCreateReccord({ variables }).then(({ data }) => {
      let type = 'success'
      const message = t('backend.notifications.create')
      let desc = t('backend.notifications.success.recordCreate')
      if (data.createProduct !== undefined) {
        dispatch(setAllProducts(newData))
      } else {
        type = 'error'
        desc = t('backend.notifications.error.recordCreate')
      }
      displayNotification(type, message, desc)
      setTimeout(function () {
        location.reload()
      }, 3000)
    })
  }

  const [form] = Form.useForm()

  return (
    <Modal
      type='primary'
      title={t('products.productInformation')}
      visible={gridState.addNewRecord}
      footer={null}
      onCancel={handleCancel}
      width={windowWidth * 0.8}
    >
      <div className='project-modal'>
        <Tab1
          t={t}
          data={'selectedItem'}
          formReadonly={false}
          form={form}
          handleEditOk={handleEditOk}
          taxes={taxes}
          type='new'
        />
      </div>
    </Modal>
  )
}

export default NewRecordModal
