const actions = {
  CARS_SET_ALL: 'CARS_SET_ALL',
  CARS_SET_TABLE: 'CARS_SET_TABLE',
  CARS_ADD: 'CARS_ADD',
  CARS_UPDATE: 'CARS_UPDATE',
  CARS_DELETE: 'CARS_DELETE',
  CARS_SET_NEW_CAR_DATA_SELECT: 'CARS_SET_NEW_CAR_DATA_SELECT',

  carsSetAll: data => {
    return {
      type: actions.CARS_SET_ALL,
      data
    }
  },
  carsSetNewCarDataSelect: data => {
    return {
      type: actions.CARS_SET_NEW_CAR_DATA_SELECT,
      data
    }
  },

  carsSetTable: data => {
    return {
      type: actions.CARS_SET_TABLE,
      data
    }
  },

  carsAddCar: data => {
    return {
      type: actions.CARS_ADD,
      data
    }
  },

  carsUpdateCar: (data, id) => {
    return {
      type: actions.CARS_UPDATE,
      data,
      id
    }
  },

  carsDeleteCar: id => {
    return {
      type: actions.CARS_DELETE,
      id
    }
  }
}

export default actions
