import Cookies from 'js-cookie'
import actions from './actions'

const {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr
} = actions

const login = () => {
  return async dispatch => {
    try {
      dispatch(loginBegin())
      setTimeout(() => {
        Cookies.set('logedIn', true)
        return dispatch(loginSuccess(true))
      }, 1000)
    } catch (err) {
      dispatch(loginErr(err))
    }
  }
}

const logOut = () => {
  return async dispatch => {
    try {
      dispatch(logoutBegin())
      Cookies.remove('logedIn')
      Cookies.remove('branchOfficeId')
      Cookies.remove('selectedCompany')
      Cookies.remove('userRole')
      dispatch(logoutSuccess(null))
    } catch (err) {
      dispatch(logoutErr(err))
    }
  }
}

export { login, logOut }
