/**
 *
 * @param {*} id
 * @param {*} target
 * @param {*} type
 * @returns
 */
const sendInvoiceEmail = async (id, target, type) => {
  var axios = require('axios')

  // const headers = { 'Content-Type': 'multipart/form-data' }
  const url =
    process.env.REACT_APP_BACKEND_API_URL +
    '/email/send-invoice-email?apikey=' +
    process.env.REACT_APP_TOKEN_API

  var config = {
    method: 'post',
    responseType: 'blob',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { id, target, type }
  }

  return await axios(config)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })

  // return await axios.post(url, formData, headers)
}

export default sendInvoiceEmail
