const actions = {
  BRANCH_OFFICCES_SET_ALL: 'BRANCH_OFFICCES_SET_ALL',
  FILTER_SET_TABLE: 'FILTER_SET_TABLE',
  BRANCH_OFFICCE_UPDATE: 'BRANCH_OFFICCE_UPDATE',
  BRANCH_OFFICCE_CREATE: 'BRANCH_OFFICCE_CREATE',
  FILTER_SET_TABLE_TAB2: 'FILTER_SET_TABLE_TAB2',

  branchOfficesSetAll: data => {
    return {
      type: actions.BRANCH_OFFICCES_SET_ALL,
      data
    }
  },
  filterSetTable: data => {
    return {
      type: actions.FILTER_SET_TABLE,
      data
    }
  },
  filterSetTableTab2: data => {
    return {
      type: actions.FILTER_SET_TABLE_TAB2,
      data
    }
  },
  branchOfficceUpdate: data => {
    return {
      type: actions.BRANCH_OFFICCE_UPDATE,
      data
    }
  },
  branchOfficceCreate: data => {
    return {
      type: actions.BRANCH_OFFICCE_UPDATE,
      data
    }
  }
}

export default actions
