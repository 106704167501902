const actions = {
  SERVICES_SET_ALL: 'SERVICES_SET_ALL',
  FILTER_SET_TABLE: 'FILTER_SET_TABLE',
  DELETE_SERVICE_ACTION: 'DELETE_SERVICE_ACTION',

  servicesSetAll: data => {
    return {
      type: actions.SERVICES_SET_ALL,
      data
    }
  },
  filterSetTable: data => {
    return {
      type: actions.FILTER_SET_TABLE,
      data
    }
  },
  serviceActionDelete: data => {
    return {
      type: actions.DELETE_SERVICE_ACTION,
      data
    }
  }
}

export default actions
