const actions = {
  PRODUCTS_SET_ALL: 'PRODUCTS_SET_ALL',
  FILTER_SET_TABLE: 'FILTER_SET_TABLE',
  FILTER_SET_TAXES: 'FILTER_SET_TAXES',
  PRODUCT_UPDATE: 'PRODUCT_UPDATE',
  PRODUCTS_SET_ALL_CAR_OWNER_PRODUCTS: 'PRODUCTS_SET_ALL_CAR_OWNER_PRODUCTS',
  FILTER_SET_TABLE_CAR_OWNER_PRODUCTS: 'FILTER_SET_TABLE_CAR_OWNER_PRODUCTS',

  productsSetAll: data => {
    return {
      type: actions.PRODUCTS_SET_ALL,
      data
    }
  },
  filterSetTable: data => {
    return {
      type: actions.FILTER_SET_TABLE,
      data
    }
  },
  taxesSetAll: data => {
    return {
      type: actions.FILTER_SET_TAXES,
      data
    }
  },
  productUpdate: data => {
    return {
      type: actions.PRODUCT_UPDATE,
      data
    }
  },
  productsSetAllCarOwnerProducts: data => {
    return {
      type: actions.PRODUCTS_SET_ALL_CAR_OWNER_PRODUCTS,
      data
    }
  },
  filterSetTableCarOwnerProducts: data => {
    return {
      type: actions.FILTER_SET_TABLE_CAR_OWNER_PRODUCTS,
      data
    }
  }
}

export default actions
