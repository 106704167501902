import actions from './actions'

const {
  CARS_SET_ALL,
  CARS_SET_TABLE,
  CARS_ADD,
  CARS_UPDATE,
  CARS_DELETE,
  CARS_SET_NEW_CAR_DATA_SELECT
} = actions

const initialState = {
  cars: [],
  allCars: []
}

const CarsReducer = (state = initialState, action) => {
  const { type, data, id } = action
  switch (type) {
    case CARS_SET_ALL:
      // console.log('Data all: ', data);
      return {
        ...state,
        allCars: data,
        cars: data
      }
    case CARS_SET_NEW_CAR_DATA_SELECT:
      return {
        ...state,
        newCarSelectData: data
      }
    case CARS_SET_TABLE:
      // console.log('Data table: ', data);
      return {
        ...state,
        cars: data
      }
    case CARS_ADD:
      const extendedCars = [...state?.cars, { ...data }]
      return {
        ...state,
        allCars: extendedCars,
        cars: extendedCars
      }
    case CARS_UPDATE:
      // data contains already updated car values
      return {
        ...state,
        allCars: data,
        cars: data
      }
    case CARS_DELETE:
      // find in cars array by ID and remove
      const allCars = state?.allCars
      const updatedCars = allCars.filter(car => car.id !== id)
      // console.log('After car REMOVE: ', updatedCars)
      return {
        ...state,
        allCars: updatedCars,
        cars: updatedCars
      }
    default:
      return state
  }
}

export { CarsReducer }
