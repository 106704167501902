import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Reservation = lazy(() => import('../../container/reservation'));
const History = lazy(() => import('../../container/reservation/History'));

const ReservationRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Reservation} />
      {/* <Route path={`${path}/social`} component={Reservation} /> */}
      {/* <Route exact path={`${path}/history`} component={History} /> */}
      <Route exact path={`/history`} component={History} />
    </Switch>
  );
};

export default ReservationRoutes;
