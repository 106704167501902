import React from 'react'
import { Menu } from 'antd'
import { NavLink, useRouteMatch } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'

const { SubMenu } = Menu

const AdminMenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { t } = useTranslation('common')
  const { path } = useRouteMatch()
  const userRole = Number(Cookies.get('userRole'))
  const pathName = window.location.pathname
  const pathArray = pathName.split(path)
  const mainPath = pathArray[1]
  const mainPathSplit = mainPath.split('/')
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`]
      : []
  )

  const onOpenChange = keys => {
    setOpenKeys(
      keys[keys.length - 1] !== 'recharts'
        ? [keys.length && keys[keys.length - 1]]
        : keys
    )
  }

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([])
  }

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1
                  ? 'home'
                  : mainPathSplit.length === 2
                  ? mainPathSplit[1]
                  : mainPathSplit[2]
              }`
            ]
          : []
      }
      defaultOpenKeys={
        !topMenu
          ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`]
          : []
      }
      overflowedIndicator={<FeatherIcon icon='more-vertical' />}
      openKeys={openKeys}
    >
      <Menu.Item key='home'>
        <NavLink onClick={toggleCollapsed} to={`${path}`}>
          <FeatherIcon size={16} icon='home' />{' '}
          <span>{t('backend.navigation.dashboard')}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key='car-overview'>
        <NavLink onClick={toggleCollapsed} to={`${path}/car`}>
          <FeatherIcon size={16} icon='truck' />{' '}
          <span>{t('backend.navigation.cars')}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key='car-owners-overview'>
        <NavLink onClick={toggleCollapsed} to={`${path}/car-owners`}>
          <FeatherIcon size={16} icon='user' />{' '}
          <span>{t('backend.navigation.carOwners')}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key='car-renters-overview'>
        <NavLink onClick={toggleCollapsed} to={`${path}/car-renters`}>
          <FeatherIcon size={16} icon='meh' />{' '}
          <span>{t('backend.navigation.carRenters')}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key='reservation'>
        <NavLink onClick={toggleCollapsed} to={`${path}/reservation/week`}>
          <FeatherIcon size={16} icon='calendar' />{' '}
          <span>{t('backend.navigation.reservations')}</span>
        </NavLink>
      </Menu.Item>
      <SubMenu
        key='service'
        icon={!topMenu && <FeatherIcon icon='layers' />}
        title={t('backend.navigation.service')}
      >
        <Menu.Item key='products'>
          <NavLink onClick={toggleCollapsed} to={`${path}/service/products`}>
            {t('backend.navigation.products')}
          </NavLink>
        </Menu.Item>
        <Menu.Item key='actions'>
          <NavLink onClick={toggleCollapsed} to={`${path}/service/actions`}>
            {t('backend.navigation.actions')}
          </NavLink>
        </Menu.Item>
        <Menu.Item key='warehouse'>
          <NavLink onClick={toggleCollapsed} to={`${path}/service/warehouse`}>
            {t('backend.navigation.warehouse')}
          </NavLink>
        </Menu.Item>
      </SubMenu>
      {userRole === 1 && (
        <>
          <Menu.Item key='users'>
            <NavLink onClick={toggleCollapsed} to={`${path}/user`}>
              {/* <NavLink onClick={toggleCollapsed} to={`/car`}> */}
              <FeatherIcon size={16} icon='users' />{' '}
              <span>{t('backend.navigation.users')}</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key='branch-offices'>
            <NavLink onClick={toggleCollapsed} to={`${path}/offices`}>
              <FeatherIcon size={16} icon='layers' /> <span>Pobočky</span>
            </NavLink>
          </Menu.Item>
        </>
      )}
    </Menu>
  )
}

AdminMenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func
}

export default AdminMenuItems
