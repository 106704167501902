import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import common_sk from './sk/common.json'
import common_en from './en/common.json'

// https://react.i18next.com/getting-started
export const i18nextConfig = i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'sk', // language to use [en]
  fallbackLng: 'sk', // en
  resources: {
    sk: {
      common: common_sk // 'common' is our custom namespace
    },
    en: {
      common: common_en
    }
  }
})
