import React from 'react'
import ReactDOM from 'react-dom'
import QRCode from 'react-qr-code'

const QRCodeImage = props => {
  const { carData } = props
  return <QRCode value={carData.licence_plate} />
}

export { QRCodeImage }
