import actions from './actions'

const { SET_ALL, FILTER_SET_TABLE } = actions

const initialState = {
  all: [],
  tableFilter: []
}

const StorageReducer = (state = initialState, action) => {
  const { type, data } = action
  switch (type) {
    case SET_ALL:
      return {
        ...state,
        all: data,
        tableFilter: data
      }
    case FILTER_SET_TABLE:
      return {
        ...state,
        tableFilter: data
      }
    default:
      return state
  }
}

export { StorageReducer }
