import React, { useState, useEffect } from 'react'
import { Tabs } from 'antd'

import { Modal } from '../../../../components/modals/antd-modals'
import { Tab1 } from './tab1'

import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'

import { UPDATE_PRODUCT } from '../../../../graphql/AdminMutations'
import { updateProduct } from '../../../../redux/products/actionCreator'
import displayNotification from '../../../reservation/admin/notification/notification'
import { Tab2 } from './tab2'

import { GetGraphQlData } from '../dataQueries'

const { TabPane } = Tabs

const ModalForm = props => {
  const {
    t,
    tableData,
    data,
    formReadonly,
    form,
    selectedItem,
    setState,
    showModal
  } = props

  const [loadData, setLoadData] = useState(false)
  const selectedItemId = selectedItem.id
  const dispatch = useDispatch()
  const windowWidth = window.innerWidth
  const { taxes } = data

  let num = 0
  const productStockMove = selectedItem.stockMove
  const graphqlVariables = {
    tokenMutation: process.env.REACT_APP_TOKEN_MUTATION,
    id: selectedItemId
  }

  const itemVariables = {
    tokenGet: process.env.REACT_APP_TOKEN_GET,
    carOwnerId: selectedItemId
  }

  useEffect(() => {
    setLoadData(true)
  }, [])
  const graphQlData = GetGraphQlData(itemVariables, loadData)
  const productsData = graphQlData[0]?.data?.allProducts

  const [triggerUpdate] = useMutation(UPDATE_PRODUCT, {
    variables: graphqlVariables
  })

  const onCancel = () => {
    setState({
      editVisible: false,
      update: {}
    })
  }

  const handleCancel = () => {
    onCancel()
  }

  const handleEditOk = data => {
    onCancel()

    const updatedData = tableData.map(item => {
      if (item.id === selectedItemId) {
        const updatedData = { ...item }
        updatedData.name = data.name
        updatedData.price_purchase = Number(data.price_purchase)
        updatedData.price_sale = Number(data.price_sale)
        updatedData.type = data.type
        updatedData.code = data.code
        updatedData.tax = Number(data.tax)
        updatedData.stockMove = item.stockMove
        return updatedData
      } else {
        item.tax = Number(item.tax)
        return item
      }
    })
    dispatch(updateProduct(updatedData))

    const variables = {
      ...graphqlVariables,
      name: data.name,
      price_purchase: Number(data.price_purchase),
      price_sale: Number(data.price_sale),
      type: data.type,
      code: data.code,
      tax: Number(data.tax)
    }

    triggerUpdate({ variables }).then(({ data }) => {
      let type = 'success'
      const message = t('backend.notifications.update')
      let desc = t('backend.notifications.success.recordUpdate')
      if (data.updateProduct !== undefined) {
        dispatch(updateProduct(updatedData))
      } else {
        type = 'error'
        desc = t('backend.notifications.error.recordUpdate')
      }

      displayNotification(type, message, desc)
    })
  }
  if (productStockMove) {
    num = productStockMove.length
  }
  return (
    <Modal
      type='primary'
      title={selectedItem.name}
      visible={showModal}
      footer={null}
      onCancel={handleCancel}
      width={windowWidth * 0.8}
    >
      <Tabs>
        <TabPane tab={t('products.modal.tabs.tab1.title')} key='tab1'>
          <Tab1
            t={t}
            data={selectedItem}
            setState={setState}
            formReadonly={formReadonly}
            form={form}
            handleEditOk={handleEditOk}
            taxes={taxes}
            type='update'
          />
        </TabPane>
        {formReadonly && (
          <TabPane
            tab={t('products.modal.tabs.tab2.title') + ' ( ' + num + ' )'}
            key='tab2'
          >
            <Tab2 t={t} selectedItem={productStockMove} data={productsData} />
          </TabPane>
        )}
      </Tabs>
    </Modal>
  )
}

export { ModalForm }
