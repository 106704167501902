import React, { Suspense } from 'react'
import { Spin } from 'antd'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import Reservation from './reservation'
import withCustomerLayout from '../../layout/withCustomerLayout'

const Customer = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Suspense
        fallback={
          <div className='spin'>
            <Spin />
          </div>
        }
      >
        <Route path={path} component={Reservation} />
      </Suspense>
    </Switch>
  )
}

export default withCustomerLayout(Customer)
