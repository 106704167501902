import actions from './actions'

const { servicesSetAll, filterSetTable, serviceActionDelete } = actions

const setAllServices = data => {
  return async dispatch => {
    dispatch(servicesSetAll(data))
  }
}
const setTableFiler = data => {
  return async dispatch => {
    dispatch(filterSetTable(data))
  }
}

const deleteServiceAction = id => {
  return async dispatch => {
    dispatch(serviceActionDelete(id))
  }
}

export { setAllServices, setTableFiler, deleteServiceAction }
