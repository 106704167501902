const actions = {
  RESERVATION_EXECUTE_GRAPHQL_QUERY: 'RESERVATION_EXECUTE_GRAPHQL_QUERY',
  RESERVATION_TRIGGER_GRAPHQL: 'RESERVATION_TRIGGER_GRAPHQL',
  RESERVATION_STOP_GRAPHQL: 'RESERVATION_STOP_GRAPHQL',
  RESERVATION_INCREASE_CLICK_COUNTER: 'RESERVATION_INCREASE_CLICK_COUNTER',
  RESERVATION_DECREASE_CLICK_COUNTER: 'RESERVATION_DECREASE_CLICK_COUNTER',
  RESERVATIONS_SET_ALL: 'RESERVATIONS_SET_ALL',
  RESERVATIONS_SET_TABLE: 'RESERVATIONS_SET_TABLE',
  DELETE_RESERVATION: 'DELETE_RESERVATION',
  CREATE_RESERVATION: 'CREATE_RESERVATION',

  reservationExecuteGraphqlQuery: data => {
    return {
      type: actions.RESERVATION_EXECUTE_GRAPHQL_QUERY,
      data
    }
  },

  reservationTriggerGraphql: () => {
    return {
      type: actions.RESERVATION_TRIGGER_GRAPHQL
    }
  },

  reservationStopGraphql: () => {
    return {
      type: actions.RESERVATION_STOP_GRAPHQL
    }
  },

  reservationIncreaseClickCounter: () => {
    return {
      type: actions.RESERVATION_INCREASE_CLICK_COUNTER
    }
  },

  reservationDecreaseClickCounter: () => {
    return {
      type: actions.RESERVATION_DECREASE_CLICK_COUNTER
    }
  },
  reservationsSetAll: data => {
    return {
      type: actions.RESERVATIONS_SET_ALL,
      data
    }
  },
  reservationsSetTable: data => {
    return {
      type: actions.RESERVATIONS_SET_TABLE,
      data
    }
  },
  reservationCreate: data => {
    return {
      type: actions.CREATE_RESERVATION,
      data
    }
  },
  reservationDelete: id => {
    return {
      type: actions.DELETE_RESERVATION,
      id
    }
  }
}

export default actions
