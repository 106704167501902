import { useQuery } from '@apollo/client'
import { GET_APP_SETTINGS } from '../graphql/CustomerQueries'

const graphqlVariables = {
  tokenGet: process.env.REACT_APP_TOKEN_GET,
  tokenMutation: process.env.REACT_APP_TOKEN_MUTATION
}

export const formatApiDate = dateObj => {
  let month = '' + (dateObj.getMonth() + 1),
    day = '' + dateObj.getDate(),
    year = dateObj.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

export const formatDate = dateObj => {
  let month = '' + (dateObj.getMonth() + 1),
    day = '' + dateObj.getDate(),
    year = dateObj.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [day, month, year].join('.')
}

export const formatSQLDate = date => {
  const dateObj = new Date(date)
  return dateObj.toLocaleDateString('sk-SK')
}

export const formatPrice = price => {
  let newPrice = 0
  if (price) {
    newPrice = price
  }
  return newPrice.toLocaleString('sk-SK', {
    style: 'currency',
    currency: 'EUR'
  })
}

export const formatTax = price => {
  return price + '%'
}

/**
 *
 * @param {*} code
 * @returns
 */
export const getApplicationSettings = (code, loadData) => {
  const { data: appConfiguration } = useQuery(GET_APP_SETTINGS, {
    variables: {
      tokenGet: graphqlVariables.tokenGet
    },
    skip: !loadData
  })
  let config
  if (appConfiguration) {
    const settingsResponse = appConfiguration?.getConfiguration
    config = settingsResponse.filter(item => item?.code === code)
    if (
      (config instanceof Array || config instanceof Object) &&
      config.length > 0
    ) {
      return config[0].value
    }
  }
  return false
}
