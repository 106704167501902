import actions from './actions'

const { USERS_SET_ALL, USERS_UPDATE, USERS_DELETE } = actions

const initialState = {
  allUsers: []
}

const UsersReducer = (state = initialState, action) => {
  const { type, data } = action
  switch (type) {
    case USERS_SET_ALL:
      return {
        ...state,
        allUsers: data
      }
    case USERS_UPDATE:
      return {
        ...state,
        allUsers: data
      }
    case USERS_DELETE:
      return {
        ...state,
        allUsers: data
      }
    default:
      return state
  }
}

export { UsersReducer }
