import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Row, Col, Spin, Checkbox } from 'antd'
import { PageHeader } from '../../../components/page-headers/page-headers'
import { Main, CardToolbox } from '../../styled'

import { Button } from '../../../components/buttons/buttons'
import { GetGraphQlData } from './dataQueries'

import { useTranslation } from 'react-i18next'
import { AutoComplete } from '../../../components/autoComplete/autoComplete'

import {
  setAllServices,
  setTableFiler
} from '../../../redux/services/actionCreator'

import List from './list'

import { useDispatch, useSelector } from 'react-redux'
import NewRecordModal from './newRecordModal'
import sendBusinessLeaseInvoices from '../../../Api/invoice'
import displayNotification from '../../reservation/admin/notification/notification'

const graphqlVariables = {
  tokenGet: process.env.REACT_APP_TOKEN_GET,
  tokenMutation: process.env.REACT_APP_TOKEN_MUTATION
}

const Actions = () => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()

  const tableData = useSelector(state => state?.services?.allServices)
  const listData = useSelector(state => state?.services?.tableFilter)

  const [loadData, setLoadData] = useState(false)
  const [loadingList, setLoadingList] = useState(true)

  const [redirectToDetail, setRedirectToDetail] = useState(false)

  const [gridState, setGridState] = useState({
    addNewRecord: false
  })

  const [branchOficcesData, setBranchOficcesData] = useState([])
  const [carsData, setCarsData] = useState([])
  const [value, setValue] = useState('')
  const [checkedStatus, setCheckedStatus] = useState()

  useEffect(() => {
    setLoadData(true)
  }, [])

  const graphQlData = GetGraphQlData(graphqlVariables, loadData)
  const servicesData = graphQlData[0]?.data?.getServiceActions
  const branchOffices = graphQlData[1]?.data?.getBranchOficces
  const cars = graphQlData[2]?.data?.getCars
  if (servicesData && branchOffices && cars) {
    const spinnerStatus = graphQlData?.loading
    setLoadData(false)
    dispatch(setAllServices(servicesData))
    setBranchOficcesData(branchOffices)
    setCarsData(cars)
    setLoadingList(spinnerStatus)
  }

  const handleSearch = searchText => {
    const data = tableData.filter(item => {
      const carOwner = item?.carOwner?.company.toUpperCase()
      const branchOffice = item?.branchOffice?.company.toUpperCase()
      const licencePlate = item?.car?.licence_plate
        ? item?.car?.licence_plate.toUpperCase()
        : ''
      const invoiceNumber = item?.invoice_number ? item?.invoice_number : ''
      return (
        licencePlate.includes(searchText.toUpperCase()) ||
        invoiceNumber.includes(searchText) ||
        branchOffice.includes(searchText.toUpperCase()) ||
        carOwner.includes(searchText.toUpperCase())
      )
    })

    dispatch(setTableFiler(data))
  }

  const showNewRecordModal = () => {
    setGridState({
      ...gridState,
      addNewRecord: true
    })
  }

  const exportBlAction = async () => {
    const confirm = window.confirm('Poslať hromadnú fakturáciu do BL?')
    if (confirm) {
      const result = await sendBusinessLeaseInvoices()
      let type = 'success'
      let reload = true
      const message = t('backend.notifications.update')
      let desc = 'E-mail bol odoslaný'
      if (result.data.message === 'no data') {
        desc = 'Žiadne faktúry na odoslanie'
        reload = false
      }
      if (result.status === false) {
        type = 'error'
        desc = 'E-mail nebol odoslaný, obráťte sa na podporu.'
        reload = false
      }

      displayNotification(type, message, desc)
      if (reload) {
        setTimeout(function () {
          window.location.reload()
        }, 1000)
      }
    }
  }
  const onCancel = () => {
    setGridState({
      ...gridState,
      addNewRecord: false
    })
  }
  const handleCancel = () => {
    onCancel()
  }

  const onChangeCheckbox = e => {
    let data = tableData
    setValue('')
    if (e.target.checked === true) {
      data = tableData.filter(item => {
        return item.status === e.target.name
      })
      setValue(e.target.value)
      setCheckedStatus(e.target.name)
    } else {
      setValue('')
      setCheckedStatus('')
    }
    dispatch(setTableFiler(data))
  }

  const serviceItems = [
    { value: 'Otvorené', label: 'open', name: 'open' },
    { value: 'Fakturované', label: 'invoiced', name: 'invoiced' },
    { value: 'Schválené', label: 'approved', name: 'approved' },
    { value: 'BL Export', label: 'blExported', name: 'blExported' },
    { value: 'Odoslané', label: 'sent', name: 'sent' }
  ]
  let buttons = [
    <div key='6' className='page-header-actions'>
      <Button
        onClick={exportBlAction}
        className='btn-add_new'
        size='default'
        type='primary'
        key='1'
      >
        <Link to='#'>BL Export</Link>
      </Button>
      <Button
        onClick={showNewRecordModal}
        className='btn-add_new'
        size='default'
        type='primary'
        key='1'
      >
        <Link to='#'>{t('common.addRecord')}</Link>
      </Button>
    </div>
  ]
  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('backend.actions.title')}
          subTitle={
            <>
              <span className='title-counter'>{t('carOwners.filter')} </span>
              <AutoComplete
                onSearch={handleSearch}
                // dataSource={notData}
                placeholder={t('carOwners.startWritting')}
                width='100%'
                patterns
              />

              {serviceItems.map(item => {
                return (
                  <div className='col-sm-12 px-3 py-2'>
                    <Checkbox
                      key={item.label}
                      onChange={onChangeCheckbox}
                      checked={item.value == value}
                      value={item.value}
                      name={item.name}
                    >
                      {item.value}
                    </Checkbox>
                  </div>
                )
              })}
            </>
          }
          buttons={buttons}
        />
      </CardToolbox>
      <Main>
        <Row gutter={15}>
          <Col md={24}>
            {loadingList && (
              <div className='spin'>
                <Spin />
              </div>
            )}
            {!loadingList && (
              <List
                inputData={listData}
                graphqlVariables={graphqlVariables}
                checkedStatus={checkedStatus}
              />
            )}
          </Col>
        </Row>
      </Main>
      <NewRecordModal
        t={t}
        gridState={gridState}
        handleCancel={handleCancel}
        onCancel={onCancel}
        tableData={tableData}
        cars={carsData}
        branchOffices={branchOficcesData}
        setRedirectToDetail={setRedirectToDetail}
      />
      {/* <NewRecordModal
        t={t}
        gridState={gridState}
        handleCancel={handleCancel}
        onCancel={onCancel}
        tableData={tableData}
        cars={carsData}
        branchOffices={branchOficcesData}
        setRedirectToDetail={setRedirectToDetail}
      /> */}
    </>
  )
}

export default Actions
