import actions from './actions'

const {
  BRANCH_OFFICCES_SET_ALL,
  FILTER_SET_TABLE,
  COMPANY_UPDATE,
  FILTER_SET_TABLE_TAB2
} = actions

const initialState = {
  allBranchOffices: [],
  tableFilter: [],
  tableFilterTab2: []
}

const BranchOfficesReducer = (state = initialState, action) => {
  const { type, data } = action
  switch (type) {
    case BRANCH_OFFICCES_SET_ALL:
      return {
        ...state,
        allBranchOffices: data,
        tableFilter: data
      }
    case FILTER_SET_TABLE:
      return {
        ...state,
        tableFilter: data
      }
    case FILTER_SET_TABLE_TAB2:
      return {
        ...state,
        tableFilterTab2: data
      }
    case COMPANY_UPDATE:
      return {
        ...state,
        tableFilter: data
      }
    default:
      return state
  }
}

export { BranchOfficesReducer }
