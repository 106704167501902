const actions = {
  SET_ALL: 'SET_ALL',
  FILTER_SET_TABLE: 'FILTER_SET_TABLE',

  storageSetAll: data => {
    return {
      type: actions.SET_ALL,
      data
    }
  },
  filterSetTable: data => {
    return {
      type: actions.FILTER_SET_TABLE,
      data
    }
  }
}

export default actions
