import { useQuery } from '@apollo/client'

import {
  GET_CAR_OWNERS,
  GET_OWNER_CARS,
  GET_PRODUCTS,
  GET_PRICELIST
} from '../../graphql/AdminQueries'

const GetGraphQlData = (graphqlVariables, loadData) => {
  const carOwners = useQuery(GET_CAR_OWNERS, {
    variables: graphqlVariables,
    skip: !loadData
  })
  const products = useQuery(GET_PRODUCTS, {
    variables: graphqlVariables,
    skip: !loadData
  })
  const pricelist = useQuery(GET_PRICELIST, {
    variables: graphqlVariables,
    skip: !loadData
  })
  return [carOwners, products, pricelist]
}

const GetOwnerCars = (graphqlVariables, loadData) => {
  const cars = useQuery(GET_OWNER_CARS, {
    variables: graphqlVariables,
    skip: !loadData
  })
  return [cars]
}

export { GetGraphQlData, GetOwnerCars }
