import React, { useState, useCallback } from 'react'
import { Table, Form } from 'antd'
import FeatherIcon from 'feather-icons-react'
import { UserTableStyleWrapper } from '../../pages/style'
import { TableWrapper } from '../../styled'

import { Button } from '../../../components/buttons/buttons'
import { Cards } from '../../../components/cards/frame/cards-frame'

import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { formatPrice } from '../../../utils/common'
import { ModalForm } from './tabs/modalForm'
import NewIncomeModalForm from './newIncomeModal'

const List = props => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const { inputData, graphqlVariables, taxes } = props

  const [form] = Form.useForm()

  const [state, setState] = useState({
    editVisible: false,
    update: {},
    newIncomeModalState: false
  })

  const [formReadonly, setFormReadonly] = useState(true)
  const [selectedItem, setSelectedItem] = useState({})
  const [newIncomeModal, setNewIncomeModal] = useState(false)

  const tableData = []
  const modalData = []

  inputData &&
    inputData.map(item => {
      const {
        id,
        name,
        price_purchase,
        price_sale,
        qty,
        type,
        code,
        tax,
        stockMove
      } = item
      return tableData.push({
        id: id,
        key: id,
        name: name,
        price_purchase: formatPrice(price_purchase),
        price_sale: formatPrice(price_sale),
        qty: qty,
        code: code,
        type: t('products.type.' + type),
        tax: tax + ' %',
        stockMove: stockMove,
        action: (
          <div className='table-actions'>
            <>
              {type === 'product' && (
                <Button
                  onClick={() => showNewIncomeModal(item)}
                  className='btn-icon'
                  type='info'
                  to='#'
                  shape='circle'
                >
                  <FeatherIcon icon='plus-circle' size={16} />
                </Button>
              )}
              <Button
                onClick={() => showEditModal(item)}
                className='btn-icon'
                type='info'
                to='#'
                shape='circle'
              >
                <FeatherIcon icon='eye' size={16} />
              </Button>
              <Button
                onClick={() => showEditModal(item, 'edit')}
                className='btn-icon'
                type='info'
                to='#'
                shape='circle'
              >
                <FeatherIcon icon='edit' size={16} />
              </Button>
            </>
          </div>
        )
      })
    })
  inputData &&
    inputData.map(item => {
      const {
        id,
        name,
        price_purchase,
        price_sale,
        qty,
        type,
        code,
        tax,
        stockMove
      } = item
      return modalData.push({
        id: id,
        key: id,
        name: name,
        price_purchase: price_purchase,
        price_sale: price_sale,
        qty: qty,
        type: type,
        code: code,
        tax: tax,
        stockMove: stockMove
      })
    })

  const showEditModal = (data, type = 'view') => {
    setEditFormFieldsValues(data)
    if (type === 'edit') {
      setFormReadonly(false)
    } else {
      setFormReadonly(true)
    }
    setState({
      ...state,
      editVisible: true,
      update: data
    })
    setSelectedItem(data)
  }
  const showNewIncomeModal = data => {
    setEditFormFieldsValues(data)
    setNewIncomeModal(true)
    setState({
      ...state,
      newIncomeModalState: true
    })
    setSelectedItem(data)
  }

  const setEditFormFieldsValues = data => {
    const fields = [
      'name',
      'price_purchase',
      'price_sale',
      'qty',
      'type',
      'code',
      'tax'
    ]
    for (const item of fields) {
      updateFormFieldValue(item, data[item])
    }
  }

  const updateFormFieldValue = useCallback(
    (fieldKey, fieldValue) => {
      form.setFieldsValue({ [fieldKey]: fieldValue })
    },
    [form]
  )

  return (
    <Cards headless>
      <UserTableStyleWrapper>
        <TableWrapper className='table-responsive'>
          <Table
            dataSource={tableData}
            columns={getTableColumns(t)}
            pagination={{
              defaultPageSize: 20,
              total: tableData.length,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} ${t('common.from')} ${total} ${t(
                  'common.items'
                )}`
            }}
          />
        </TableWrapper>
      </UserTableStyleWrapper>
      <ModalForm
        t={t}
        tableData={modalData}
        data={props}
        formReadonly={formReadonly}
        form={form}
        selectedItem={selectedItem}
        setState={setState}
        showModal={state.editVisible}
      />
      <NewIncomeModalForm
        t={t}
        data={props}
        formReadonly={formReadonly}
        form={form}
        selectedItem={selectedItem}
        setState={setState}
        showModal={state.newIncomeModalState}
      />
    </Cards>
  )
}

const getTableColumns = t => {
  return [
    {
      title: t('products.tableList.id'),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t('products.tableList.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('products.tableList.price_purchase'),
      dataIndex: 'price_purchase',
      key: 'price_purchase'
    },
    {
      title: t('products.tableList.price_sale'),
      dataIndex: 'price_sale',
      key: 'price_sale'
    },
    {
      title: t('products.tableList.tax'),
      dataIndex: 'tax',
      key: 'tax'
    },
    {
      title: t('products.tableList.qty'),
      dataIndex: 'qty',
      key: 'qty'
    },
    {
      title: t('products.tableList.type'),
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: t('common.actions'),
      dataIndex: 'action',
      key: 'action',
      width: '90px'
    }
  ]
}

export default List
