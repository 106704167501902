import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Row, Col, Card, Form, Input, Button, DatePicker, Spin } from 'antd'
import { BasicFormWrapper } from '../../styled'
import { formatSQLDate } from '../../../utils/common'

import { useHistory } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { UPDATE_SERVICE_ACTION } from '../../../graphql/AdminMutations'
import displayNotification from '../../reservation/admin/notification/notification'

import FeatherIcon from 'feather-icons-react'
import { SelectPaymentType } from '../../../components/customSelect/selectPaymentType'
import sendInvoiceEmail from './Invoice/email'

const InfoFormRight = props => {
  const dateFormat = 'DD.MM.YYYY'
  const history = useHistory()
  const { t, data, graphqlVariables, setStatusChange, invoiceData } = props
  const [code, setCode] = useState('')
  const [status, setStatus] = useState(data?.status)
  const [paymentType, setPaymentType] = useState('bank')
  const [statusName, setStatusName] = useState(
    t('serviceActions.tableList.statuses.' + data?.status)
  )
  const emailDisableMessage =
    'Nie je možné zaslať dokumenty, zákaník nemá vyplnenú emailovú adresu.'

  let canDisplayEmailButtons = false
  if (data?.carOwner?.invoice_emails) {
    canDisplayEmailButtons = true
  }

  const [canSetCode, setCanSetCode] = useState(status === 'open' ? true : false)
  const [canApprove, setCanApprove] = useState(false)
  const [issueDate, setIssueDate] = useState(moment().format('YYYY-MM-DD'))
  const [spinnerStatus, setSpinnerStatus] = useState(false)
  const [invoiceSendStatus, setInvoiceSendStatus] = useState(false)
  const [invoiceSendStatusEcon, setInvoiceSendStatusEcon] = useState(false)
  const [triggerUpdateItem] = useMutation(UPDATE_SERVICE_ACTION, {
    variables: graphqlVariables
  })

  useEffect(() => {
    if (data?.invoiceSendStatus) {
      setInvoiceSendStatus(true)
    }
    if (data?.invoiceSendStatusEcon) {
      setInvoiceSendStatusEcon(true)
    }
  }, [data?.invoiceSendStatus])

  const handleDownloadInvoice = async () => {
    setSpinnerStatus(true)
    const pdfFile = await sendInvoiceEmail(
      graphqlVariables.id,
      'internal',
      'download'
    )
    const file = new Blob([pdfFile], { type: 'application/pdf' })
    const fileURL = URL.createObjectURL(file)
    setSpinnerStatus(false)
    window.open(fileURL)
  }

  const handleSendInvoice = async type => {
    setSpinnerStatus(true)
    const confirm = window.confirm('Naozaj chcete odoslať faktúru emailom?')
    if (confirm) {
      const result = await sendInvoiceEmail(graphqlVariables.id, type, 'email')
      let messageType = 'success'
      const message = 'Email info'
      const toWho = type === 'internal' ? 'ekonómke' : 'zákazníkovi'
      let desc = `Faktúra bola odoslaná ${toWho}`
      if (!result) {
        messageType = 'error'
        desc = 'Chyba pri odosielaní faktúry'
      } else {
        if (type === 'customer') {
          let variables = { ...graphqlVariables }
          variables.invoiceSendStatus = true

          triggerUpdateItem({ variables }).then(({ data }) => {
            const response = data?.updateServiceAction
            if (response.sucessful) {
              setInvoiceSendStatus(true)
            }
          })
        }
      }
      setSpinnerStatus(false)
      displayNotification(messageType, message, desc)
    }
    setSpinnerStatus(false)
  }

  const handleIssueDateChange = date => {
    const value = moment(date).format('YYYY-MM-DD')
    setIssueDate(value)
  }
  const handleGenerateInvoice = () => {
    let variables = { ...graphqlVariables }
    variables.invoiced = true
    variables.invoice_issue_date = issueDate
    variables.payment_type = paymentType

    triggerUpdateItem({ variables }).then(({ data }) => {
      const response = data?.updateServiceAction
      let type = 'success'
      const message = t('backend.notifications.update')
      let desc = t('backend.notifications.success.recordUpdate')
      if (response.sucessful) {
        setStatus('invoiced')
      } else {
        type = 'error'
        desc = t('backend.notifications.error.recordUpdate')
      }
      displayNotification(type, message, desc)
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    })
  }
  const onFinish = values => {
    let variables = { ...graphqlVariables }
    if (values.mileage) variables.mileage = Number(values.mileage)
    if (values.note) variables.note = values.note
    if (values.authorization_code) {
      variables.authorization_code = values.authorization_code
      variables.status = 'approved'
    }
    triggerUpdateItem({ variables }).then(({ data }) => {
      const response = data?.updateServiceAction
      let type = 'success'
      const message = t('backend.notifications.update')
      let desc = t('backend.notifications.success.recordUpdate')
      if (response.sucessful) {
        // dispatch(deleteServiceAction(id))
        // setStatus('approved')
        // setStatusName('Schválený')
        // setStatusChange('approved')
        // setCanSetCode(false)
      } else {
        type = 'error'
        desc = t('backend.notifications.error.recordUpdate')
      }
      displayNotification(type, message, desc)
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    })
  }

  let formTitle = ''
  if (data?.invoice_number) {
    formTitle =
      t('serviceActions.detail.invoice.number') +
      ':' +
      data?.invoice_number +
      ' | ' +
      t('serviceActions.detail.invoice.dateOfIssue') +
      ':' +
      moment(data?.invoice_issue_date).format('DD.MM.YYYY') +
      ' | ' +
      t('serviceActions.detail.invoice.dueDate') +
      ':' +
      moment(data?.invoice_due_date).format('DD.MM.YYYY')
  }

  console.log('status::: ', status)
  console.log('invoiceSendStatus::: ', invoiceSendStatus)
  return (
    <>
      {spinnerStatus && (
        <div className='spin'>
          <Spin />
        </div>
      )}
      <BasicFormWrapper className='mb-25'>
        <Form name='sDash_grid-form' layout='horizontal' onFinish={onFinish}>
          <Card
            title={formTitle}
            extra={
              <>
                {status === 'open' && (
                  <>
                    {canApprove && (
                      <Button htmlType='submit' className='ant-btn btn-invoice'>
                        {t('serviceActions.detail.approve')}
                      </Button>
                    )}
                    <Button
                      htmlType='submit'
                      className='ant-btn ant-btn-primary'
                    >
                      {t('serviceActions.detail.updateInfo')}
                    </Button>
                  </>
                )}
                {status === 'approved' && (
                  <Button
                    className='ant-btn btn-invoice'
                    onClick={() => handleGenerateInvoice()}
                  >
                    {t('serviceActions.tableList.createInvoice')}
                  </Button>
                )}
                {status === 'invoiced' &&
                  canDisplayEmailButtons &&
                  !invoiceSendStatus && (
                    <>
                      <Button
                        className='ant-btn download-invoice'
                        onClick={() => handleSendInvoice('customer')}
                      >
                        <FeatherIcon icon='mail' size={14} />
                        &nbsp; Poslať Zákazníkovi
                      </Button>
                    </>
                  )}
                {(status === 'invoiced' || status === 'sent') &&
                  canDisplayEmailButtons && (
                    <>
                      <Button
                        className='ant-btn download-invoice'
                        onClick={() => handleSendInvoice('internal')}
                      >
                        <FeatherIcon icon='mail' size={14} />
                        &nbsp; Poslať Ekonómke
                      </Button>
                      &nbsp;
                      <Button
                        className='ant-btn download-invoice'
                        onClick={() => handleDownloadInvoice()}
                      >
                        <FeatherIcon icon='download' size={14} />
                        &nbsp;
                        {t('serviceActions.tableList.invoice')}
                      </Button>
                    </>
                  )}
                {status === 'invoiced' && !canDisplayEmailButtons && (
                  <div>{emailDisableMessage}</div>
                )}
              </>
            }
          >
            <Row gutter={30}>
              <Col md={4} xs={24} className='mb-25'>
                <Form.Item
                  name='mileage'
                  label={t('services.mileage')}
                  className='small-input'
                  initialValue={data?.mileage}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={4} xs={24} className='mb-25'>
                <Form.Item
                  name='status'
                  label={t('serviceActions.tableList.status')}
                  className='small-input'
                >
                  {statusName}
                </Form.Item>
              </Col>
              <Col md={5} xs={24} className='mb-25'>
                <Form.Item
                  name='date'
                  label={t('services.date')}
                  className='small-input'
                  initialValue={formatSQLDate(data?.date)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col md={5} xs={24} className='mb-25'>
                <Form.Item
                  name='authorization_code'
                  label={t('serviceActions.detail.authorizationCode')}
                  className='small-input'
                  initialValue={data?.authorization_code}
                >
                  <Input
                    disabled={!canSetCode}
                    onChange={e => {
                      setCode(e.target.value)
                      if (e.target.value) {
                        setCanApprove(true)
                      } else {
                        setCanApprove(false)
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              {status === 'approved' && (
                <>
                  <Col md={5} xs={24} className='mb-25'>
                    <Form.Item
                      name='invoice_issue_date'
                      id='invoice_issue_date'
                      label={t('services.invoiceIssueDate')}
                      className='small-input'
                      initialValue={moment(data?.date)}
                    >
                      <DatePicker
                        format={dateFormat}
                        onChange={date => handleIssueDateChange(date)}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={5} xs={24} className='mb-25'>
                    <Form.Item
                      name='payment_type'
                      id='payment_type'
                      label='Typ platby'
                      className='small-input'
                      initialValue={data?.payment_type}
                    >
                      <SelectPaymentType paymentType={setPaymentType} />
                    </Form.Item>
                  </Col>
                </>
              )}
              {status === 'invoiced' && (
                <>
                  <Col md={5} xs={24} className='mb-25'>
                    <Form.Item
                      name='invoice_issue_date'
                      label={t('services.invoiceIssueDate')}
                      className='small-input'
                      initialValue={formatSQLDate(data?.invoice_issue_date)}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col md={5} xs={24} className='mb-25'>
                    <Form.Item label='Typ platby' className='small-input'>
                      {data?.payment_type && data?.payment_type === 'bank'
                        ? 'Banka'
                        : 'Hotovosť'}
                    </Form.Item>
                  </Col>
                  <Col md={10} xs={24} className='mb-25'>
                    {invoiceSendStatus && (
                      <>
                        <div className='invoiceSendStatus'>
                          Faktúra bola zaslaná zákazníkovi
                        </div>
                      </>
                    )}
                    {invoiceSendStatusEcon && (
                      <>
                        <div className='invoiceSendStatus'>
                          Faktúra bola zaslaná ekonómke
                        </div>
                      </>
                    )}
                  </Col>
                </>
              )}
              {status === 'sent' && (
                <>
                  <Col md={5} xs={24} className='mb-25'>
                    <Form.Item
                      name='invoice_issue_date'
                      label={t('services.invoiceIssueDate')}
                      className='small-input'
                      initialValue={formatSQLDate(data?.invoice_issue_date)}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col md={5} xs={24} className='mb-25'>
                    <Form.Item label='Typ platby' className='small-input'>
                      {data?.payment_type && data?.payment_type === 'bank'
                        ? 'Banka'
                        : 'Hotovosť'}
                    </Form.Item>
                  </Col>
                  <Col md={10} xs={24} className='mb-25'>
                    {invoiceSendStatus && (
                      <>
                        <div className='invoiceSendStatus'>
                          Faktúra bola zaslaná zákazníkovi
                        </div>
                      </>
                    )}
                    {invoiceSendStatusEcon && (
                      <>
                        <div className='invoiceSendStatus'>
                          Faktúra bola zaslaná ekonómke
                        </div>
                      </>
                    )}
                  </Col>
                </>
              )}
            </Row>
            <Row gutter={30}>
              <Col md={24} xs={24} className='mb-25'>
                <Form.Item name='note' initialValue={data?.note}>
                  <Input.TextArea
                    placeholder={t('serviceActions.detail.invoice.noteHint')}
                    disabled={!canSetCode}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </BasicFormWrapper>
    </>
  )
}

export default InfoFormRight
