import React, { useState } from 'react'
import { Button, Col, Form, Input, InputNumber, Row } from 'antd'
import { CREATE_SERVICE_ACTION_ITEM } from '../../../graphql/AdminMutations'
import displayNotification from '../../reservation/admin/notification/notification'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { Modal } from '../../../components/modals/antd-modals'
import { BasicFormWrapper } from '../../styled'
import { SelectProduct } from '../../../components/customSelect/selectProduct'
import { formatPrice } from '../../../utils/common'

const NewRecordModal = props => {
  const {
    t,
    gridState,
    handleCancel,
    onCancel,
    products,
    carOwnerId,
    carOwnerBonus,
    serviceActionId
  } = props
  const windowWidth = window.innerWidth

  const [itemStatus, setItemStatus] = useState(true)

  const graphqlVariables = {
    tokenMutation: process.env.REACT_APP_TOKEN_MUTATION
  }
  const [triggerCreateItem] = useMutation(CREATE_SERVICE_ACTION_ITEM, {
    variables: graphqlVariables
  })
  let productsData = []
  products &&
    products.map(item => {
      const { id, name, price_sale, price_purchase, tax } = item
      const priceLists = item?.priceLists
      let customPrice
      if (priceLists) {
        priceLists.map(list => {
          if (list.carOwner.id === carOwnerId) {
            customPrice = list.price_sale
          }
        })
      }
      const priceSale = customPrice ? customPrice : price_sale
      let updatedBonus = 0
      let bonusPrice = 0
      if (priceSale) {
        bonusPrice = priceSale
      }
      if (carOwnerBonus) {
        updatedBonus = (100 - carOwnerBonus) / 100
        bonusPrice = priceSale / updatedBonus
      }
      return productsData.push({
        id: id,
        name: name,
        priceSale: priceSale,
        pricePurchase: price_purchase,
        tax: tax,
        bonusPrice: bonusPrice.toFixed(2)
      })
    })

  const handleChangeQty = value => {
    form.setFieldsValue({
      total: formatPrice(form.getFieldValue('bonusPrice') * value)
    })
  }

  const handleChangePriceSale = value => {
    const updatedBonus = (100 - carOwnerBonus) / 100
    const newPrice = value / updatedBonus
    form.setFieldsValue({
      total: formatPrice(form.getFieldValue('qty') * newPrice),
      bonusPrice: newPrice.toFixed(2)
    })
  }

  const onFinish = values => {
    onCancel()
    let variables = { ...graphqlVariables }
    const total = values.bonusPrice * values.qty
    const taxPerc = values.tax
    const taxValue = (total / 100) * taxPerc
    variables.product_name = values.productName
    variables.qty = values.qty
    variables.price_purchase = Number(values.pricePurchase)
    variables.price_sale = Number(values.bonusPrice)
    variables.price_total = Number(total)
    variables.tax = Number(taxPerc)
    variables.price_sale_tax = Number(taxValue)
    variables.price_total_tax = Number(total + taxValue)
    variables.service = Number(serviceActionId)
    variables.product = values.productId
    variables.note = values.note ? values.note : ''
    variables.tokenMutation = graphqlVariables.tokenMutation

    triggerCreateItem({ variables }).then(({ data }) => {
      const response = data?.createServiceActionItem
      let type = 'success'
      const message = t('backend.notifications.update')
      let desc = t('backend.notifications.success.recordUpdate')
      if (response.id) {
        window.location.reload(true)
      } else {
        type = 'error'
        desc = t('backend.notifications.error.recordUpdate')
      }
      displayNotification(type, message, desc)
    })
  }
  const [form] = Form.useForm()
  return (
    <Modal
      type='primary'
      title={t('backend.notifications.create')}
      visible={gridState.addNewRecord}
      footer={null}
      onCancel={handleCancel}
      width={windowWidth * 0.5}
    >
      <div className='project-modal'>
        <BasicFormWrapper className='mb-25'>
          <Form
            form={form}
            name='sDash_grid-form'
            layout='horizontal'
            onFinish={onFinish}
          >
            <Row gutter={30}>
              <Col md={24} xs={24} className='mb-25'>
                <Form.Item
                  name='product'
                  label={t('serviceActions.detail.product')}
                  className='small-input'
                >
                  <SelectProduct
                    data={productsData}
                    productsData={productsData}
                    form={form}
                    setItemStatus={setItemStatus}
                  />
                </Form.Item>
                <Form.Item name='productId' noStyle>
                  <Input hidden />
                </Form.Item>
                <Form.Item name='productName' noStyle>
                  <Input hidden />
                </Form.Item>
                <Form.Item name='pricePurchase' noStyle>
                  <Input hidden />
                </Form.Item>
                <Form.Item name='tax' noStyle>
                  <Input hidden />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col md={6} xs={24} className='mb-25'>
                <Form.Item
                  name='priceSale'
                  label={t('products.tableList.price_sale')}
                  className='small-input'
                  initialvalue={0}
                >
                  <InputNumber
                    disabled={itemStatus}
                    onChange={handleChangePriceSale}
                  />
                </Form.Item>
              </Col>
              <Col md={6} xs={24} className='mb-25'>
                <Form.Item
                  name='bonusPrice'
                  label={
                    '+ ' +
                    t('products.tableList.bonus') +
                    ' ' +
                    carOwnerBonus +
                    '%'
                  }
                  className='small-input'
                >
                  <InputNumber disabled={true} />
                </Form.Item>
              </Col>
              <Col md={4} xs={24} className='mb-25'>
                <Form.Item
                  name='qty'
                  label={t('products.tableList.qty')}
                  className='small-input'
                >
                  <InputNumber
                    min={1}
                    initialvalue={1}
                    onChange={handleChangeQty}
                    disabled={itemStatus}
                  />
                </Form.Item>
              </Col>
              <Col md={6} xs={24} className='mb-25'>
                <Form.Item
                  name='total'
                  label={t('products.tableList.priceTotalWithoutTax')}
                  className='small-input'
                  initialvalue={formatPrice(0)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col md={24} xs={24} className='mb-25'>
                <Form.Item
                  name='note'
                  label={t('products.tableList.note')}
                  className='small-input'
                >
                  <Input.TextArea
                    placeholder={t('services.noteHint')}
                    disabled={itemStatus}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={24} sm={24} xs={24} style={{ marginTop: '20px' }}>
                <Button
                  htmlType='submit'
                  size='default'
                  type='primary'
                  key='submit'
                  disabled={itemStatus}
                >
                  {t('common.addRecord')}
                </Button>
              </Col>
            </Row>
          </Form>
        </BasicFormWrapper>
      </div>
    </Modal>
  )
}

export default NewRecordModal
