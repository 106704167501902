import { useQuery } from '@apollo/client'

import { GET_BRANCH_OFICCES, GET_ALL_USERS } from '../../graphql/AdminQueries'

const GetSelectData = (graphqlVariables, loadData) => {
  const branchOfices = useQuery(GET_BRANCH_OFICCES, {
    variables: graphqlVariables,
    skip: !loadData
  })
  const users = useQuery(GET_ALL_USERS, {
    variables: graphqlVariables,
    skip: !loadData
  })
  return [branchOfices, users]
}

export { GetSelectData }
