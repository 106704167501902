import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { GetGraphQlData } from '../../actions/dataQueries'

import generateDocument from './document'

const graphqlVariables = {
  tokenGet: process.env.REACT_APP_TOKEN_GET,
  tokenMutation: process.env.REACT_APP_TOKEN_MUTATION
}

const PrintInvoice = () => {
  const history = useHistory()
  const params = useParams()
  const id = params.id
  const invoiceId = id.toString().padStart(7, '0')
  graphqlVariables.id = id

  const [loadData, setLoadData] = useState(false)
  const [serviceItems, setServiceItems] = useState()
  const [service, setService] = useState()

  useEffect(() => {
    setLoadData(true)
  }, [])
  const graphQlData = GetGraphQlData(graphqlVariables, loadData)
  const servicesData = graphQlData[0]?.data?.getServiceActions
  if (servicesData !== undefined) {
    setLoadData(false)
    setService(servicesData[0])
    setServiceItems(servicesData[0]?.items)
  }
  return generateDocument(serviceItems, service, invoiceId, 'print')
}

export default PrintInvoice
