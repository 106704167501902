import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
import { Row, Col, Spin, Form } from 'antd'

import { Main, CardToolbox } from '../styled'
import { PageHeader } from '../../components/page-headers/page-headers'
import { Button } from '../../components/buttons/buttons'

import { AutoComplete } from '../../components/autoComplete/autoComplete'
import { useTranslation } from 'react-i18next'

import { GetGraphQlData } from './dataQueries'
import {
  setAllCarOwners,
  setTableFiler
} from '../../redux/car_owners/actionCreator'

import List from './overview/list'
import { useDispatch, useSelector } from 'react-redux'
import NewRecordModal from './newRecordModal'
import { setAllProducts } from '../../redux/products/actionCreator'

const graphqlVariables = {
  tokenGet: process.env.REACT_APP_TOKEN_GET,
  tokenMutation: process.env.REACT_APP_TOKEN_MUTATION
}

const CarOwnersGrid = () => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()

  const [gridState, setGridState] = useState({
    addNewRecord: false
  })
  const showNewRecordModal = () => {
    setGridState({
      ...gridState,
      addNewRecord: true
    })
  }
  const onCancel = () => {
    setGridState({
      ...gridState,
      addNewRecord: false
    })
  }

  const handleCancel = () => {
    onCancel()
  }
  const tableData = useSelector(state => state?.carOwners?.allCarOwners)
  const listData = useSelector(state => state?.carOwners?.tableFilter)
  const productsData = useSelector(state => state?.products?.allProducts)

  const [loadData, setLoadData] = useState(false)
  const [loadingList, setLoadingList] = useState(true)

  useEffect(() => {
    setLoadData(true)
  }, [])

  const graphQlData = GetGraphQlData(graphqlVariables, loadData)
  const data = graphQlData[0]?.data?.getCarOwners
  const products = graphQlData[1]?.data?.getProducts
  const pricelist = graphQlData[2]?.data?.getPricelist
  if (data && products && pricelist) {
    const spinnerStatus = graphQlData[0]?.loading
    dispatch(setAllCarOwners(data))
    dispatch(setAllProducts(products))
    setLoadingList(spinnerStatus)
    setLoadData(false)
  }

  const handleSearch = searchText => {
    // ked budu data v poriadku treba to odkomentovat
    // const data = tableData.filter(
    //   item =>
    //     item?.company.toUpperCase().includes(searchText.toUpperCase()) ||
    //     item?.city.toUpperCase().includes(searchText.toUpperCase()) ||
    //     item?.firstname.toUpperCase().includes(searchText.toUpperCase()) ||
    //     item?.lastname.toUpperCase().includes(searchText.toUpperCase()) ||
    //     item?.email.toUpperCase().includes(searchText.toUpperCase())
    // )

    const data = tableData.filter(item =>
      item?.company.toUpperCase().includes(searchText.toUpperCase())
    )
    dispatch(setTableFiler(data))
  }

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('carOwners.list')}
          subTitle={
            <>
              <span className='title-counter'>{t('carOwners.filter')} </span>
              <AutoComplete
                onSearch={handleSearch}
                // dataSource={notData}
                placeholder={t('carOwners.startWritting')}
                width='100%'
                patterns
              />
            </>
          }
          buttons={[
            <Button
              onClick={showNewRecordModal}
              className='btn-add_new'
              size='default'
              type='primary'
              key='1'
            >
              {/* <Link to="/admin/users/add-user/info">+ Add New Car</Link> */}
              <Link to='#'>{t('common.addRecord')}</Link>
            </Button>
          ]}
        />
      </CardToolbox>
      <Main>
        <Row gutter={15}>
          <Col md={24}>
            {loadingList && (
              <div className='spin'>
                <Spin />
              </div>
            )}
            {!loadingList && (
              <List
                inputData={listData}
                graphqlVariables={graphqlVariables}
                products={productsData}
              />
            )}
          </Col>
        </Row>
      </Main>
      <NewRecordModal
        t={t}
        gridState={gridState}
        handleCancel={handleCancel}
        onCancel={onCancel}
        tableData={tableData}
      />
    </>
  )
}

export default CarOwnersGrid
