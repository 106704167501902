import React, { useState, useEffect } from 'react'
import { Tabs } from 'antd'

import { Modal } from '../../../../components/modals/antd-modals'
import { Tab1 } from './tab1'

import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'

import { GetRenterCars } from '../../dataQueries'

import { UPDATE_CAR_RENTER } from '../../../../graphql/AdminMutations'
import { updateCompany } from '../../../../redux/car_renters/actionCreator'
import displayNotification from '../../../reservation/admin/notification/notification'
import { Tab2 } from './tab2'

const { TabPane } = Tabs

const ModalForm = props => {
  const {
    t,
    tableData,
    data,
    formReadonly,
    form,
    selectedItem,
    setState,
    showModal
  } = props

  const [loadData, setLoadData] = useState(false)
  const selectedItemId = selectedItem.id
  const dispatch = useDispatch()
  const windowWidth = window.innerWidth
  let carsNum = 0

  useEffect(() => {
    setLoadData(true)
  }, [])

  const carsVariables = {
    tokenGet: process.env.REACT_APP_TOKEN_GET,
    carRenterId: selectedItemId
  }

  const graphQlData = GetRenterCars(carsVariables, loadData)
  const carsData = graphQlData[0]?.data?.getCars

  const graphqlVariables = {
    tokenMutation: process.env.REACT_APP_TOKEN_MUTATION,
    id: selectedItemId
  }

  const [triggerUpdateCompany] = useMutation(UPDATE_CAR_RENTER, {
    variables: graphqlVariables
  })

  const onCancel = () => {
    setState({
      editVisible: false,
      update: {}
    })
  }

  const handleCancel = () => {
    onCancel()
  }

  const handleEditOk = data => {
    onCancel()

    const updatedData = tableData.map(company => {
      if (company.id === selectedItemId) {
        const updatedData = { ...company }
        updatedData.active = data.active ? data.active : 1
        updatedData.firstname = data.firstname
        updatedData.lastname = data.lastname
        updatedData.company = data.company
        updatedData.street = data.street
        updatedData.city = data.city
        updatedData.zip = data.zip
        updatedData.phone = data.phone
        updatedData.email = data.email
        updatedData.ic = data.ic
        updatedData.dic = data.dic
        updatedData.ic_dph = data.ic_dph
        return updatedData
      } else {
        return company
      }
    })
    // dispatch(updateCompany(updatedData))

    const variables = {
      ...graphqlVariables,
      active: data.active ? data.active : 1,
      firstname: data.firstname,
      lastname: data.lastname,
      company: data.company,
      zip: data.zip,
      street: data.street,
      city: data.city,
      phone: data.phone,
      email: data.email,
      ic: data.ic,
      dic: data.dic,
      ic_dph: data.ic_dph,
      reservation_emails: data.reservation_emails
    }

    triggerUpdateCompany({ variables }).then(({ data }) => {
      let type = 'success'
      const message = t('backend.notifications.update')
      let desc = t('backend.notifications.success.recordUpdate')
      if (data.updateCarRenter !== undefined) {
        dispatch(updateCompany(updatedData))
      } else {
        type = 'error'
        desc = t('backend.notifications.error.recordUpdate')
      }

      displayNotification(type, message, desc)
    })
  }
  if (carsData) {
    carsNum = carsData.length
  }
  return (
    <Modal
      type='primary'
      title={selectedItem.company}
      visible={showModal}
      footer={null}
      onCancel={handleCancel}
      width={windowWidth * 0.8}
    >
      <Tabs>
        <TabPane tab={t('carOwners.modal.tabs.tab1.title')} key='tab1'>
          <Tab1
            t={t}
            formReadonly={formReadonly}
            form={form}
            handleEditOk={handleEditOk}
          />
        </TabPane>
        {formReadonly && (
          <TabPane
            tab={t('carOwners.modal.tabs.tab2.title') + ' ( ' + carsNum + ' )'}
            key='tab2'
          >
            <Tab2 t={t} selectedItem={selectedItem} carsData={carsData} />
          </TabPane>
        )}
      </Tabs>
    </Modal>
  )
}

export { ModalForm }
